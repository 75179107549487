import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import ErrorAlert from '../Common/AlertError/ErrorAlert';
import validator from 'validator';
import { getLanguage } from '../Common/Util';
import { withTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';

var qs = require('qs');

class LeasingInquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      email: '',
      contactno: '',
      vehicleRequired: '',
      leasingPeriod: '',
      remark: '',
      loading: false,
      errorMessage: '',
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validateQuoteRequest = () => {
    let lngParam = getLanguage();
    this.setState({
      errorMessage: '',
    });
    let valid = true;

    if (this.state.fullName === undefined || this.state.fullName === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please enter the customer name' });
      } else {
        //ar
        this.setState({ errorMessage: 'الرجاء إدخال اسم العميل' });
      }

      return false;
    }
    if (this.state.contactno === undefined || this.state.contactno === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please enter the contact no' });
      } else {
        //ar
        this.setState({ errorMessage: 'الرجاء إدخال أي اتصال' });
      }

      return false;
    }

    if (this.state.email === undefined || this.state.email === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please enter the email' });
      } else {
        //ar
        this.setState({ errorMessage: 'يرجى إدخال عنوان البريد الإلكتروني' });
      }

      return false;
    }
    if (!validator.isEmail(this.state.email)) {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The email field is invalid',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل البريد الإلكتروني',
        });
      }
      return false;
    }

    if (this.state.vehicleRequired === undefined || this.state.vehicleRequired === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please enter the vehicle required' });
      } else {
        //ar
        this.setState({ errorMessage: 'Please enter the vehicle required' });
      }

      return false;
    }

    if (this.state.leasingPeriod === undefined || this.state.leasingPeriod === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please enter the leasing period' });
      } else {
        //ar
        this.setState({ errorMessage: 'Please enter the leasing period' });
      }

      return false;
    }

    if (this.state.remark === undefined || this.state.remark === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please enter the remark' });
      } else {
        //ar
        this.setState({ errorMessage: 'الرجاء إدخال بهذا التصريح' });
      }

      return false;
    }

    return valid;
  };

  onSendQuoteRequestClick = () => {
    if (this.validateQuoteRequest()) {
      var data = qs.stringify({
        CompanyName: '',
        PersonalInformation: {
          Title: null,
          FullName: this.state.fullName,
          FirstName: null,
          LastName: null,
          ContactNo: this.state.contactno,
          DOB: null,
          Email: this.state.email,
          Address: null,
        },
        Description: this.state.remark,
        SpecialOfferID: this.state.SpecialOfferID,
        QuoteRequestDetails: [
          {
            QuoteRequestFor: 0,
            Vehicle: this.state.vehicleRequired,
            Quantity: 4,
            MileageDescription: '5000',
            ExpectedStartOn: '2021-03-04T00:00:00',
            CustomerRemark: this.state.remark,
            Duration: this.state.leasingPeriod,
            AddEditId: 1,
          },
        ],
      });

      var config = {
        method: 'post',
        url:
          process.env.REACT_APP_API + `QuoteRequest/Post?apiKey=${process.env.REACT_APP_API_KEY}`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: data,
      };
      this.setState({ loading: true });
      axios(config)
        .then((response) => {
          this.setState({ loading: false });

          this.props.history.push(`/thankyou`);
        })
        .catch((error) => {
          this.setState({ errorMessage: 'Error while sending quote request' });
        });
    } else {
    }
  };

  render() {
    const { t } = this.props;

    return (
      <div className="p-md-3 bg_custom">
        <h5 className="font-weight-bold pt-md-3">{t('Submit your Lease Inquiry.1')}</h5>
        <br />
        <form>
          <div className="form-group">
            <label for="fullName" className="book-text  mb-2">
              <h6 className="font-weight-bold mb-0">{t('Name.1')}</h6>
            </label>
            <input
              type="email"
              className="form-control px-3"
              id="fullName"
              name="fullName"
              aria-describedby="emailHelp"
              placeholder=""
              onChange={this.onChange}
            />
          </div>

          <div className="form-group">
            <label for="contactno" className="book-text mb-2">
              <h6 className="font-weight-bold mb-0">{t('Contact No.1')}</h6>
            </label>
            <input
              type="email"
              className="form-control px-3"
              name="contactno"
              id="contactno"
              aria-describedby="emailHelp"
              placeholder=""
              onChange={this.onChange}
            />
          </div>

          <div className="form-group">
            <label for="email" className="book-text  mb-2">
              <h6 className="font-weight-bold mb-0">{t('Email.1')}</h6>
            </label>
            <input
              type="email"
              className="form-control px-3"
              name="email"
              id="email"
              aria-describedby="emailHelp"
              placeholder=""
              onChange={this.onChange}
            />
          </div>

          <div className="form-group">
            <label for="vehicleRequired" className="book-text  mb-2">
              <h6 className="font-weight-bold mb-0">{t('Vehicle Required.1')}</h6>
            </label>
            <input
              type="email"
              className="form-control px-3"
              name="vehicleRequired"
              id="vehicleRequired"
              aria-describedby="emailHelp"
              placeholder=""
              onChange={this.onChange}
            />
          </div>

          <div className="form-group">
            <label for="leasingPeriod" className="book-text  mb-2">
              <h6 className="font-weight-bold mb-0">{t('Lease Period.1')}</h6>
            </label>
            <input
              type="email"
              className="form-control px-3"
              name="leasingPeriod"
              id="leasingPeriod"
              aria-describedby="emailHelp"
              placeholder=""
              onChange={this.onChange}
            />
          </div>

          <div className="form-group">
            <label for="remark" className="book-text  mb-2">
              <h6 className="font-weight-bold mb-0">{t('Remark.1')}</h6>
            </label>
            <input
              type="email"
              className="form-control px-3"
              name="remark"
              id="remark"
              aria-describedby="emailHelp"
              placeholder=""
              onChange={this.onChange}
            />
          </div>
          {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
            <ErrorAlert errorMessage={this.state.errorMessage} />
          ) : null}
          {this.state.loading == true ? (
            <div style={{ verticalAlign: 'center' }}>
              <Spinner animation="border" />
              &nbsp;{t('Sending quote request.1')}
            </div>
          ) : null}
          <a
            className="primary-bttn primary-invert-bttn text-center mt-3 mb-3"
            title="book"
            onClick={this.onSendQuoteRequestClick}
          >
            {t('Submit Inquiry.1')}
          </a>
        </form>
        <br />
      </div>
    );
  }
}

export default withTranslation()(withRouter(LeasingInquiry));
