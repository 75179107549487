import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { GoogleApiWrapper } from 'google-maps-react';
import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { getLanguage } from '../Common/Util';
import ViewExtrasDetails from '../ListingPage/ViewExtrasDetails';
import '../assets/css/slick.css';
import { branchInstance } from '../axios/axiosInstance';

var qs = require('qs');

class ReturnPopover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allBranches: [],
      overReturnBranch: {},
      branchTypes: [],
      baseBranches: [],
      searchBox: {},
      showBranchDescription: false,
      branchDescription: '',
      returnState: 'SELECT',
      returnAddress: '',
      allStates: [],
      isYourLocation: false,
      windowSize: 1024,
      isMobileView: false,
    };
  }
  onReturnBranchSelected = (selectedBranch) => {
    this.props.returnDeliveryLocationSelected('', '');
    this.props.onReturnBranchSelected(selectedBranch);
  };

  toogleDescriptionPopUp = (showHide) => {
    this.setState({
      showBranchDescription: showHide,
    });
  };
  onReturnBranchMouseOver = (branch) => {
    this.setState({ overReturnBranch: branch });
  };
  onPlacesChanged = () => {
    this.searchBox.getPlaces();
  };
  componentDidMount() {
    this.getBranches();
    this.getStates();

    if (this.props.returnState != null && this.props.returnState != undefined)
      this.setState({ returnState: this.props.returnState });
    if (this.props.returnAddress != null && this.props.returnAddress != undefined)
      this.setState({ returnAddress: this.props.returnAddress });

    window.addEventListener('resize', this.updateWindowSize);
    this.updateWindowSize();
  }
  componentDidUpdate(prevProps) {}

  updateWindowSize = () => {
    this.setState({
      windowSize: window.innerWidth,
    });
    this.setState({ isMobileView: false });
    if (window.innerWidth < 770) {
      this.setState({ isMobileView: true });
    }
  };

  containsLocation = (locations, id) => {
    var i = -0;
    for (i = 0; i < locations.length; i++) {
      if (locations[i].locationId.toString() == id.toString()) return true;
    }
    return false;
  };

  getStates = () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    branchInstance.get(`deliveryMapping/allUI?countryCode=${code}`).then((response) => {
      const allStates = response.data.result;
      if (allStates.length > 0) {
        this.setState({
          allStates: allStates,
        });
      }
    });
  };

  getBranches = () => {
    let lngParam = getLanguage();
    const code = process.env.REACT_APP_COUNTRY_CODE;
    branchInstance.get(`all?countryCode=${code}`).then((response) => {
      const allBranches = response.data.result;

      if (allBranches.length > 0) {
        this.setState({
          allBranches: allBranches,
        });

        // this.state.overReturnBranch = allBranches[0];
        // this.state.overReturnBranch.Identity.Id = "00000000-0000-0000-0000-000000000000";
        // this.state.overReturnBranch.Name = '';
        // this.state.overReturnBranch.Address = '';
        // this.state.overReturnBranch.Description = '';
        // this.state.overReturnBranch.Message = '';

        // var dubai = allBranches.find((element)=> element.Name== 'Dubai Airport - Terminal 1')
        // this.setState({
        //     overReturnBranch : dubai
        // });

        //this.onReturnBranchSelected(dubai);

        const locations = [];
        allBranches.forEach((branch) => {
          if (!this.containsLocation(locations, branch.BranchType.BranchTypeID)) {
            locations.push({
              locationId: branch.BranchType.BranchTypeID,
              name: branch.BranchType.Name,
              imageString: branch.BranchType.Attachment.ImageString,
            });
          }
        });
        this.setState({ branchTypes: locations });
        this.setState({ baseBranches: allBranches });
      }

      this.setState({
        allBranches: allBranches,
      });
    });
  };

  onInfoClick = (locationSelected) => {
    this.setState({
      showBranchDescription: true,
      branchDescription: locationSelected.Description,
    });
  };

  returnDeliveryLocationChange = (event) => {
    this.setState({ returnState: event.target.value });
    var hasBranch = false;
    if (this.state.allBranches.length > 0) {
      var statePicked = this.state.allStates.filter(
        (data) => data.StateName.trim().toLowerCase() === event.target.value.trim().toLowerCase(),
      );
      branchInstance
        .get(`deliveryMapping/branchIdByStateCode?stateCode=${statePicked[0].StateCode}`)
        .then((response) => {
          const state = response.data.result;
          const branchId = state[0].toString();

          this.state.allBranches.forEach((branch) => {
            if (branch._id.toString() === branchId) {
              hasBranch = true;
              this.setState({ overReturnBranch: branch });
            }
          });
        });
      if (!hasBranch) this.setState({ overReturnBranch: this.state.allBranches[0] });
    }
  };

  onChange = (e) => {
    this.setState({ returnAddress: e.target.value });
    if (e.target.value.length > 0) {
      if (this.state.allBranches.length > 0) {
        const newBaseLocationList = [];
        this.state.allBranches.forEach((branch) => {
          if (branch.Name.toLowerCase().includes(e.target.value.toLowerCase())) {
            newBaseLocationList.push(branch);
          }
        });
        if (newBaseLocationList.length > 0) {
          this.setState({ branchTypes: this.state.branchTypes });
          this.setState({ allBranches: newBaseLocationList });
        }
      }
    } else {
      this.setState({ allBranches: [] });
      this.setState({ allBranches: this.state.baseBranches });
    }
  };

  setClickHandler = (event) => {
    if (this.state.returnState != 'SELECT') {
      if (this.state.returnAddress == null || this.state.returnAddress == '') {
        this.setState({ errorMessage: 'Please enter the address.' });
        return;
      }

      //this.state.overReturnBranch.Identity.Id = "00000000-0000-0000-0000-000000000000";
      this.state.overReturnBranch.Name = this.state.returnState + '-' + this.state.returnAddress;
      this.state.overReturnBranch.Address = this.state.returnAddress;
      this.state.overReturnBranch.CountryState.StateName = this.state.returnState;

      this.props.onReturnBranchSelected(this.state.overReturnBranch);
      this.props.returnDeliveryLocationSelected(this.state.returnState, this.state.returnAddress);
    } else {
      this.props.returnDeliveryLocationSelected('', '');
      this.setState({ errorMessage: 'Please select the Emirates.' });
      return;
    }
  };

  onHideReturnPopover = () => {
    document.getElementById('body').className = '';
  };

  handleClose = () => {
    this.setState({
      errorMessage: '',
    });
  };

  setIsOurCountries = (event) => {
    this.setState({
      isYourLocation: false,
    });
  };

  setIsYourLocation = (event) => {
    this.setState({
      isYourLocation: true,
    });
  };

  onHidePickupPopover = () => {
    document.getElementById('body').className = '';
    this.props.clearPopOver();
  };

  render() {
    const { t } = this.props;
    return this.props.searchWidgetMode == 2 ? (
      <div
        className={
          this.state.isYourLocation == true
            ? 'pickup-dropdown-box-wrapper common-model-wrap yourLocation-wrap yourLocation--box'
            : 'pickup-dropdown-box-wrapper common-model-wrap yourLocation-wrap'
        }
      >
        <a className="close-model-select" onClick={this.onHideReturnPopover}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <polygon
              fill="#191919"
              fill-rule="nonzero"
              points="84 73.237 81.763 71 68.5 84.263 55.237 71 53 73.237 66.263 86.5 53 99.763 55.237 102 68.5 88.737 81.763 102 84 99.763 70.737 86.5"
              transform="translate(-53 -71)"
            ></polygon>
          </svg>
        </a>
        <div className="pickup-dropdown-box-left">
          <div className="NearByLocation__currentLocation">
            <button
              className={this.state.isYourLocation == false ? 'set-bttn' : 'set-bttn-gray'}
              onClick={this.setIsOurCountries}
              style={{ zIndex: '500' }}
            >
              {t('Our Counters.1')}
            </button>
            <button
              className={this.state.isYourLocation == true ? 'set-bttn' : 'set-bttn-gray'}
              onClick={this.setIsYourLocation}
              style={{ zIndex: '500' }}
            >
              {t('Deliver to me.1')}
            </button>
          </div>
          {this.state.isYourLocation == true ? (
            <>
              <div className="NearByLocation__currentLocation">
                {/* <label style={{ cursor: "pointer" }} >Need a Delivery? Select Emirates Enter Delivery address.</label> */}
                <div className="width-col want-delivery-wrap-input want-delivery-wrap-select ">
                  <select
                    id="return-delivery-location"
                    onChange={this.returnDeliveryLocationChange}
                    value={this.state.returnState}
                  >
                    <option value="SELECT">{t('Select City.1')}</option>
                    {this.state.allStates.map((state) => {
                      return (
                        <>
                          <option value={state.StateName}>{state.StateName}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="width-col want-delivery-wrap-input">
                  <input
                    class="form-control"
                    id="returnAddress"
                    name="returnAddress"
                    type="text"
                    onChange={this.onChange}
                    value={this.state.returnAddress}
                    placeholder="Enter your address"
                  />
                </div>
                <button
                  className="set-bttn"
                  onClick={this.setClickHandler}
                  style={{ zIndex: '500' }}
                >
                  Submit
                </button>
                <a title="Close" className="ml-3 close-info" onClick={this.onHideReturnPopover}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </a>
              </div>
            </>
          ) : (
            <>
              <div className="width-col want-delivery-wrap-input">
                <input
                  class="form-control"
                  id="returnAddress"
                  name="returnAddress"
                  type="text"
                  onChange={this.onChange}
                  value={this.state.returnAddress}
                  placeholder={t('Search Dollar Counters here.1')}
                />
                <a title="Close" className="ml-3 close-info" onClick={this.onHidePickupPopover}>
                  close
                </a>
              </div>

              <div className="StationHistory__wrapper">
                <div className="StationHistory__stationName">
                  <div className="StationHistory__stationInfo">
                    {this.state.branchTypes.map((branchtype) => {
                      return (
                        <div key={branchtype.locationId}>
                          <div className="StationHistory__title">
                            <img
                              src={branchtype.imageString}
                              alt="Dollar Branch"
                              style={{ width: '.5cm', height: '.5cm' }}
                            />{' '}
                            {branchtype.name}
                            {this.state.allBranches.map((branch) => {
                              return branch.BranchType.BranchTypeID == branchtype.locationId ? (
                                <>
                                  <div key={branch.Identity.Id}>
                                    <div
                                      className="StationHistory__title2"
                                      onClick={() => this.onReturnBranchSelected(branch)}
                                      onMouseOver={() => {
                                        this.onReturnBranchMouseOver(branch);
                                      }}
                                    >
                                      {branch.Name}
                                    </div>
                                  </div>
                                </>
                              ) : null;
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {
          // this.state.isMobileView == false ?
          this.state.isYourLocation == false ? (
            <div className="pickup-dropdown-box-right">
              <div className="StationDetails__wrapper">
                <h3 className="StationDetails__headline">
                  {this.state.overReturnBranch.Name}
                  <a class="text-red" onClick={() => this.onInfoClick(this.state.overReturnBranch)}>
                    {t('More Info.1')} <i class="fa fa-angle-right"></i>
                  </a>
                </h3>
                {/* <a   title="Close" className="ml-3 close-info" onClick={this.onHideReturnPopover}>
                                                    <i className="fa fa-times" aria-hidden="true"></i>
                                                </a>   */}
                <p className="StationDetails__subline">{this.state.overReturnBranch.Address}</p>
                <p className="StationDetails__subline" style={{ color: '#C8102E', opacity: '1' }}>
                  {this.state.overReturnBranch.Message}
                </p>
                <div className="StationDetails__openings">
                  <div className="StationDetails__openingHours">
                    <div className="OpeningHours__wrapper">
                      <div className="OpeningHours__openingHours">
                        <div className="OpeningHours__openingDay">
                          <div className="OpeningHours__times">
                            {this.state.overReturnBranch.BranchOfficeTiming != null
                              ? this.state.overReturnBranch.BranchOfficeTiming.BranchTimings != null
                                ? this.state.overReturnBranch.BranchOfficeTiming.BranchTimings.map(
                                    (timing) => {
                                      return (
                                        <>
                                          {timing != null ? (
                                            <>
                                              {timing.DayString} :{' '}
                                              {timing.Shifts.map((shift) => {
                                                return <>{shift}</>;
                                              })}
                                            </>
                                          ) : null}
                                          <br />
                                        </>
                                      );
                                    },
                                  )
                                : null
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <button type="submit" className="rounded-bttn rounded-bttn-transparent mt-3 text-center width-100" onClick={() => this.onInfoClick(this.state.overReturnBranch)}>Info</button> */}
              </div>
              <div className="StationInfo__mapWrapper">
                <iframe
                  src={this.state.overReturnBranch.GoogleLocationURL}
                  width="100%"
                  height="250"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>

                <a title="Close" className="ml-3 close-info" onClick={this.onHidePickupPopover}>
                  {t('close.1')}
                </a>
              </div>
            </div>
          ) : null
          // : null
        }

        {this.state.showBranchDescription ? (
          <div>
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <Modal
                show={this.state.showBranchDescription}
                onHide={() => this.toogleDescriptionPopUp(false)}
                dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap"
              >
                <ViewExtrasDetails descriptionToShow={this.state.branchDescription} />
              </Modal>
            </div>
          </div>
        ) : null}

        <div className="col-xl-3">
          {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
            <Dialog
              open={this.state.errorMessage.length > 0}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{'Dollar'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {this.state.errorMessage}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  {t('OK.1')}
                </Button>
              </DialogActions>
            </Dialog>
          ) : null}

          {this.state.loading == true ? (
            <div style={{ verticalAlign: 'center' }}>
              <Spinner animation="border" />
              &nbsp;{this.state.loadingText}
            </div>
          ) : null}
        </div>
      </div>
    ) : null;
  }
}

export default hoistStatics(
  withTranslation()(
    GoogleApiWrapper({
      apiKey: 'AIzaSyD5B6KKLVWThFRg2oXcEYoFHMyRFoLexzo',
    })(withRouter(ReturnPopover)),
  ),
  ReturnPopover,
);
