import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import UpgradeOption from './MonthlyUpgradeOption';
import VehicleFeatureBox from '../Common/VehicleFeatureBox';
import { getCurrency } from '../Common/Util';

class MonthlyListingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpgrade: false,
      hideUpgradeOption: false,
      currencyCode: '',
    };
  }
  componentDidMount() {
    this.setState({ currencyCode: getCurrency() });
    //this.state.currencyCode = getCurrency();
  }

  onBookNowClick = () => {
    this.props.onBookNowClick();
  };

  toogleUpgradeOptionPopup = (showHide) => {
    this.setState({
      showUpgrade: showHide,
    });
  };
  toogleIncludeInline = (showHide1) => {
    if (this.props.hideInclude == 0) {
      if (this.props.vehicleIndex == this.props.selectedVehicleIndex) {
        this.props.onVehicleIndexChange(9999, {});
      } else {
        this.props.onVehicleIndexChange(this.props.vehicleIndex, this.props.model);
      }
    }
  };

  onBookShowHandler = (paySelection) => {
    this.props.onVehicleChange(this.props.model, paySelection);

    this.setState({
      showUpgrade: false,
    });
  };

  onBookUpgradeHandler = (upgradeModel, originalVehicleModelID, originalTariffGroupID) => {
    this.props.onUpgradeModelSelected(upgradeModel, originalVehicleModelID, originalTariffGroupID);
    this.setState({
      showUpgrade: false,
    });
  };

  render() {
    const { t } = this.props;

    const RackRate = (this.props.model.RentalCharges.RackRate * 1.05).toFixed(2);
    const PayNowRate = (this.props.model.RentalCharges.PayNowRate * 1.05).toFixed(2);

    // let chargeP = (

    //     <p style={{ textDecoration: 'line-through', color: 'black' }}>{t('AED.1')} <span style={{ textDecoration: 'line-through', color: '#006FF7' }}>{RackRate}</span>/{t('day.1')}</p>
    // );
    return (
      <>
        <div
          className={
            this.props.vehicleIndex == this.props.selectedVehicleIndex
              ? this.props.model.IsSoldOut
                ? 'vehicle-box-design selected vehicle-sold-out'
                : this.props.model.IsOriginalModelWhileModify
                  ? 'vehicle-box-design selected vehilce-current-selected'
                  : 'vehicle-box-design selected'
              : this.props.model.IsSoldOut
                ? 'vehicle-box-design vehicle-sold-out'
                : this.props.model.IsOriginalModelWhileModify
                  ? 'vehicle-box-design vehilce-current-selected'
                  : 'vehicle-box-design'
          }
        >
          <h3 style={{ textAlign: 'left' }}>{this.props.model.Name}</h3>
          <span className="similar-text">
            {t('or Similar.1')} | {this.props.model.VehicleClassification.Name}
            {this.props.model.ModelYear != null ? (
              <>
                {' '}
                |{' '}
                <b>
                  {' '}
                  {t('Model Year.1')} - {this.props.model.ModelYear}{' '}
                </b>
              </>
            ) : null}
          </span>
          {this.props.model.FavouriteNote.length > 0 && this.props.hideUpgradeOptions == 0 ? (
            <div className="important-info-start-bg-stick">
              <i className="fa fa-star"></i>
              {this.props.model.FavouriteNote}
            </div>
          ) : (
            ''
          )}

          {this.props.model.IsOriginalModelWhileModify == true ? (
            <div className="current-selection-box">{t('Current.1')}</div>
          ) : (
            ''
          )}

          <div class="vehicle-box-design-img-wrap">
            <img
              src={this.props.model.VehicleImages.FrontImageAttachment.ImageString}
              onClick={() => this.toogleIncludeInline(true)}
              alt={this.props.model.Name}
              style={{ cursor: 'pointer' }}
              className="vehicle-image-main"
            />
          </div>

          <div className="charges-option-up">
            {this.props.showDiffPrice == 1 ? (
              this.props.rateDifference == 0 ? (
                <p>
                  {' '}
                  <span className="free-upgrade-span">{t('Free Upgrade.1')}</span>{' '}
                </p>
              ) : (
                <p>
                  {this.state.currencyCode} <span>{this.props.rateDifference}</span>
                  {t('Extra.1')}
                </p>
              )
            ) : (
              <p>
                {this.state.currencyCode}{' '}
                <span>
                  <s className="font-strick-size">{RackRate}</s>
                </span>{' '}
                <span>{PayNowRate}</span> /{t('month.1')}
              </p>
            )}

            {/* {this.props.hideUpgradeOptions == 0  ? (this.props.model.SuggestedUpgradeModelIDs.length != 0) ?
                            <div class="important-info-start-bg-stick">
                                <a href="" title={t('Upgrade Options.1')} data-toggle="modal" data-target="#exampleModal" onClick={() => this.toogleUpgradeOptionPopup(true)}>{t('Upgrade Options.1')}</a></div> : ""
                            
                            : null
                        } */}
          </div>
          <p className="tax-included">Prices are inclusive of VAT.</p>
          {this.props.hideInclude == 0 && this.props.model.IsRestrictBooking == false ? (
            <VehicleFeatureBox model={this.props.model} />
          ) : (
            ''
          )}

          {this.props.showBookNow == 1 && this.props.model.IsRestrictBooking == false ? (
            <div style={{ width: '100%' }}>
              <div className="text-center mt-4 prestige-car-box-bttn">
                <a
                  onClick={() => {
                    this.onBookNowClick();
                  }}
                  title="Book Now"
                  className="vehicle-box-design-book"
                  tabIndex="0"
                >
                  {t('Book Now.1')}
                </a>
              </div>{' '}
            </div>
          ) : null}
        </div>

        <Modal
          show={this.state.showUpgrade}
          onHide={() => this.toogleUpgradeOptionPopup(false)}
          dialogClassName="modal-dialog modal-xl modal-dialog-centered"
        >
          <UpgradeOption
            model={this.props.model}
            searchParam={this.props.searchParam}
            BookShow={() => this.onBookShowHandler()}
            BookUpgrade={this.onBookUpgradeHandler}
            CloseUpgradePopup={this.toogleUpgradeOptionPopup}
          />
        </Modal>
      </>
    );
  }
}

export default hoistStatics(withTranslation()(MonthlyListingCard), MonthlyListingCard);
