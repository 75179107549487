import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { images } from './constant';
import './assets/css/style.css';
class LeaseBenfits extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  scrollNext = () => {
    if (this.containerRef.current) {
      this.containerRef.current.scrollBy({ left: 275, behavior: 'smooth' });
    }
  };

  scrollPrev = () => {
    if (this.containerRef.current) {
      this.containerRef.current.scrollBy({ left: -275, behavior: 'smooth' });
    }
  };

  render() {
    return (
      <div className="swiper benifitSwiper-oz">
        <div className="swiper-wrapper" ref={this.containerRef}>
          <div className="swiper-slide">
            <img src={images.thump1} alt="" />
            <h6>
              Guaranteed Savings <span>on 1st Month Cash Outflow</span>
            </h6>
          </div>
          <div className="swiper-slide">
            <img src={images.thump2} alt="" />
            <h6>
              No <span> Down Payment & </span> Zero <span>Deposit</span>
            </h6>
          </div>
          <div className="swiper-slide">
            <img src={images.thump3} alt="" />
            <h6>
              Lower<span> Monthly Payments</span>
            </h6>
          </div>
          <div className="swiper-slide">
            <img src={images.thump4} alt="" />
            <h6>
              Option <span> to Purchase</span>
            </h6>
          </div>
          <div className="swiper-slide">
            <img src={images.thump5} alt="" />
            <h6>
              Free<span> Maintenance</span>
            </h6>
          </div>
          <div className="swiper-slide">
            <img src={images.thump6} alt="" />
            <h6>Free Insurance & Registration </h6>
          </div>
          <div className="swiper-slide">
            <img src={images.thump7} alt="" />
            <h6>
              Free Replacement <span> Vehicle</span>
            </h6>
          </div>
          <div className="swiper-slide">
            <img src={images.thump8} alt="" />
            <h6>
              Zero<span> Processing Fee & Financial Charges</span>
            </h6>
          </div>
          <div className="swiper-slide">
            <img src={images.thump9} alt="" />
            <h6>
              Low<span> Financial Risk</span>
            </h6>
          </div>
        </div>
        <div className="pagination">
          <div className="swiper-button-next" onClick={this.scrollPrev}>
            <img src={images.prevButton} alt="" />
          </div>
          <div className="swiper-button-prev">
            <img src={images.nextButton} alt="" onClick={this.scrollNext} />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(LeaseBenfits);
