import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import DriverDetailsIcon from '../assets/images/driver-details-icon.svg';
import FindReservationImg from '../assets/images/find-a-reservation.png';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import { getLanguage } from '../Common/Util';
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Subscribe from '../Hire/Subscribe';
import NewsIndex from '../News/NewsIndex';
import validator from 'validator';
import SuccessAlert from '../Common/AlertError/SuccessAlert';
import ContactUsBox from './ContactUsBox';
import { Helmet } from 'react-helmet';
var qs = require('qs');
var axios = require('axios');

class RequestReceipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationNo: '',
      email: '',
      loading: false,
      errorMessage: '',
      successMessage: '',
      subscribeEmail: '',
    };
  }

  componentDidMount = () => {
    scroll.scrollToTop();
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleClose = () => {
    this.setState({
      errorMessage: '',
    });
  };
  validate = () => {
    let lngParam = getLanguage();

    this.setState({
      errorMessage: '',
    });

    let valid = true;
    if (this.state.confirmationNo.trim() == '') {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The confirmationNo field is required',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب مجال confirmationNo',
        });
      }

      return false;
    }
    if (this.state.email == undefined || this.state.email === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'The email field is required' });
      } else {
        //ar
        this.setState({
          errorMessage: 'The email field is required',
        });
      }

      return false;
    }
    return valid;
  };
  onSubmit = () => {
    if (this.validate()) {
      var axios = require('axios');
      this.setState({ loading: true });
      var config = {
        method: 'post',
        url:
          process.env.REACT_APP_API +
          `CustomerRequest/RequestReceipt?apiKey=` +
          process.env.REACT_APP_API_KEY +
          `&reservationNo=${this.state.confirmationNo}&contactNo=&emailID=${this.state.email}`,
        headers: {},
      };

      axios(config)
        // .then((response) => {

        //     this.setState({ loading: false });
        //     this.props.history.push(`/reservationconfirm/${response.data.Identity.Id}/1`);
        // })
        // .catch((error) => {
        //     this.setState({errorMessage:error.response.data.Message});
        //     this.setState({ loading: false });
        // });
        .then((response) => {
          if (response.data != null && response.data.Message != null) {
            localStorage.setItem('thanksmessage', response.data.Message);
          }

          this.props.history.push('/thankyou');
        })
        .catch((error) => {
          this.props.history.push('/thankyou');
        });
    }
  };

  validateSubscribe = () => {
    let lngParam = getLanguage();

    this.setState({
      errorMessage: '',
    });

    let valid = true;

    if (this.state.subscribeEmail.trim() == '') {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The email field is required',
          successMessage: '',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل البريد الإلكتروني',
          successMessage: '',
        });
      }

      return false;
    }

    if (!validator.isEmail(this.state.subscribeEmail)) {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The email field is invalid',
          successMessage: '',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل البريد الإلكتروني',
          successMessage: '',
        });
      }
      return false;
    }

    return valid;
  };

  onChangeEmail = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onSubscribeSubmit = (e) => {
    e.preventDefault();
    if (this.validateSubscribe()) {
      let data = qs.stringify({ Name: '', EmailId: this.state.subscribeEmail });
      let config = {
        method: 'post',
        url:
          process.env.REACT_APP_API +
          `NewsletterRequest/Post?apiKey=` +
          process.env.REACT_APP_API_KEY,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          if (response.data != null && response.data.Message != null) {
            localStorage.setItem('thanksmessage', response.data.Message);
          }
        })
        .catch((error) => {});
      this.props.history.push('/thankyou');
    }
  };
  handleClose = () => {
    this.setState({
      errorMessage: '',
    });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Request Your Receipt from Dollar Car Rental UAE</title>
          <meta
            name="description"
            content="Booked a car with Dollar Car Rental UAE? Need an invoice for your records? Simply submit your request for a receipt now! Hassle-free process, ensuring you get the documentation you need promptly. Don't delay, get your rental receipt today!"
          ></meta>
          <meta
            property="og:title"
            content="Request Your Receipt from Dollar Car Rental UAE"
          ></meta>
          <meta property="og:url" content="https://www.dollaruae.com/requestreceipt"></meta>
          <meta
            property="og:description"
            content="Booked a car with Dollar Car Rental UAE? Need an invoice for your records? Simply submit your request for a receipt now! Hassle-free process, ensuring you get the documentation you need promptly. Don't delay, get your rental receipt today!"
          ></meta>
        </Helmet>
        <ListingHeader />
        <div class="minH100 grey-bg py-2">
          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box">
                <div className="">
                  <div className="row flex-reversea  mb-3">
                    <div className="col-12">
                      <div className="pb-2">
                        <h2 className="border-botom">{t('Request a Receipt.1')}</h2>
                        <p>{t('Already got your Dollar Car Reserved.1')}</p>
                        <p className="text_p mt-2">
                          {t(
                            'Receipts for previous rentals made with Dollar Car Rental are available online after returning the vehicle.1',
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="box-title-icon-border d-flex align-items-center mb-4 position-relative">
                    <span className="box-title-icon-rounded">
                      <img
                        src={DriverDetailsIcon}
                        alt="Dollar Car Reserved"
                        width="16"
                        height="16"
                      />
                    </span>
                    <h4 className="text-16 text-red">{t('Request a Receipt.1')}</h4>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="custom-input-type-wrap">
                        <label htmlFor="first-name">{t('Confirmation Number.1')}</label>
                        <input
                          type="text"
                          id="customer-name"
                          placeholder={t('Enter Confirmation Number.1')}
                          name="confirmationNo"
                          onChange={this.onChange}
                          value={this.state.confirmationNo}
                        />
                      </div>
                    </div>
                    <div className="col-lg-5 col-sm-6 col-12 mt-sm-0 mt-4">
                      <div className="custom-input-type-wrap">
                        <label htmlFor="last-name">{t('Email.1')}</label>
                        <input
                          type="text"
                          id="email-address"
                          placeholder="Enter Email"
                          name="email"
                          onChange={this.onChange}
                          value={this.state.email}
                        />
                      </div>
                    </div>
                    <div className="col-xl-3 col-md-6 text-right div-bottom-alignment">
                      <div className="custom-input-type-wrap mt-4">
                        <a
                          className="primary-bttn primary-invert-bttn"
                          title="Submit"
                          onClick={() => this.onSubmit()}
                        >
                          {t('Get a Receipt.1')}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-lg-6">
                      <div className="form-group">
                        {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
                          <Dialog
                            open={this.state.errorMessage.length > 0}
                            onClose={this.handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">{'Dollar'}</DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                {this.state.errorMessage}
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={this.handleClose} color="primary">
                                {t('OK.1')}
                              </Button>
                            </DialogActions>
                          </Dialog>
                        ) : null}
                        {this.state.loading == true ? (
                          <div style={{ verticalAlign: 'center' }}>
                            <Spinner animation="border" />
                            &nbsp;{t('Sending quote request.1')}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="box-shadow-white-box-section grey-bg py-2">
            <div className="container">
              <div className="subscriber-container box-shadow-white-box request-receipt-red-box">
                <div className="subscriber-text-left request-receipt-red-box1">
                  <h2>{t('Join Dollar RED Community.1')}</h2>
                  <p>
                    {t(
                      'Exclusive Flash Deals, Surprise Giveaways, Early Bird Offers, Road Trip Destinations, Tips & Tricks right in your mailbox!.1',
                    )}
                  </p>
                </div>
                <form>
                  <input
                    type="text"
                    placeholder={t('Enter Email Address.1')}
                    name="subscribeEmail"
                    id="subscribeEmail"
                    value={this.state.subscribeEmail}
                    onChange={this.onChangeEmail}
                  />
                  <input
                    id="submit"
                    type="submit"
                    onClick={this.onSubscribeSubmit}
                    value={t('Join Now.1')}
                  ></input>
                </form>
              </div>
            </div>

            <div className="custom-input-type-wrap">
              <div class="container">
                {this.state.successMessage != null && this.state.successMessage.length > 0 ? (
                  <SuccessAlert successMessage={this.state.successMessage} />
                ) : null}
                {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
                  <Dialog
                    open={this.state.errorMessage.length > 0}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">{'Dollar'}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {this.state.errorMessage}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleClose} color="primary">
                        {t('OK.1')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                ) : null}
              </div>
            </div>
          </section>
          {/* <Subscribe /> */}
          {/* <NewsIndex /> */}

          <ContactUsBox subHeading="" heading={t('Do you have further Question.1')}></ContactUsBox>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(withRouter(withRouter(RequestReceipt)));
