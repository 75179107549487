import moment from 'moment';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import validator from 'validator';
import ErrorAlert from '../Common/AlertError/ErrorAlert';
import { getLanguage } from '../Common/Util';
import VehicleFeatureBox from '../Common/VehicleFeatureBox';
var qs = require('qs');
class EmailPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errorMessage: '',
      loading: false,
    };
  }
  SendEmail = () => {
    if (this.validate()) {
      var axios = require('axios');
      var config = {
        method: 'post',
        url:
          process.env.REACT_APP_API +
          'QuoteRequest/SendEmailQuoteRequest?apiKey=' +
          process.env.REACT_APP_API_KEY +
          `&vehicleModelID=${this.props.model.VehicleModelID}&emailID=${this.state.email}`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      this.setState({
        loading: true,
      });
      axios(config)
        .then((response) => {
          this.setState({
            loading: false,
          });
          if (response.data.IsValid == true) {
            if (response.data != null && response.data.Message != null) {
              localStorage.setItem('thanksmessage', response.data.Message);
            }

            this.props.history.push('/thankyou');
          } else {
            this.setState({ errorMessage: response.data.Message });
          }
        })
        .catch((error) => {});
    }
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  validate = () => {
    let lngParam = getLanguage();

    let valid = true;
    if (this.state.email.trim() == '') {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The email field is required',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل البريد الإلكتروني',
        });
      }

      return false;
    }
    if (!validator.isEmail(this.state.email)) {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The email field is invalid',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل البريد الإلكتروني',
        });
      }
      return false;
    }
    if (this)
      this.setState({
        errorMessage: '',
      });

    return valid;
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="upgrade-option-modal-content">
          {
            <Modal.Header className="p-0 border-0" closeButton>
              <h2 className="border-botom">{t('Email me a quote.1')}</h2>
            </Modal.Header>
          }
          <Modal.Body>
            <div className="row">
              <div className="col-md-6">
                <img
                  className="carouselImage"
                  src={this.props.model.VehicleImages.FrontImageAttachment.ImageString}
                  alt="First slide"
                />
              </div>
              <div className="col-md-6">
                <div className="vehicle-box-detail-right">
                  <div className="vehicle-box-detail-right-content">
                    <h4>{this.props.model.Name}</h4>

                    <span className="similar-text">
                      {t('or Similar.1')} | {this.props.model.VehicleClassification.Name}
                    </span>
                    <VehicleFeatureBox model={this.props.model} />
                    <div className="feature-vehicle-box row mt-3 pt-3 border-top">
                      <div className="col-md-6">
                        <span className="text-red font-bold">
                          {this.props.additionalParam.dropOffLocationName}
                        </span>
                        <br></br>
                        {moment(this.props.searchParam.CheckOutOn).format('DD MMM YYYY HH:mm')}
                      </div>
                      <div className="col-md-6">
                        <span className="text-red font-bold">
                          {this.props.additionalParam.pickupLocationName}
                        </span>
                        <br></br>
                        {moment(this.props.searchParam.CheckInOn).format('DD MMM YYYY HH:mm')}
                      </div>
                    </div>

                    <div className="vehicle-box-detail-left mt-3 pt-3 border-top">
                      <ul className="check-box-listing mt-0">
                        {this.props.includeSection != null
                          ? this.props.includeSection.map((include) => {
                              return (
                                <li key={include.ShortText}>
                                  <span>{include.ShortText}</span>
                                </li>
                              );
                            })
                          : ''}
                      </ul>
                    </div>
                    <div className="booking-details-listing-right text-red mt-4">
                      {t('AED.1')}
                      <span class="h4">
                        {this.props.model.RentalCharges.DiscountedRate.toFixed(2)}/{t('day.1')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="custom-input-type-wrap">
                  <label>{t('Email Address.1')}</label>
                  <input
                    type="email"
                    id="SendToEmailId"
                    placeholder={t('Enter email address.1')}
                    name="email"
                    onChange={this.onChange}
                    value={this.state.email}
                  />
                </div>
              </div>
              <div className="col-lg-2">
                <div className="custom-input-type-wrap">
                  <label>&nbsp;</label>
                  {this.state.loading == false ? (
                    <a title="Send" className="book-now-btttn" onClick={this.SendEmail}>
                      {t('Send.1')}
                    </a>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-6">
                {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
                  <ErrorAlert errorMessage={this.state.errorMessage} />
                ) : null}

                {this.state.loading == true ? (
                  <div style={{ verticalAlign: 'center' }}>
                    <Spinner animation="border" />
                    &nbsp; {t('sending an email.1')}
                  </div>
                ) : null}
              </div>
            </div>
          </Modal.Body>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(EmailPopup));
