import { Component, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import './assets/css/style.css';
import './assets/fonts/stylesheet.css';
import { images } from './constant';
import WhyDollar from './WhyDollar';
import LeaseBenfits from './LeaseBenfits';
import LeaseThankYou from './LeaseThankYou';
import LeaseForm from './LeaseForm';
import Footer from '../../Common/Footer.js';
import CopyRightFooter from '../../Common/CopyRightFooter.js';
import Header from '../../Common/Header.js';

class LeaseToOwn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowSize: window.innerWidth,
      isMobileView: false,
      thanksPopUp: false,
    };
    this.formWrapperRef = createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowSize);
    this.updateWindowSize();
  }

  updateWindowSize = () => {
    this.setState({
      windowSize: window.innerWidth,
    });
    this.setState({ isMobileView: false });
    if (window.innerWidth < 770) {
      this.setState({ isMobileView: true });
    }
  };

  handleThankYouPopup = (value) => {
    this.setState({ thanksPopUp: value });
  };

  render() {
    return (
      <>
        <Header />
        <main className="main">
          {/* table ad form */}
          <section className="th-f2-grid">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="inner-wrapper">
                    <div className="bg-wrapper">
                      <img src={images.bgThumb} alt="" />
                    </div>
                    <h5>Are you looking for a car loan?</h5>
                    <h6 className="fz-40-black">
                      Contact us for the most innovative vehicle leasing program <span>.</span>
                    </h6>
                    <p className="fz-16-24-gray">
                      At Dollar Car Rental UAE, we provide unique rent-to-own options that offer
                      numerous advantages over traditional bank finance.
                    </p>

                    <div className="form-wrapper" ref={this.formWrapperRef}>
                      {/* <!-- form --> */}
                      <LeaseForm handleThankYouPopup={this.handleThankYouPopup} />
                    </div>
                    {/* why dollar section */}
                    <WhyDollar />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- all other benefit --> */}
          <section className="th-f4">
            <div className="inner-wrapper">
              <div className="title-wrapper">
                <h6 className="fz-40-black">Rent to Own Benefits</h6>
              </div>
              <LeaseBenfits />
            </div>
          </section>

          {/* <!-- want to know more --> */}
          <section className="th-f5">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="inner-wrapper">
                    <div className="content">
                      <h6 className="fz-30-white">Want to know more?</h6>
                      <p>Connect with us, Let’s have talk. We’re here to assist you.</p>
                    </div>
                    <a
                      onClick={() =>
                        this.formWrapperRef.current.scrollIntoView({ behavior: 'smooth' })
                      }
                    >
                      Submit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- thank you poppup --> */}
          {this.state.thanksPopUp && (
            <LeaseThankYou handleThankYouPopup={this.handleThankYouPopup} />
          )}
        </main>
        {this.state.isMobileView == false ? <Footer /> : null}
        {this.state.isMobileView == true ? (
          <CopyRightFooter isMobileView={this.state.isMobileView} />
        ) : null}
      </>
    );
  }
}

export default withTranslation()(LeaseToOwn);
