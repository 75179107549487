import moment from 'moment';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import schedule06 from '../assets/images/calender-icoon.svg';
import driverdDetails from '../assets/images/driver-details-icon.svg';
import email from '../assets/images/email.png';
import phone from '../assets/images/phone.png';
import phone2 from '../assets/images/phone2.svg';
import printing from '../assets/images/print-icoon.svg';
import infothin from '../assets/images/iconmonstr-info-thin.svg';
import rentalDetails from '../assets/images/rental-policies-icon.svg';
import modifyBooking from '../assets/images/modify-booking-icon.svg';
import share from '../assets/images/share.svg';
import ListingHeader from '../Common/ListingHeader';
import VehicleFeatureBoxVertical from '../Common/VehicleFeatureBoxVertical';
import ConfirmationBooking from '../Common/ConfirmationBooking';
import { Modal } from 'react-bootstrap';
import ReservationCancelPopUp from './ReservationCancelPopUp';
import ViewIncludeLongText from './ViewIncludeLongText';
import ViewInsuranceDetails from './ViewInsuranceDetails';
import ListingFooter from '../Common/ListingFooter';
import EmailReservation from './EmailReservation';
import RentalPolicies from './RentalPolicies';
import { getCancellationInformation, getClearCancellationInformation } from '../Common/Util';
import tickMarkIcon from '../assets/images/tick-mark.png';
import { animateScroll as scroll, scroller } from 'react-scroll';
import { responsiveFontSizes } from '@material-ui/core';

class ReservationConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Data: {},
      ReservationConfirmId: '',
      VehicleName: '',
      ReservationStatus: '',
      showCancelViewDetails: false,
      CancelCharge: 0,
      PaidCharge: 0,
      AmountToRefund: 0,
      showExtrasDetails: false,
      LongTextShow: '',
      showShare: false,
      cancellationStatus: false,
      PromoCode: '',
      CancellationData: {},
      loading: false,
      rentalDays: 0,
      rentalType: 0,
    };
  }
  componentDidMount = () => {
    const {
      match: { params },
    } = this.props;
    this.ReservationDetails(params.index);
    setTimeout(() => {
      const cancellationInfo = getCancellationInformation();
      if (cancellationInfo != null) {
        this.state.cancellationStatus = cancellationInfo.IsValid;
        getClearCancellationInformation();
      }
    }, 100);
    scroll.scrollToTop();
  };

  toogleViewCancelPopUp = (showHide) => {
    this.setState({ loading: showHide });
    if (showHide) {
      var axios = require('axios');

      var config = {
        method: 'get',
        url:
          process.env.REACT_APP_API +
          `Reservations/GetCancelCalculation?apiKey=` +
          process.env.REACT_APP_API_KEY +
          `&reservationId=${this.state.ReservationConfirmId}`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };

      axios(config)
        .then((response) => {
          setTimeout(() => {
            this.setState({
              CancellationData: response.data,
            });
          }, 0);

          setTimeout(() => {
            this.setState({
              showCancelViewDetails: showHide,
            });
          }, 0);
        })
        .catch((error) => {});
    } else {
      this.setState({
        showCancelViewDetails: showHide,
      });
    }
  };

  toggleViewShare = (showHide) => {
    this.setState({
      showShare: showHide,
    });
  };

  toogleViewIncludeLongTextPopUp = (showHide, LongText) => {
    this.setState({
      showExtrasDetails: showHide,
    });
    this.setState({
      LongTextShow: LongText,
    });
  };
  ReservationDetails = (Id) => {
    this.setState({
      ReservationConfirmId: Id,
    });
    var axios = require('axios');

    var config = {
      method: 'get',
      url:
        process.env.REACT_APP_API +
        `Reservations/GetByID?apiKey=` +
        process.env.REACT_APP_API_KEY +
        `&reservationId=${Id}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios(config)
      .then((response) => {
        //let cancelCharge = 0;

        // response.data.ModifyRateDetail.forEach(element => {

        //     cancelCharge = cancelCharge + element.TotalAmt;

        // });
        let paidCharge = 0;
        response.data.RentalRateDetail.forEach((element) => {
          paidCharge = paidCharge + element.TotalAmt;
        });

        this.setState({
          Data: response.data,
          //CancelCharge: cancelCharge,
          PaidCharge: paidCharge,
          rentalDays: response.data.RentalIn.RentalDays,
          //AmountToRefund: paidCharge - cancelCharge
        });

        if (this.state.Data.ReservationStatus == 0) {
          this.setState({ ReservationStatus: 'Confirmation Pending' });
        } else if (this.state.Data.ReservationStatus == 1) {
          this.setState({ ReservationStatus: 'Rejected' });
        } else if (response.data.ReservationStatus == 2) {
          this.setState({ ReservationStatus: 'Confirmed' });
        } else if (this.state.Data.ReservationStatus == 3) {
          this.setState({ ReservationStatus: 'Internal Approval Pending' });
        } else {
          this.setState({ cancellationStatus: true });
          this.setState({ ReservationStatus: 'Cancelled' });
        }
        if (
          this.state.Data.RentalIn.PromoCode != null &&
          this.state.Data.RentalIn.PromoCode != ''
        ) {
          this.setState({ PromoCode: this.state.Data.RentalIn.PromoCode });
        }

        this.setState({ rentalType: this.state.Data.RentalType });

        //alert(this.state.Data.InDetail.DeliveryPickupStateName);
      })
      .catch((error) => {});
  };
  onPrintClick = () => {
    window.print();
  };

  onAddToCalenderClick = () => {
    var axios = require('axios');
    var config = {
      method: 'get',
      url:
        process.env.REACT_APP_API +
        `Reservations/GetReservationGoogleCalenderLink?apiKey=` +
        process.env.REACT_APP_API_KEY +
        `&reservationId=${this.state.ReservationConfirmId}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios(config).then((response) => {
      window.open(response.data.toString(), '_blank');
    });
  };

  onShareFeedbackClick = () => {
    this.props.history.push(`/feedback/${this.state.Data.Identity.Code}`);
  };

  onRetryPaymentClick = () => {
    var axios = require('axios');

    var config = {
      method: 'get',
      url:
        process.env.REACT_APP_API +
        `NGeniusPaymentIntegration/RetryPayment?apiKey=` +
        process.env.REACT_APP_API_KEY +
        `&reservationId=${this.state.ReservationConfirmId}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios(config).then((response) => {
      window.location = response.data.toString();
    });
  };

  toogleViewInsurancePopUp = (showHide, insuranceDescriptionToShow) => {
    this.setState({
      showInsuranceViewDetails: showHide,
    });
    this.setState({
      insuranceDesriptionToShow: insuranceDescriptionToShow,
    });
  };

  onStartAnotherReservation = () => {
    this.props.history.push('/StartaReservation');
  };

  onModifyClick = () => {
    var BookingInfo = [
      {
        CheckOutLocationId: this.state.Data.RentalIn.CheckOutBranchID,
        CheckInLocationId: this.state.Data.RentalIn.CheckInBranchID,
        PickupLocationId: this.state.Data.RentalIn.CheckOutBranchID,
        DestinationLocationId: this.state.Data.RentalIn.CheckInBranchID,
        PickUpDate: moment(this.state.Data.RentalIn.CheckOutOn).format('DD MMM YYYY, h:mm A'),
        ReturnDate: moment(this.state.Data.RentalIn.CheckInOn).format('DD MMM YYYY, h:mm A'),
        pickupLocationName: this.state.Data.OutDetail.Name,
        dropOffLocationName: this.state.Data.InDetail.Name,
        pickupLocationImage: this.state.Data.OutDetail.Attachment.ImageString,
        dropOffLocationImage: this.state.Data.InDetail.Attachment.ImageString,
        pickupLocationAddress: this.state.Data.OutDetail.Address,
        dropOffLocationAddress: this.state.Data.InDetail.Address,
        pickupLocationMessage: this.state.Data.OutDetail.BranchMessage,
        dropOffLocationMessage: this.state.Data.InDetail.BranchMessage,
        pickupLocationBranchDescription: this.state.Data.OutDetail.BranchDescription,
        dropOffLocationBranchDescription: this.state.Data.InDetail.BranchDescription,
        promocode: '',

        deliveryState: this.state.Data.OutDetail.DeliveryPickupStateName,
        deliveryAddress: this.state.Data.OutDetail.DeliveryPickupAddress,
        returnState: this.state.Data.InDetail.DeliveryPickupStateName,
        returnAddress: this.state.Data.InDetail.DeliveryPickupAddress,

        ModifiedReservationID: this.state.ReservationConfirmId,
      },
    ];

    //alert(this.state.ReservationConfirmId);

    localStorage.setItem('BookingInfo', JSON.stringify(BookingInfo));
    if (this.state.rentalType == 3) {
      //Subscription
      window.location = '/monthlybooking';
    } else {
      window.location = '/booking';
    }
  };
  onShareClick = () => {
    this.setState({
      showShare: true,
    });
  };
  render() {
    const { t } = this.props;
    let onlinePaymentStatus =
      this.state.Data.OnlinePaymentDetail != null &&
      this.state.Data.OnlinePaymentDetail.OnlinePayStatus == 1
        ? 'Success'
        : 'Last Payment Failed';
    return (
      <>
        <ListingHeader></ListingHeader>
        {/* {
                this.state.cancellationStatus ?
                    <div className="row pt-5 align-items-center justify-content-center bg-grey ">
                        <div className="col-sm-12 col-lg-8 col-md-12 col-12">
                            <div id="reserve p-3 contact-us-quick-box">
                                <div className="rental-steps-wrap ">
                                    <div className="pickup-location-box text-center">
                                        <img src={tickMarkIcon} alt="Thank you" className="mb-3" />
                                        <h3 className="font-bold mb-2  ">Reservation Cancelled !</h3>
                                        <p className=" text-center">   This is to confirm your reservation {<span>#{(this.state.Data.Identity != null) ? this.state.Data.Identity.Code : ""}</span>} has been cancelled.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            } */}

        <div class="minH100 bg-grey reservation-confirmation">
          <section className="congrts-scnpg-section">
            <div className="container">
              <div className="congrts-title-wrap">
                {this.state.cancellationStatus ? (
                  <div>
                    <h1>{t('Reservation Canceled.1')}</h1>
                    <p>
                      {t('This is to confirm your reservation.1')}{' '}
                      {
                        <span>
                          #{this.state.Data.Identity != null ? this.state.Data.Identity.Code : ''}
                        </span>
                      }{' '}
                      {t('has been canceled.1')}
                    </p>
                  </div>
                ) : (
                  <div>
                    <h1>
                      {this.state.ReservationStatus == 'Confirmed'
                        ? 'Congratulations!'
                        : this.state.ReservationStatus}
                    </h1>
                    {this.state.ReservationStatus == 'Confirmation Pending' ? (
                      <p>{t('Your booking pending for confirmation.1')}</p>
                    ) : (
                      <p>{t('Your booking has been confirmed.1')}</p>
                    )}
                  </div>
                )}
              </div>

              <div className="congrts-scnpg-wrap">
                <div className="congrts-scnpg-red-strip">
                  <h2>{t('Booking Summary.1')}</h2>
                  <div className="congrts-scnpg-red-right">
                    <div className="box-title-icon-border d-flex align-items-center">
                      <span
                        className="box-title-icon-rounded"
                        style={{ cursor: 'pointer' }}
                        onClick={this.onPrintClick}
                      >
                        <img src={printing} alt="Dollar Print" width="21" height="" />
                      </span>
                      <h4 style={{ cursor: 'pointer' }} onClick={this.onPrintClick}>
                        {t('Print.1')}
                      </h4>
                    </div>
                    <div className="box-title-icon-border d-flex align-items-center">
                      <span
                        className="box-title-icon-rounded"
                        style={{ cursor: 'pointer' }}
                        onClick={this.onAddToCalenderClick}
                      >
                        <img src={schedule06} alt="Dollar Calendar" width="24" height="16" />
                      </span>
                      <h4 style={{ cursor: 'pointer' }} onClick={this.onAddToCalenderClick}>
                        {t('Add To Calendar.1')}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="booking-summary-section">
                  <div className="booking-summary-left">
                    <div className="booking-summary-left-top">
                      <div className="img-details-wrap">
                        <img
                          src={
                            this.state.Data.VehicleModel != null
                              ? this.state.Data.VehicleModel.VehicleImages.FrontImageAttachment
                                  .ImageString
                              : ''
                          }
                          alt="Vehicle Image"
                          width="389"
                          height="245"
                        />
                      </div>
                      <div className="rvrsn-title-wrap-box">
                        <div className="rvrsn-title-left">
                          <h5 className="font-bold mb-1">
                            {this.state.Data.VehicleModel != null
                              ? this.state.Data.VehicleModel.Name
                              : ''}
                          </h5>
                          <p className="text-14 opacity-50 text-black">
                            {t('or Similar.1')}
                            {this.state.Data.VehicleModel != null ? (
                              <> | {this.state.Data.VehicleModel.VehicleClassification.Name}</>
                            ) : (
                              ''
                            )}
                            {this.state.Data.VehicleModel != null ? (
                              this.state.Data.RentalIn.ModelYear != null ? (
                                <>
                                  {' '}
                                  |{' '}
                                  <b>
                                    {' '}
                                    {t('Model Year.1')} - {this.state.Data.RentalIn.ModelYear}{' '}
                                  </b>{' '}
                                </>
                              ) : null
                            ) : null}
                          </p>
                        </div>
                        <div className="rvrsn-title-right">
                          <h5 className="rsnrn-number" style={{ display: 'inline-block' }}>
                            {t('Reservation Number.1')}:
                            <span>
                              {this.state.Data.Identity != null
                                ? this.state.Data.Identity.Code
                                : ''}
                            </span>
                          </h5>
                        </div>
                      </div>

                      <div className="featture-box-wrap">
                        <h5 className="text-18 font-bold">{t('Features.1')}</h5>
                        <VehicleFeatureBoxVertical
                          model={
                            this.state.Data.VehicleModel != null ? this.state.Data.VehicleModel : ''
                          }
                        />
                        <ul className="check-box-listing mt-0">
                          {this.state.Data.BookingIncluded != null
                            ? this.state.Data.BookingIncluded.map((include, index) => {
                                return (
                                  <li>
                                    <span>
                                      {include.ShortText}
                                      <a
                                        className="text-16 text-red font-bold d-block"
                                        onClick={() =>
                                          this.toogleViewIncludeLongTextPopUp(
                                            true,
                                            include.LongText,
                                          )
                                        }
                                      >
                                        <img alt="Sold-Out-Transparent" src={infothin} />
                                      </a>
                                    </span>
                                  </li>
                                  // <li key={index}>
                                  //     <span>
                                  //         {includeSection.ShortText}
                                  //     </span>
                                  //     <a title="Details" className="text-red opacity-100 font-bold" onClick={() => this.toogleViewIncludeLongTextPopUp(true, includeSection.LongText)}>
                                  //         {t('Details.1')}</a>
                                  // </li>
                                );
                              })
                            : null}
                        </ul>
                      </div>

                      <div className="driver-details-wrap" id="include">
                        <h4>{t('Driver Details.1')}</h4>

                        <div className="row">
                          <div className="col-12">
                            <div className="row" id="labelcust">
                              <div className="col-12">
                                <div className="custom-input-type-wrap">
                                  <label htmlFor="first-name">{t('Full Name.1')}:</label>
                                  <p>
                                    {this.state.Data.Renter != null
                                      ? this.state.Data.Renter.FirstName +
                                        ' ' +
                                        this.state.Data.Renter.LastName
                                      : ''}
                                  </p>
                                </div>
                              </div>

                              <div className="col-12 mt-2">
                                <div className="custom-input-type-wrap">
                                  <label htmlFor="contact-number">{t('Phone Number.1')}:</label>
                                  <p>
                                    {this.state.Data.Renter != null
                                      ? this.state.Data.Renter.ContactNo
                                      : ''}
                                  </p>
                                </div>
                              </div>

                              {/* <div className="col-12 mt-2">
                                                                <div className="custom-input-type-wrap">
                                                                    <label htmlFor="date-of-birth">{t('Date Of Birth.1')}:</label>
                                                                    <p>{(this.state.Data.Renter != null) ? moment(this.state.Data.Renter.BirthDate).format('DD MMM YYYY') : ""}</p>
                                                                </div>
                                                            </div> */}
                              <div className="col-12 mt-2">
                                <div className="custom-input-type-wrap">
                                  <label htmlFor="first-name">{t('Nationality.1')}:</label>
                                  <br></br>
                                  <p>
                                    {this.state.Data.Renter != null
                                      ? this.state.Data.Renter.CountryState.NationalityName
                                      : ''}
                                  </p>
                                </div>
                              </div>
                              <div className="col-12 mt-2">
                                <div className="custom-input-type-wrap">
                                  <label htmlFor="first-name">{t('Email Address.1')}:</label>
                                  <p>
                                    {this.state.Data.Renter != null
                                      ? this.state.Data.Renter.EmailId
                                      : ''}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {this.props.match.params.index2 == 1 ? (
                            <div className="col-12">
                              {' '}
                              {this.state.ReservationStatus == 'Cancelled' ? (
                                ''
                              ) : (
                                <a
                                  href=""
                                  className="rounded-bttn  color-red mt-3 mb-3 d-none"
                                  title="Book &amp; Pay Online"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    className="mr-2"
                                    viewBox="0 0 20.881 22.273"
                                  >
                                    <g transform="translate(4.872)">
                                      <path
                                        className="a"
                                        d="M138.962,5.806,134.09.238a.694.694,0,0,0-1.047,0l-4.872,5.568a.7.7,0,0,0,.523,1.154h2.784v9.048a.7.7,0,0,0,.7.7h2.784a.7.7,0,0,0,.7-.7V6.96h2.784a.7.7,0,0,0,.523-1.154Z"
                                        transform="translate(-127.998)"
                                      />
                                    </g>
                                    <g transform="translate(0 15.312)">
                                      <path
                                        className="a"
                                        d="M34.1,352v4.176H18.784V352H16v5.568a1.392,1.392,0,0,0,1.392,1.392h18.1a1.391,1.391,0,0,0,1.392-1.392V352Z"
                                        transform="translate(-16 -352)"
                                      />
                                    </g>
                                  </svg>
                                  {t('Upload Document.1')}
                                </a>
                              )}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="booking-summary-right">
                    <div className="link-booking-bttn-wrap">
                      {this.state.Data.IsAllowModification == true ? (
                        <a
                          className="red-bttn"
                          title="Book &amp; Pay Later"
                          onClick={this.onModifyClick}
                        >
                          <img src={modifyBooking} />
                          {t('Modify Reservation.1')}
                        </a>
                      ) : (
                        ''
                      )}

                      {this.state.Data.IsAllowCancellation == true ? (
                        <a
                          className="grey-bttn"
                          title="Book &amp; Pay Online"
                          onClick={() => this.toogleViewCancelPopUp(true)}
                        >
                          {t('Cancel Reservation.1')}
                        </a>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="pickup-location-box--wrap">
                      <div className="pickup-location-box">
                        <div className="pickup-location-dots">
                          <i className="dots"></i>
                          <span className="pickup-sm-title">{t('Pickup Location.1')}</span>
                          <h3 className="StationDetails__headline">
                            {this.state.Data.OutDetail != null
                              ? this.state.Data.OutDetail.Name
                              : ''}
                            {/* <a class="text-red" onClick={() => this.toogleViewInsurancePopUp(true, this.state.Data.OutDetail.BranchDescription)}>More Info <i class="fa fa-angle-right"></i></a> */}
                          </h3>
                          <p className="StationDetails__subline">
                            {this.state.Data.OutDetail != null
                              ? this.state.Data.OutDetail.Address
                              : ''}
                          </p>
                        </div>
                        <div className="pickup-location-dots">
                          <i className="dots"></i>
                          <span>{t('Pickup Date & Time.1')}</span>
                          <p className="StationDetails__subline">
                            {this.state.Data.OutDetail != null
                              ? moment(this.state.Data.OutDetail.Timestamp).format(
                                  'DD MMM YYYY, HH:mm',
                                )
                              : ''}
                          </p>
                          {this.state.Data.OutDetail != null &&
                          this.state.Data.OutDetail.DeliveryPickupAddress != null &&
                          this.state.Data.OutDetail.DeliveryPickupAddress != '' ? (
                            <span className="delivery-pickup-box">
                              {t('Delivery.1')} :{' '}
                              <span>
                                {this.state.Data.OutDetail.DeliveryPickupAddress +
                                  ', ' +
                                  this.state.Data.OutDetail.DeliveryPickupStateName}
                              </span>
                            </span>
                          ) : null}
                        </div>
                      </div>
                      {this.state.Data.RentalType == 3 ? (
                        <>
                          <div className="pickup-location-box">
                            <div className="pickup-location-dots">
                              <i className="dots"></i>
                              <span>{t('Return Date & Time.1')}</span>
                              <p className="StationDetails__subline">
                                {this.state.Data.InDetail != null
                                  ? moment(this.state.Data.InDetail.Timestamp).format(
                                      'DD MMM YYYY, HH:mm',
                                    )
                                  : ''}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="pickup-location-box">
                            <div className="pickup-location-dots">
                              <i className="dots"></i>
                              <span className="pickup-sm-title">{t('Return Location.1')}</span>
                              <h3 className="StationDetails__headline">
                                {this.state.Data.InDetail != null
                                  ? this.state.Data.InDetail.Name
                                  : ''}
                                {/* <a class="text-red" onClick={() => this.toogleViewInsurancePopUp(true, this.state.Data.InDetail.BranchDescription)}>More Info <i class="fa fa-angle-right"></i></a> */}
                              </h3>
                              <p className="StationDetails__subline">
                                {this.state.Data.InDetail != null
                                  ? this.state.Data.InDetail.Address
                                  : ''}
                              </p>
                            </div>
                            <div className="pickup-location-dots">
                              <i className="dots"></i>
                              <span>{t('Return Date & Time.1')}</span>
                              <p className="StationDetails__subline">
                                {this.state.Data.InDetail != null
                                  ? moment(this.state.Data.InDetail.Timestamp).format(
                                      'DD MMM YYYY, HH:mm',
                                    )
                                  : ''}
                              </p>
                              {this.state.Data.InDetail != null &&
                              this.state.Data.InDetail.DeliveryPickupAddress != null &&
                              this.state.Data.InDetail.DeliveryPickupAddress != '' ? (
                                <span className="delivery-pickup-box">
                                  {t('Collection.1')}:{' '}
                                  <span>
                                    {this.state.Data.InDetail.DeliveryPickupAddress +
                                      ', ' +
                                      this.state.Data.InDetail.DeliveryPickupStateName}
                                  </span>
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="confirmBooking-detail-box">
                      {this.state.Data.RentalType == 3 ? (
                        <>
                          <div className="confirmBookingTitle border-bottom">
                            <h3>
                              {this.state.Data.RentalIn.SubscriptionMonth > 0 ? (
                                <> {t('Total Months.1')}</>
                              ) : (
                                <> {t('Total Month.1')}</>
                              )}
                            </h3>
                            <h3 className="text-red">
                              {this.state.Data.RentalIn.SubscriptionMonth}
                              {this.state.Data.RentalIn.SubscriptionMonth > 0 ? (
                                <> {t('Months.1')}</>
                              ) : (
                                <> {t('Month.1')}</>
                              )}
                            </h3>
                          </div>
                          <div className="confirmBookingTitle border-bottom">
                            <h3>{t('Allowed KM.1')}</h3>
                            <h3 className="text-red">
                              {this.state.Data.RentalIn.SubscriptionKm} KMS | Month
                            </h3>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="confirmBookingTitle">
                            <h3>{t('Total Days.1')}</h3>
                            {this.state.rentalDays > 1 ? (
                              <h3 className="text-red">
                                {this.state.rentalDays} {t('days.1')}
                              </h3>
                            ) : (
                              <h3 className="text-red">
                                {this.state.rentalDays} {t('day.1')}
                              </h3>
                            )}
                          </div>
                        </>
                      )}
                      {this.state.Data.RentalRateDetail != null
                        ? this.state.Data.RentalRateDetail.map((model) => {
                            return (
                              <div className="confirmBookingTitle">
                                <h4>
                                  {this.state.Data.RentalRateDetail != null ? model.Item : ''}
                                  {this.state.Data.RentalRateDetail != null ? (
                                    <span>{model.Description}</span>
                                  ) : (
                                    ''
                                  )}
                                </h4>
                                <h4 className="text-red">
                                  {t('AED.1')}{' '}
                                  {this.state.Data.RentalRateDetail != null
                                    ? model.SubTotal.toFixed(2)
                                    : ''}
                                </h4>
                              </div>
                            );
                          })
                        : ''}
                      <div className="confirmBookingTitle">
                        <h4>{t('Sub Total.1')}</h4>
                        <h4 className="text-red">
                          {t('AED.1')}{' '}
                          {this.state.Data.SubTotal != null
                            ? this.state.Data.SubTotal.toFixed(2)
                            : null}
                        </h4>
                      </div>
                      <div className="confirmBookingTitle">
                        <h4>{t('Tax Total.1')}</h4>
                        <h4 className="text-red">
                          {t('AED.1')}{' '}
                          {this.state.Data.TotalTax != null
                            ? this.state.Data.TotalTax.toFixed(2)
                            : null}
                        </h4>
                      </div>
                      <div className="confirmBookingTitle border-top">
                        <h2>{t('Grand Total.1')}</h2>
                        <h2 className="text-red">
                          {t('AED.1')}{' '}
                          {this.state.Data != null && this.state.Data.TotalAmount != null
                            ? this.state.Data.TotalAmount.toFixed(2)
                            : ''}
                          &#8236;
                        </h2>
                      </div>
                      {/* <ConfirmationBooking ConfirmationData={this.state.Data} /> */}
                      <div className="confirmBookingTitle">
                        <h4>{t('Payment Mode.1')} :</h4>
                        <h4 className="text-red">
                          {this.state.Data.RentalIn != null &&
                          this.state.Data.RentalIn.PaymentStatus == 0 ? (
                            <span>&nbsp; {t('Pay at Counter.1')}</span>
                          ) : this.state.Data.OnlinePaymentDetail != null &&
                            this.state.Data.OnlinePaymentDetail.OnlinePayStatus == 1 ? (
                            <span>&nbsp; {t('Paid Online.1')} </span>
                          ) : null}
                          {this.state.Data.RentalIn != null &&
                          this.state.Data.RentalIn.PaymentStatus == 0 ? null : this.state.Data
                              .OnlinePaymentDetail != null &&
                            this.state.Data.OnlinePaymentDetail.OnlinePayStatus == 1 ? (
                            this.state.Data.ModificationCharge != null ? null : (
                              <span>&nbsp; {onlinePaymentStatus}</span>
                            )
                          ) : (
                            <>
                              <span>&nbsp; {onlinePaymentStatus}</span>&nbsp;
                              <h2
                                className="text-16 text-danger font-bold"
                                style={{ cursor: 'pointer' }}
                                onClick={this.onRetryPaymentClick}
                              >
                                {t('Retry.1')}
                              </h2>
                            </>
                          )}
                        </h4>
                      </div>

                      {this.state.Data.OnlinePaymentDetail != null &&
                      this.state.Data.OnlinePaymentDetail.OnlinePayStatus == 1 &&
                      this.state.Data.ModificationCharge.AmountToRefund > 0 ? (
                        <>
                          <div className="confirmBookingTitle">
                            <h4 className="text-red">{t('Refund Amount.1')} :</h4>
                            <h4 className="text-red">
                              {
                                <>
                                  {
                                    <div>
                                      {t('AED.1')}{' '}
                                      {this.state.Data.ModificationCharge.AmountToRefund}
                                    </div>
                                  }
                                </>
                              }
                              <>
                                <br></br>
                              </>
                            </h4>
                          </div>
                        </>
                      ) : null}

                      {this.state.Data.OnlinePaymentDetail != null &&
                      this.state.Data.OnlinePaymentDetail.OnlinePayStatus == 1 &&
                      this.state.Data.ModificationCharge.AmountToPay > 0 ? (
                        <>
                          <div className="confirmBookingTitle">
                            <h4 className="text-red">{t('Amount To Pay.1')} :</h4>
                            <h4 className="text-red">
                              {
                                <div>
                                  {t('AED.1')} {this.state.Data.ModificationCharge.AmountToPay}{' '}
                                </div>
                              }
                              <>
                                <br></br>
                              </>
                            </h4>
                          </div>
                        </>
                      ) : null}
                      {this.state.Data.OnlinePaymentDetail != null &&
                      this.state.Data.OnlinePaymentDetail.OnlinePayStatus == 2 ? (
                        <div className="confirmBookingTitle">
                          <h4>{t('Payment Status1')} :</h4>
                          <h4 className="text-red">
                            {this.state.Data.OnlinePaymentDetail != null &&
                            this.state.Data.OnlinePaymentDetail.OnlinePayStatus == 2 ? (
                              <span>
                                &nbsp; {this.state.Data.OnlinePaymentDetail.PaymentMessage}{' '}
                              </span>
                            ) : null}
                            <>
                              <br></br>
                            </>
                          </h4>
                        </div>
                      ) : null}

                      {this.state.Data.RentalIn != null &&
                      this.state.Data.RentalIn.PaymentStatus == 1 &&
                      this.state.Data.OnlinePaymentDetail != null &&
                      this.state.Data.OnlinePaymentDetail.OnlinePayStatus == 1 &&
                      this.state.Data.CancellationCharge != null &&
                      this.state.Data.CancellationCharge.AmountCancellationCharge == 0 ? (
                        <div className="confirmBookingTitle">
                          <h4>Paid Amount : </h4>
                          {this.state.Data.RentalIn != null &&
                          this.state.Data.RentalIn.PaymentStatus == 1 &&
                          this.state.Data.OnlinePaymentDetail != null &&
                          this.state.Data.OnlinePaymentDetail.OnlinePayStatus == 1 ? (
                            <h4 className="text-red">
                              {this.state.Data.ModificationCharge != null &&
                              this.state.Data.ModificationCharge.AmountPaid > 0 ? (
                                <div>
                                  {t('AED.1')}{' '}
                                  {this.state.Data.ModificationCharge.AmountPaid.toFixed(2)}&#8236;
                                </div>
                              ) : (
                                <div>
                                  {t('AED.1')}{' '}
                                  {this.state.Data != null && this.state.Data.TotalAmount != null
                                    ? this.state.Data.TotalAmount.toFixed(2)
                                    : ''}
                                  &#8236;
                                </div>
                              )}
                            </h4>
                          ) : null}
                        </div>
                      ) : null}
                      {this.state.Data.RentalIn != null &&
                      this.state.Data.RentalIn.PaymentStatus == 0 ? null : this.state.Data
                          .CancellationCharge != null &&
                        this.state.Data.CancellationCharge.AmountCancellationCharge == 0 ? (
                        <div className="confirmBookingTitle">
                          <h4>{t('Pay Ref No.1')} : </h4>
                          <h4 className="text-red mt-2">
                            {this.state.Data.OnlinePaymentDetail != null
                              ? this.state.Data.OnlinePaymentDetail.OnlinePayRefNo == null ||
                                this.state.Data.OnlinePaymentDetail.OnlinePayRefNo == ''
                                ? null
                                : '#' + this.state.Data.OnlinePaymentDetail.OnlinePayRefNo
                              : null}
                          </h4>
                        </div>
                      ) : null}
                      {
                        <ul className="booking-details-listing">
                          {this.state.Data.CancellationCharge != null &&
                          this.state.Data.CancellationCharge.AmountCancellationCharge > 0 ? (
                            <li className="mt-3">
                              <div class="booking-details-listing-left text-18 font-hel-bold text-red">
                                {t('Cancellation Charges.1')}
                              </div>
                              <div class="booking-details-listing-right text-red">
                                {t('AED.1')}{' '}
                                <span class="h4">
                                  {this.state.Data.CancellationCharge.AmountCancellationCharge.toFixed(
                                    2,
                                  )}
                                </span>
                              </div>
                            </li>
                          ) : null}
                          {this.state.Data.CancellationCharge != null &&
                          this.state.Data.CancellationCharge.AmountToRefund > 0 ? (
                            <li className="mt-3">
                              <div class="booking-details-listing-left text-18 font-hel-bold text-red">
                                {t('Refund Amount.1')}
                              </div>
                              <div class="booking-details-listing-right text-red">
                                {t('AED.1')}{' '}
                                <span class="h4">
                                  {this.state.Data.CancellationCharge != null
                                    ? this.state.Data.CancellationCharge.AmountToRefund.toFixed(2)
                                    : null}
                                </span>
                              </div>
                            </li>
                          ) : null}
                          {this.state.Data.CancellationCharge != null &&
                          this.state.Data.CancellationCharge.AmountPaid > 0 ? (
                            <li className="mt-3">
                              <div class="booking-details-listing-left text-18 font-hel-bold text-red">
                                {t('Amount Paid.1')}
                              </div>
                              <div class="booking-details-listing-right text-red">
                                {t('AED.1')}{' '}
                                <span class="h4">
                                  {this.state.Data.CancellationCharge != null
                                    ? this.state.Data.CancellationCharge.AmountPaid.toFixed(2)
                                    : null}
                                </span>
                              </div>
                            </li>
                          ) : null}
                        </ul>
                      }
                    </div>

                    <h4 className="paymode">{/* <span>Pay at Counter</span> */}</h4>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="rental-policy-confirm bg-white">
            <div className="container">
              <RentalPolicies rentalType={this.state.rentalType}></RentalPolicies>
            </div>
          </div>
          {/* <div style={{ boxShadow: "0px -5px 10px rgb(0 0 0 / 15%)" }}>
                    <Footer />
                </div> */}
        </div>
        {this.state.showCancelViewDetails ? (
          <div className="modal-dialog modal-xl modal-dialog-centered">
            <Modal
              show={this.state.showCancelViewDetails}
              onHide={() => this.toogleViewCancelPopUp(false)}
              dialogClassName="modal-dialog modal-xl modal-dialog-centered"
            >
              <ReservationCancelPopUp
                ConfirmationData={this.state.CancellationData}
                ReservationConfirmId={this.state.ReservationConfirmId}
              />
            </Modal>
          </div>
        ) : null}

        {this.state.showExtrasDetails ? (
          <div>
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <Modal
                show={this.state.showExtrasDetails}
                onHide={() => this.toogleViewIncludeLongTextPopUp(false)}
                dialogClassName="modal-dialog modal-xl modal-dialog-centered"
              >
                <ViewIncludeLongText LongTextShow={this.state.LongTextShow} />
              </Modal>
            </div>
          </div>
        ) : null}

        {this.state.showInsuranceViewDetails ? (
          <div>
            <div className="modal-dialog modal-xl modal-dialog-centered ">
              <Modal
                show={this.state.showInsuranceViewDetails}
                onHide={() => this.toogleViewInsurancePopUp(false)}
                dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap"
              >
                <ViewInsuranceDetails
                  insuranceDesriptionToShow={this.state.insuranceDesriptionToShow}
                />
              </Modal>
            </div>
          </div>
        ) : null}

        {this.state.showShare ? (
          <div>
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <Modal
                show={this.state.showShare}
                onHide={() => this.toggleViewShare(false)}
                dialogClassName="modal-dialog modal-xl modal-dialog-centered"
              >
                <EmailReservation ReservationConfirmId={this.state.ReservationConfirmId} />
              </Modal>
            </div>
          </div>
        ) : null}

        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(withRouter(ReservationConfirm));
