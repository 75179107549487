import React from 'react';
import DocumentSection from './DocumentSection';
import styles from './RequiredDocuments.module.css';

const residentDocs = [
  {
    icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/4a446b0d6a8037c53f734d118221032c803448cdeb29133659e68c65a41769e7?apiKey=ac85e0a3444a46c7baa2de216eef8e21',
    text: 'UAE Driving License',
  },
  {
    icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/bbede9c7839b2933d6b5a921ba108413bec37928a54c200cf16f3a3ba6b398cf?apiKey=ac85e0a3444a46c7baa2de216eef8e21',
    text: 'Emirates ID',
  },
  {
    icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/07bd2bad79d7cad68ecb49fadd5a6902ccd29deeb7380ae5303c5e2b1459814a?apiKey=ac85e0a3444a46c7baa2de216eef8e21',
    text: 'Credit Card or Debit Card',
  },
];

const touristDocs = [
  {
    icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/8c0af325267a094738f603c5700b9117307a7de1862017336b14a58f32d3e83a?apiKey=ac85e0a3444a46c7baa2de216eef8e21',
    text: 'Valid Passport & UAE Tourist Visa',
  },
  {
    icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/1d54e9be8159dd574ae8cd3e2a9470621b14b46a9a02756ffc6e39269b8c2442?apiKey=ac85e0a3444a46c7baa2de216eef8e21',
    text: 'International Driving license or Permit',
  },
  {
    icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/1cb2dbffc472cc1e15bba5c60c94590d85f6ca67aa2063337b355deda93e7fa6?apiKey=ac85e0a3444a46c7baa2de216eef8e21',
    text: 'Credit Card or Debit Card',
  },
];

const RequiredDocuments = () => {
  return (
    <section className={styles.documentsSection}>
      <h2 className={styles.sectionTitle}>Required Documents</h2>
      <div className={styles.documentTypes}>
        <DocumentSection
          title={
            <strong>
              As a <span className={styles.sectionTitleUnderLine}>Resident</span>
            </strong>
          }
          documents={residentDocs}
          note={
            <p className={styles.note}>
              <span className={styles.boldBlack}>Please note:</span> As a UAE resident, if you have
              your home country driver's license,{' '}
              <a
                href="https://www.dollaruae.com/termsandconditions"
                className={styles.highlightRed}
              >
                You Will need to convert your license
              </a>{' '}
              before you start driving in the UAE.
            </p>
          }
        />
        <DocumentSection
          title={
            <strong>
              As a <span className={styles.sectionTitleUnderLine}>Tourist</span>
            </strong>
          }
          documents={touristDocs}
          note={
            <p className={styles.note}>
              <span className={styles.boldBlack}>Please note:</span> As a tourist, you may drive
              with an international driving license, valid passport and visa. You qualify as a
              tourist until you receive your UAE residency.
            </p>
          }
        />
      </div>
    </section>
  );
};

export default RequiredDocuments;
