import React, { Component } from 'react';
import RentalGuideImge from '../assets/images/Business/1981493672.jfif';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
class RentalGuide extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Vehicle Rental Guide: All You Need to Know Before Booking Your Next Car Rental in UAE
          </title>
          <link rel="canonical" href="www.dollaruae.com/rental-guide" />
          <meta
            property="og:title"
            content="Vehicle Rental Guide: All You Need to Know Before Booking Your Next Car Rental in UAE"
          ></meta>
          <meta property="og:site_name" content="Dollar Car Rental"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/rentalguide"></meta>
          <meta
            property="og:description"
            content="Your Quick Guide to a hassle-free car rental experience in UAE. Rent a Car in Dubai, Abu Dhabi and Sharjah."
          ></meta>
          <meta property="og:type" content="business.business"></meta>
          <meta
            property="og:image"
            content="https://www.dollaruae.com/static/media/1981493672.01674064.jfif"
          ></meta>
          <meta
            name="title"
            content="Vehicle Rental Guide: All You Need to Know Before Booking Your Next Car Rental in UAE"
          ></meta>
          <meta
            name="description"
            content="Your Quick Guide to a hassle-free car rental experience in UAE. Rent a Car in Dubai, Abu Dhabi and Sharjah."
          ></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/rentalguide"></meta>
          <meta
            property="og:title"
            content="Vehicle Rental Guide: All You Need to Know Before Booking Your Next Car Rental in UAE"
          ></meta>
          <meta
            property="og:description"
            content="Your Quick Guide to a hassle-free car rental experience in UAE. Rent a Car in Dubai, Abu Dhabi and Sharjah."
          ></meta>
          <meta
            property="og:image"
            content="https://www.dollaruae.com/static/media/1981493672.01674064.jfif"
          ></meta>
          <meta property="twitter:card" content="summary_large_image"></meta>
          <meta property="twitter:url" content="https://www.dollaruae.com/rentalguide"></meta>
          <meta
            property="twitter:title"
            content="Vehicle Rental Guide: All You Need to Know Before Booking Your Next Car Rental in UAE"
          ></meta>
          <meta
            property="twitter:description"
            content="Your Quick Guide to a hassle-free car rental experience in UAE. Rent a Car in Dubai, Abu Dhabi and Sharjah."
          ></meta>
          <meta
            property="twitter:image"
            content="https://www.dollaruae.com/static/media/1981493672.01674064.jfif"
          ></meta>
        </Helmet>
        <ListingHeader />
        <div>
          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a ">
                <div className="">
                  <div className="row flex-reversea">
                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                      <div className="pickup-location-box">
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content">
                            <h1 className="border-botom">{t('Rental Guide.1')}</h1>
                            <p>
                              {t('Your Quick Guide to getting a Hassle-free Car Rental.1')}
                              <br />
                              {t('Experience in the UAE.1')}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                      <img
                        src={RentalGuideImge}
                        alt="Dollar Rental Guide"
                        className="img-fluid rounded-xl"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="box-shadow-white-box-section grey-bg pb-4">
            <div className="container-fluid">
              <div className="box-shadow-white-box">
                <div className="row">
                  <div className="col-lg-7 p-3">
                    <h5 className="font-weight-bold">{t('Minimum Age Limit.1')}</h5>
                    <p className="text_p mt-4">
                      {t(
                        'Dollar Car Rental UAE requires one to be of 22 years old and above for all our car rental plans within the country.1',
                      )}
                    </p>
                    <br />
                    <h5 className="font-weight-bold">{t('Reservation Methods.1')}</h5>
                    <p className="text_p mt-4">
                      {t(
                        'You may make your reservations online at www.dollaruae.com, call our 24x7 sales representatives at 800 DOLLAR, visit any of our counters at locations across UAE or email us at.1',
                      )}{' '}
                      <a
                        href="mailto:makeabooking@dollaruae.ae"
                        className="footer-mobile footer-email"
                        title="Mail"
                      >
                        makeabooking@dollaruae.ae
                      </a>
                    </p>
                    <br />
                    <h5 className="font-weight-bold">{t('Security Deposits.1')}</h5>
                    <p className="text_p mt-4">
                      {t('Valid Credit Card with minimum 3 months validity.1')}
                    </p>
                    <br />
                    <h5 className="font-weight-bold">{t('Mode of Payment.1')}</h5>
                    <p className="text_p mt-4">
                      {t(
                        'Dollar Car Rental UAE accepts all major Credit Cards (MASTER, VISA, JCB, DINERS). Credit card must be valid for a minimum of 3 months from the day of rental and must be physically available at the time of vehicle collection.1',
                      )}
                    </p>
                    <br />
                    <h5 className="font-weight-bold">{t('Vehicle Inspection & Add-ons.1')}</h5>
                    <p className="text_p mt-4">
                      {t(
                        'Once you’ve ensured the vehicle quality is up to your satisfaction, you may proceed to sign the rental contract and complete the payment process. You may also add on amenities like baby/toddler rear seats, GPS trackers, Mobile WIFI, etc. at this stage.1',
                      )}
                    </p>
                  </div>
                  <div className="col-lg-5">
                    <div className="p-3 bg_custom mr-6">
                      <h5 className="font-weight-bold">{t('Valid Documents Required.1')}</h5>
                      <br />
                      <ul className="round-circle-listing">
                        <li>
                          <span>
                            {t('For Local Residents..1')}
                            <br />
                            <p className="text_p mt-2">
                              • {t('Emirates ID (Front & Back).1')}
                              <br />• {t('UAE Driving License (Front & Back).1')}
                              <br />• {t('Passport.1')}
                              <br />• {t('Visa.1')}
                            </p>
                          </span>
                        </li>
                        <li className="mt-3">
                          <span>
                            {t('For Tourists (International)..1')}
                            <br />
                            <p className="text_p mt-2">
                              • {t('International Driving License.1')}
                              <br />• {t('Passport.1')}
                              <br />• {t('Visiting Visa.1')}
                              <br />
                            </p>
                          </span>
                        </li>
                        <li className="mt-3">
                          <span>
                            {t('For Tourists (GCC Countries).1')}
                            <br />
                            <p className="text_p mt-2">
                              • {t('Valid GCC Driving License.1')}
                              <br />• {t('Passport.1')}
                              <br />• {t('Visiting Visa.1')}
                            </p>
                          </span>
                        </li>
                        <li className="mt-3">
                          <span>
                            {t(
                              'International or National Driving license in any other language other than Arabic and English should be translated into Arabic or English. The translation should be attested by the Embassy.1',
                            )}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(RentalGuide);
