import React, { Component } from 'react';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import articles from '../assets/images/articles.png';
import group2x from '../assets/images/group@2x.png';
import m1 from '../assets/images/m1.png';
import phone from '../assets/images/phone.png';
import email from '../assets/images/email.png';
import m2 from '../assets/images/m2.png';
import m3 from '../assets/images/m3.png';
import m4 from '../assets/images/m4.png';
import m5 from '../assets/images/m5.png';
import m6 from '../assets/images/m6.png';

class SalesTeam extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <ListingHeader></ListingHeader>
        <section className="box-shadow-white-box-section  " id="partner">
          <div className="container-fluid">
            <div className="box-shadow-white-box-a ">
              <div className="">
                <div className="row flex-reversea">
                  <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                    <div className="pickup-location-box">
                      <h5 className="font-bold mb-3 mt-2 ">Sales Team</h5>
                      <div className="pickup-location-flex">
                        <div className="pickup-location-flex-content">
                          <h3 className="mb-1 f-32">Lorem Ipsum is that it has a more-or-less</h3>
                          <span>
                            Lorem Ipsum is that it has a more-or-less normal distribution of
                            letters, as opposed to using 'Content here, content here', making it
                            look like readable English.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                    <img src={articles} alt="Dollar Sales Team" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="box-shadow-white-box-section">
          <div className="container-fluid">
            <div className="box-shadow-white-box">
              <div className="row">
                <div className="col-lg-6">
                  <div className="box-title-icon-border d-flex align-items-center mb-4 btn_border">
                    <span className="box-title-icon-rounded">
                      <img src={group2x} alt="Dollar 1" width="17" height="15" />
                    </span>
                    <h4 className="text-16 text-red">Lorem Ipsum is that</h4>
                  </div>
                  <div className="row pb-3">
                    <div className="col-3 mt-2">
                      <img src={m1} alt="girl" className="img-fluid" />
                    </div>
                    <div className="col-9 mt-2">
                      <h5 className="font-bold mb-2 f-32">Desirae Vetrovs</h5>
                      <h6 className=" mb-3 mt-2 f-20">Lorem Ipsum is that</h6>
                      <div className="row">
                        <div className="col-lg-6 col-12 col-md-6 mt-1 d-flex-center">
                          <a href="tel:917536672">
                            {' '}
                            <img src={phone} alt="girl" className="img-fluid phonea" />
                            <span className="img_text ml-2">+9197536672</span>
                          </a>
                        </div>
                        <br />
                        <div className="col-lg-6 col-12 col-md-6 mt-1 d-flex-center">
                          <a href="mailto:desiraevet@gmail.com">
                            {' '}
                            <img src={email} alt="girl" className="img-fluid phone" />
                            <span className="img_text ml-2">desiraevet@gmail.com</span>
                          </a>
                        </div>
                      </div>

                      <span className="img_text mt-2">
                        Lorem Ipsum is that it has a more-or-less normal distribution of letters
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="row pb-3">
                    <div className="col-3 mt-2">
                      <img src={m3} alt="girl" className="img-fluid" />
                    </div>
                    <div className="col-9 mt-2">
                      <h5 className="font-bold mb-2 f-32">Desirae Vetrovs</h5>
                      <h5 className=" mb-3 mt-2 f-20">Lorem Ipsum is that</h5>
                      <div className="row">
                        <div className="col-lg-6 col-12 col-md-6 mt-1 d-flex-center">
                          <a href="tel:917536672">
                            {' '}
                            <img src={phone} alt="girl" className="img-fluid phonea" />
                            <span className="img_text ml-2">+9197536672</span>
                          </a>
                        </div>
                        <div className="col-lg-6 col-12 col-md-6 mt-1 d-flex-center">
                          <a href="mailto:desiraevet@gmail.com">
                            {' '}
                            <img src={email} alt="girl" className="img-fluid phone" />
                            <span className="img_text ml-2">desiraevet@gmail.com</span>{' '}
                          </a>
                        </div>
                      </div>
                      <span className="img_text mt-2">
                        Lorem Ipsum is that it has a more-or-less normal distribution of letters
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="row pb-3">
                    <div className="col-3 mt-2">
                      <img src={m5} alt="girl" className="img-fluid" />
                    </div>
                    <div className="col-9 mt-2">
                      <h5 className="font-bold mb-2 f-32">Desirae Vetrovs</h5>
                      <h5 className=" mb-3 mt-2 f-20">Lorem Ipsum is that</h5>
                      <div className="row">
                        <div className="col-lg-6 col-12 col-md-6 mt-1 d-flex-center">
                          <a href="tel:917536672">
                            {' '}
                            <img src={phone} alt="girl" className="img-fluid phonea" />
                            <span className="img_text ml-2">+9197536672</span>
                          </a>
                        </div>
                        <div className="col-lg-6 col-12 col-md-6 mt-1 d-flex-center">
                          <a href="mailto:desiraevet@gmail.com">
                            {' '}
                            <img src={email} alt="girl" className="img-fluid phone" />
                            <span className="img_text ml-2">desiraevet@gmail.com</span>
                          </a>
                        </div>
                      </div>
                      <span className="img_text mt-2">
                        Lorem Ipsum is that it has a more-or-less normal distribution of letters
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="box-title-icon-border d-flex align-items-center mb-4 btn_border">
                    <span className="box-title-icon-rounded">
                      <img src={group2x} alt="Dollar 1" width="17" height="15" />
                    </span>
                    <h4 className="text-16 text-red">Lorem Ipsum is that</h4>
                  </div>

                  <div className="row pb-3">
                    <div className="col-3 mt-2">
                      <img src={m2} alt="girl" className="img-fluid" />
                    </div>
                    <div className="col-9 mt-2">
                      <h5 className="font-bold mb-2 f-32">Desirae Vetrovs</h5>
                      <h5 className=" mb-3 mt-2 f-20">Lorem Ipsum is that</h5>
                      <div className="row">
                        <div className="col-lg-6 col-12 col-md-6 mt-1 d-flex-center">
                          <a href="tel:917536672">
                            {' '}
                            <img src={phone} alt="girl" className="img-fluid phonea" />
                            <span className="img_text ml-2">+9197536672</span>
                          </a>
                        </div>
                        <div className="col-lg-6 col-12 col-md-6 mt-1 d-flex-center">
                          <a href="mailto:desiraevet@gmail.com">
                            {' '}
                            <img src={email} alt="girl" className="img-fluid phone" />
                            <span className="img_text ml-2">desiraevet@gmail.com</span>
                          </a>
                        </div>
                      </div>
                      <span className="img_text mt-2">
                        Lorem Ipsum is that it has a more-or-less normal distribution of letters
                      </span>
                    </div>
                  </div>
                  <hr />

                  <div className="row pb-3">
                    <div className="col-3 mt-2">
                      <img src={m4} alt="girl" className="img-fluid" />
                    </div>
                    <div className="col-9 mt-2">
                      <h5 className="font-bold mb-2 f-32">Desirae Vetrovs</h5>
                      <h5 className=" mb-3 mt-2 f-20">Lorem Ipsum is that</h5>
                      <div className="row">
                        <div className="col-lg-6 col-12 col-md-6 mt-1 d-flex-center">
                          <img src={phone} alt="girl" className="img-fluid phonea" />
                          <span className="img_text ml-2">+9197536672</span>
                        </div>
                        <div className="col-lg-6 col-12 col-md-6 mt-1 d-flex-center">
                          <img src={email} alt="girl" className="img-fluid phone" />
                          <span className="img_text ml-2">desiraevet@gmail.com</span>
                        </div>
                      </div>
                      <span className="img_text mt-2">
                        Lorem Ipsum is that it has a more-or-less normal distribution of letters
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="row pb-3">
                    <div className="col-3 mt-2">
                      <img src={m6} alt="girl" className="img-fluid" />
                    </div>
                    <div className="col-9 mt-2">
                      <h5 className="font-bold mb-2 f-32">Desirae Vetrovs</h5>
                      <h5 className=" mb-3 mt-2 f-20">Lorem Ipsum is that</h5>
                      <div className="row">
                        <div className="col-lg-6 col-12 col-md-6 mt-1 d-flex-center">
                          <a href="tel:917536672">
                            {' '}
                            <img src={phone} alt="girl" className="img-fluid phonea" />
                            <span className="img_text ml-2">+9197536672</span>
                          </a>
                        </div>
                        <div className="col-lg-6 col-12 col-md-6 mt-1 d-flex-center ">
                          <a href="mailto:desiraevet@gmail.com">
                            {' '}
                            <img src={email} alt="girl" className="img-fluid phone" />
                            <span className="img_text ml-2">desiraevet@gmail.com</span>{' '}
                          </a>
                        </div>
                      </div>
                      <span className="img_text mt-2">
                        Lorem Ipsum is that it has a more-or-less normal distribution of letters
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="box-title-icon-border d-flex align-items-center mb-4"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ListingFooter></ListingFooter>
      </>
    );
  }
}

export default SalesTeam;
