import axios from 'axios';
import React, { Component } from 'react';

import hoistStatics from 'hoist-non-react-statics';
import { Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { animateScroll as scroll, scroller } from 'react-scroll';
import ContactPopup from '../Common/ContactPopup';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import { getLanguage } from '../Common/Util';
import carrental from '../assets/images/Business/Car Rental Program For Businesses.jpg';
import BusinessSolutionHeader from './BusinessSolutionHeader';
import ContactUsBox from './ContactUsBox';
import CorporateLeaseBox from './CorporateLeaseBox';
import KeyPointDollar from './KeyPointDollar';
import TransportFleetMenuBox from './TransportFleetMenuBox';
import VehicleByVehicleTypes from './VehicleByVehicleTypes';
class BusinessSolutions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderDafaultValue: 0,
      selectedVehicals: 0,
      selectedOption: 'convertible',
      selectedYear: '12',
      vehicleTypes: [],
      maxVehicles: 500,
      showContact: false,
      leasingEnquiry: {},
    };
  }
  componentDidMount = () => {
    var hasMatchProperty = this.props.hasOwnProperty('match');
    if (hasMatchProperty) {
      const {
        match: { params },
      } = this.props;
      if (params.path != 'top') {
        scroller.scrollTo(params.path, {
          smooth: 'easeInOutQuint',
        });
      } else {
        scroll.scrollToTop();
      }
      if (params.path != 'top') {
        scroller.scrollTo(params.path, {
          smooth: 'easeInOutQuint',
        });
      } else {
        scroll.scrollToTop();
      }
    }

    this.getVehicleTypes();

    scroll.scrollToTop();
  };
  getVehicleTypes = () => {
    let lngParam = getLanguage();
    axios(
      process.env.REACT_APP_API +
        'VehicleClassification/Get?apiKey=' +
        process.env.REACT_APP_API_KEY +
        `&lng=${lngParam}`,
    ).then((response) => {
      const vehicleTypes = response.data;
      this.setState({ vehicleTypes: vehicleTypes });
      if (vehicleTypes.length > 0) {
        this.setState({
          selectedOption: vehicleTypes[0].Name,
        });
      }
    });
  };
  handleSliderChange = (event, newValue) => {
    this.setState({
      selectedVehicals: newValue,
    });
  };

  toogleContactPopup = (show) => {
    this.setState({
      showContact: show,
    });
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    this.setState({
      selectedOption: e.target.value,
    });
  };

  onLeasOptionClick = (maxVehicles) => {
    this.setState({ maxVehicles: maxVehicles });
  };

  onChangeYear = (e) => {
    this.setState({
      selectedYear: e.target.value,
    });
  };

  onClick = () => {
    this.setState({
      showContact: true,
    });
    let leasingEnquiry = {
      selectedVehicalType: this.state.selectedOption,
      selectedVehicalQunatity: this.state.selectedVehicals,
      selectedYear: this.state.selectedYear,
    };
    this.setState({
      leasingEnquiry: leasingEnquiry,
    });
  };

  onMenuLinkClick = (path) => {
    this.props.history.push(path);
    scroll.scrollToTop();
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dollar Car Rental UAE | Commercial Vehicle Rentals</title>
          <link rel="canonical" href="www.dollaruae.com/business-solutions" />
          <meta
            property="og:title"
            content="Dollar Car Rental UAE | Commercial Vehicle Rentals"
          ></meta>
          <meta property="og:site_name" content="Dollar Car Rental"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/business-solutions"></meta>
          <meta
            property="og:description"
            content="Explore a range of commercial vehicles tailored to meet your need. We proudly host UAE's largest & widest transportation fleet. "
          ></meta>
          <meta property="og:type" content="business.business"></meta>
          <meta
            property="og:image"
            content="https://www.dollaruae.com/static/media/431848417.41772347.jfif"
          ></meta>
          <meta name="title" content="Dollar Car Rental UAE | Commercial Vehicle Rentals"></meta>
          <meta
            name="description"
            content="Explore a range of commercial vehicles tailored to meet your need. We proudly host UAE's largest & widest transportation fleet. "
          ></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/business-solutions"></meta>
          <meta
            property="og:title"
            content="Dollar Car Rental UAE | Commercial Vehicle Rentals"
          ></meta>
          <meta
            property="og:description"
            content="Explore a range of commercial vehicles tailored to meet your need. We proudly host UAE's largest & widest transportation fleet. "
          ></meta>
          <meta
            property="og:image"
            content="https://www.dollaruae.com/static/ media/431848417.41772347.jfif"
          ></meta>
          <meta property="twitter:card" content="summary_large_image"></meta>
          <meta
            property="twitter:url"
            content="https://www.dollaruae.com/business-solutions"
          ></meta>
          <meta
            property="twitter:title"
            content="Dollar Car Rental UAE | Commercial Vehicle Rentals"
          ></meta>
          <meta
            property="twitter:description"
            content="Explore a range of commercial vehicles tailored to meet your need. We proudly host UAE's largest & widest transportation fleet. "
          ></meta>
          <meta
            property="twitter:image"
            content="https://www.dollaruae.com/static/media/431848417.41772347.jfif"
          ></meta>
        </Helmet>
        <ListingHeader></ListingHeader>
        <div className="minH100 bg-grey">
          <BusinessSolutionHeader></BusinessSolutionHeader>

          <TransportFleetMenuBox></TransportFleetMenuBox>

          <KeyPointDollar></KeyPointDollar>

          <CorporateLeaseBox></CorporateLeaseBox>

          <section className="box-shadow-white-box-section bg-grey py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a mt-0">
                <div className="">
                  <div className="row  flex-reversea">
                    <div className="col-lg-6  col-md-12 col-sm-12 col-12 ">
                      <div className="pickup-location-box bottomAlignContent">
                        <h5 className="font-bold mb-3">
                          {t('Car Rental Program For Businesses.1')}
                        </h5>
                        <p class="text_p mt-3">
                          {t(
                            'Enjoy discounted corporate rates when you choose Dollar Car Rental UAE. With locations across the UAE, including all major airports, we promise a hassle-free travel & uncompromising convenience. Choose from a wide range of fleet portfolio, custom add on personalizations & get easy access to tracking solutions.1',
                          )}
                        </p>
                        <p class="text_p mt-3 mb-3">
                          {t(
                            'Our corporate leasing works on a pay-for-usage model rather than fleet ownership This allows our clients to use vehicles based on their requirements with simple straightforward lease rentals while not having to worry about the hassles of fleet management servicing insurance damage repair registration rentals replacement vehicles and end-of-term sales Contact us today to know more.1',
                          )}
                        </p>
                        <a
                          onClick={this.onClick}
                          className="primary-bttn primary-invert-bttn text-center mt-xl-auto mt-3"
                          title="   Book Now"
                        >
                          {t('Submit Inquiry.1')}
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6  col-md-12 col-sm-12 col-12 mt-4 mt-sm-0">
                      <img
                        src={carrental}
                        alt="Dollar Business Solutions"
                        className="img-fluid rounded-xl"
                      />
                    </div>
                    <Modal
                      show={this.state.showContact}
                      onHide={() => this.toogleContactPopup(false)}
                      dialogClassName="modal-dialog modal-xl modal-dialog-centered"
                    >
                      <ContactPopup leasingEnquiry={this.state.leasingEnquiry} />
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <VehicleByVehicleTypes vehicleClassificationID="f2a4bbb4-bfd8-4285-bbfc-df5b00567571"></VehicleByVehicleTypes>
          <div className="container-fluid mb-4">
            <div className="text-center">
              <a
                onClick={this.onClick}
                className="primary-bttn primary-invert-bttn text-center mt-xl-auto mt-3"
                title="   Book Now"
              >
                {t('Submit Inquiry.1')}
              </a>
            </div>
          </div>
          <ContactUsBox
            subHeading={t(
              'To know further on how you can manage your fleet more efficiently and other business solutions, contact us today.1',
            )}
            heading={t('We look forward to serving you.1')}
          ></ContactUsBox>
        </div>
        <ListingFooter></ListingFooter>
      </div>
    );
  }
}

export default hoistStatics(withTranslation()(BusinessSolutions), BusinessSolutions);
