import React, { Component } from 'react';
import image from '../assets/images/xpress-checkout.png';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import Truck from '../assets/images/TuSimple_Self_Drving_Truck_4_copy.0.png';
import Group73 from '../assets/images/Group-733.png';
import ZeroImage from '../assets/images/0x0.png';
import ContactUsBox from './ContactUsBox';
import { withTranslation } from 'react-i18next';
class TruckRental extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <ListingHeader />
        <div className="minH100">
          <section className="box-shadow-white-box-section grey-bg py-2" id="id6">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a " id="partner">
                <div className="pad-30">
                  <div className="row">
                    <div className="col-12">
                      <div className="pickup-location-box">
                        <h5 className="font-bold mb-3">
                          <center>{t('Budget-friendly Business Mobility.1')}</center>
                        </h5>

                        <h6>
                          <center>{t('Your keys to efficiency, savings & growth.1')}</center>
                        </h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-4" id="truckrental" name="truckrental">
                      <img src={Truck} alt="Specialized Vehicles" />
                      <div className="pickup-location-flex-content">
                        <h3 className="mb-4">{t('SPECIALIZED VEHICLES.1')}</h3>
                        <span>
                          {t(
                            'Customizable vehicular modifications for business-specific requirements. Based on the needs of a large number of our clients, we have expanded and increased our focus on specialized vehicles, targeted towards the transport/logistics/distribution companies and commercial fleet owners in the UAE. We now offer these vehicles on a long-term lease as per your requirements.1',
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-4">
                      <img src={ZeroImage} alt="Corporate Fleet" />
                      <div className="pickup-location-flex-content">
                        <h3 className="mb-4">{t('CORPORATE FLEET.1')}</h3>
                        <span>
                          {t(
                            'Modern, reliable & adaptable choices for businesses that involve transportation of expensive and perishable goods. All our vehicle models are rigid, ergonomic, and open-for-customizations to suit different business requirements & long-term fleet management solutions. Book a complimentary appointment with any of our sales consultants today for personalized insights into your business.1',
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-4">
                      <img src={Group73} alt="Business Solutions" />
                      <div className="pickup-location-flex-content">
                        <h3 className="mb-4">{t('BUSINESS SOLUTIONS.1')}</h3>
                        <span>
                          {t(
                            'Let our experts support you with scalable vehicle acquisitions and fleet management solutions to ensure significant cost savings, reduced administrative hassles, increased productivity, and risk mitigation within your business. Get personalized assessments & guidance on corporate rentals, fleet acquisition, resale, maintenance, vehicle tracking, fuel, accident management & more.1',
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <ContactUsBox subHeading="" heading={t('Do you have further Question.1')}></ContactUsBox>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(TruckRental);
