import ListingHeader from '../Common/ListingHeader';
import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import SuccessAlert from '../Common/AlertError/SuccessAlert';
import ErrorAlert from '../Common/AlertError/ErrorAlert';
import { getLanguage } from '../Common/Util';
var qs = require('qs');

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NewPassword: '',
      ConfirmPassword: '',
      errorMessage: '',
      successMessage: '',
      driverSuccessMessage: '',
      driverErrorMessage: '',
      aspNetUserID: '',
    };
  }

  componentDidMount = () => {
    this.setState({ aspNetUserID: this.props.match.params.id });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  validate = () => {
    let lngParam = getLanguage();
    this.setState({
      errorMessage: '',
    });
    let valid = true;
    if (this.state.NewPassword.trim() == '') {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The new password field is required',
          successMessage: '',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل كلمة المرور الجديدة',
          successMessage: '',
        });
      }

      return false;
    }
    if (this.state.ConfirmPassword.trim() == '') {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The confirm password field is required',
          successMessage: '',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل تأكيد كلمة المرور',
          successMessage: '',
        });
      }
      return false;
    }
    return valid;
  };

  onSave = () => {
    if (this.validate()) {
      var axios = require('axios');
      var data = qs.stringify({
        AspNetUserID: this.state.aspNetUserID,
        NewPassword: this.state.NewPassword,
        ConfirmPassword: this.state.ConfirmPassword,
      });

      var config = {
        method: 'post',
        url:
          process.env.REACT_APP_API +
          `Account/ChangePassword?apiKey=` +
          process.env.REACT_APP_API_KEY,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },

        data: data,
      };

      axios(config)
        .then((response) => {
          if (response.data.Status == 202) {
            this.setState({
              successMessage: response.data.Message,
              NewPassword: '',
              ConfirmPassword: '',
              errorMessage: '',
            });
          } else {
            this.setState({
              errorMessage: response.data.Message,
              successMessage: '',
            });
          }
        })
        .catch((error) => {});
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <ListingHeader></ListingHeader>
        <div className="minH100 register-form-page bg-grey">
          <div className="container-fluid" id="LoginFormPopup">
            <div className="register-container">
              <h2 className="border-botom">{t('Change Password.1')}</h2>
              <div className="row  register-first-row">
                <div className="col-lg-4 col-sm-6 col-12 mt-sm-0 mt-3">
                  <div className="custom-input-type-wrap">
                    <label htmlFor="first-name">{t('Enter New Password.1')}</label>
                    <input
                      type="password"
                      placeholder={t('Enter New Password.1')}
                      id="NewPassword"
                      name="NewPassword"
                      onChange={this.onChange}
                      value={this.state.NewPassword}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12 mt-sm-0 mt-3">
                  <div className="custom-input-type-wrap">
                    <label htmlFor="last-name">{t('Enter Confirm Password.1')}</label>
                    <input
                      type="password"
                      placeholder={t('Enter Confirm Password.1')}
                      id="ConfirmPassword"
                      name="ConfirmPassword"
                      onChange={this.onChange}
                      value={this.state.ConfirmPassword}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12 mt-sm-0 mt-3 div-bottom-aligned">
                  <div className="custom-input-type-wrap">
                    <button
                      type="button"
                      className="rounded-bttn rounded-bttn-transparent ml-3 mt-3"
                      onClick={() => this.onSave()}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12 mt-sm-0 mt-3">
                  <div className="custom-input-type-wrap">
                    <br />
                    {this.state.successMessage != null && this.state.successMessage.length > 0 ? (
                      <SuccessAlert successMessage={this.state.successMessage} />
                    ) : null}
                    {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
                      <ErrorAlert errorMessage={this.state.errorMessage} />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(ChangePassword)), ChangePassword);
