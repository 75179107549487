import moment from 'moment';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import validator from 'validator';
import ErrorAlert from '../Common/AlertError/ErrorAlert';
import { getLanguage } from '../Common/Util';
import VehicleFeatureBox from '../Common/VehicleFeatureBox';
var qs = require('qs');

class EmailReservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errorMessage: '',
      loading: false,
    };
  }
  SendEmail = () => {
    if (this.validate()) {
      var axios = require('axios');
      let emailList = this.state.email.split(',');

      var config = {
        method: 'post',
        url:
          process.env.REACT_APP_API +
          'Reservations/SendBookingEmailByEmailIDs?apiKey=' +
          process.env.REACT_APP_API_KEY +
          `&reservationID=${this.props.ReservationConfirmId}`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: emailList,
      };

      this.setState({
        loading: true,
      });

      axios(config)
        .then((response) => {
          this.setState({
            loading: false,
          });
          if (response.data.IsValid == true) {
            if (response.data != null && response.data.Message != null) {
              localStorage.setItem('thanksmessage', response.data.Message);
            }

            this.props.history.push('/thankyou');
          } else {
            this.setState({ errorMessage: response.data.Message });
          }
        })
        .catch((error) => {});
    }
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  validate = () => {
    let lngParam = getLanguage();

    let valid = true;
    if (this.state.email.trim() == '') {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The email field is required',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل البريد الإلكتروني',
        });
      }

      return false;
    }

    if (this)
      this.setState({
        errorMessage: '',
      });

    return valid;
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="upgrade-option-modal-content">
          {
            <Modal.Header className="p-0 border-0" closeButton>
              <h2 className="border-botom">{t('Email me reservation.1')}</h2>
            </Modal.Header>
          }
          <Modal.Body>
            <div className="row">
              <div className="col-lg-4">
                <div className="custom-input-type-wrap">
                  <label>{t('Email Address.1')}</label>
                  <input
                    type="email"
                    id="SendToEmailId"
                    placeholder={t('Enter email address.1')}
                    name="email"
                    onChange={this.onChange}
                    value={this.state.email}
                  />
                </div>
              </div>
              <div className="col-lg-2">
                <div className="custom-input-type-wrap">
                  <label>&nbsp;</label>
                  {this.state.loading == false ? (
                    <a title="Send" className="book-now-btttn" onClick={this.SendEmail}>
                      {t('Send.1')}
                    </a>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-6">
                {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
                  <ErrorAlert errorMessage={this.state.errorMessage} />
                ) : null}

                {this.state.loading == true ? (
                  <div style={{ verticalAlign: 'center' }}>
                    <Spinner animation="border" />
                    &nbsp; {t('sending an email.1')}
                  </div>
                ) : null}
              </div>
            </div>
          </Modal.Body>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(EmailReservation));
