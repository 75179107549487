import React, { Component } from 'react';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ErrorAlert from '../Common/AlertError/ErrorAlert';
import Spinner from 'react-bootstrap/Spinner';
import DatePicker from 'react-datepicker';
import BookaServiceImg from '../assets/images/service-request.png';

import { getCustomerId, getLanguage } from '../Common/Util';
import validator from 'validator';
import sp1 from '../assets/images/Business/1706998768.jpg';
import sp2 from '../assets/images/Business/2.jfif';
import sp3 from '../assets/images/Business/2019ToyotaHiAce01.jpg';
import hoistStatics from 'hoist-non-react-statics';
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import ContactUsBox from './ContactUsBox';
import { Helmet } from 'react-helmet';
var qs = require('qs');
class BookService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      contactNo: '',
      customerRemark: '',
      currentKm: '',
      agreementNo: '',
      bookingDate: moment(Date())._d,
      //appointmentDate: moment(Date())._d,
      currentKm: '',
      invoiceNo: '',
      vehicleNo: '',
      errorMessage: '',
      loading: false,
      serviceType: [],
      IsAgreementNoRequired: false,
      IsVehicleNoRequired: false,
      IsInvoiceNoRequired: false,
      IsCurrentKmRequired: false,
      IsAppointmentDateRequired: false,
      customerRequestTypeID: 0,
      successMessage: '',
      MyValue: '',
    };
  }
  componentDidMount() {
    this.getServiceType();
    scroll.scrollToTop();
  }
  getServiceType = () => {
    var axios = require('axios');

    var config = {
      method: 'get',
      url:
        process.env.REACT_APP_API +
        `CustomerRequestType/Get?apiKey=` +
        process.env.REACT_APP_API_KEY,
      headers: {},
    };

    axios(config)
      .then((response) => {
        this.setState({
          serviceType: response.data,
        });
      })
      .catch((error) => {});
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  validate = () => {
    let lngParam = getLanguage();

    this.setState({
      errorMessage: '',
    });

    let valid = true;
    if (this.state.name == undefined || this.state.name == '') {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The customer name field is required',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل اسم العميل',
        });
      }

      return false;
    }
    if (this.state.contactNo == undefined || this.state.contactNo === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'The contact number field is required' });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب مجال ContactNo',
        });
      }

      return false;
    }
    if (this.state.email == undefined || this.state.email === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'The email is required' });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل البريد الإلكتروني',
        });
      }

      return false;
    }
    if (!validator.isEmail(this.state.email)) {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The email field is invalid',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل البريد الإلكتروني',
        });
      }
      return false;
    }
    return valid;
  };

  onMenuLinkClick = (path) => {
    this.props.history.push(path);
    scroll.scrollToTop();
  };

  onBookNowClick = () => {
    let customerId = getCustomerId();

    if (this.validate()) {
      var axios = require('axios');
      var data = qs.stringify({
        customer: {
          id: customerId,
          name: this.state.name,
          email: this.state.email,
          contactNo: this.state.contactNo,
        },
        vehicleNo: this.state.vehicleNo,
        agreementNo: this.state.agreementNo,
        invoiceNo: this.state.invoiceNo,
        customerRequestBookingOn: this.state.bookingDate,
        currentKms: this.state.currentKm,
        customerRemark: this.state.customerRemark,
        appointmentOn: this.state.appointmentDate,
        appointmentRemark: null,
        vendorID: '00000000-0000-0000-0000-000000000000',
        customerRequestType: {
          customerRequestTypeID: this.state.customerRequestTypeID,
        },
        customerRequestRefNo: 234,
        description: null,
      });

      var config = {
        method: 'post',
        url:
          process.env.REACT_APP_API +
          `CustomerRequest/Post?apiKey=` +
          process.env.REACT_APP_API_KEY,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: data,
      };
      this.setState({
        loading: true,
      });
      axios(config)
        .then((response) => {
          if (response.data.IsValid == true) {
            this.setState({
              loading: false,
            });

            if (response.data != null && response.data.Message != null) {
              localStorage.setItem('thanksmessage', response.data.Message);
            }

            window.location = '/thankyou';
          } else {
            if (response.data.Message != null) {
              this.setState({
                errorMessage: response.data.Message,
              });
            } else {
              this.setState({
                errorMessage: response.data.Message,
              });
            }
            this.setState({
              loading: false,
            });
          }
        })
        .catch((error) => {});
    }
  };
  onServiceTypeChange = (e) => {
    if (e.target.value != 'Service Type') {
      let obj = JSON.parse(e.target.value);
      this.setState({
        customerRequestTypeID: obj.CustomerRequestTypeID,
        IsAgreementNoRequired: obj.IsAgreementNoRequired,
        IsVehicleNoRequired: obj.IsVehicleNoRequired,
        IsInvoiceNoRequired: obj.IsInvoiceNoRequired,
        IsCurrentKmRequired: obj.IsCurrentKmRequired,
        IsAppointmentDateRequired: obj.IsAppointmentDateRequired,
      });
    } else {
      this.setState({
        customerRequestTypeID: false,
        IsAgreementNoRequired: false,
        IsVehicleNoRequired: false,
        IsInvoiceNoRequired: false,
        IsCurrentKmRequired: false,
        IsAppointmentDateRequired: false,
      });
    }
  };
  onChangePickUpDate = (date) => {
    this.setState({ appointmentDate: date });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dollar Car Rental UAE | Book a Service </title>
          <link rel="canonical" href="www.dollaruae.com/book-service" />
          <meta property="og:title" content="Dollar Car Rental UAE | Book a Service "></meta>
          <meta property="og:site_name" content="Dollar Car Rental"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/"></meta>
          <meta
            property="og:description"
            content="For any questions about your vehicle’s operation, repair, rental or servicing needs, contact us at our 24x7 priority support - write to us or call us on 800 4710."
          ></meta>
          <meta property="og:type" content="business.business"></meta>
          <meta
            property="og:image"
            content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
          <meta name="title" content="Dollar Car Rental UAE | Book a Service "></meta>
          <meta
            name="description"
            content="For any questions about your vehicle’s operation, repair, rental or servicing needs, contact us at our 24x7 priority support - write to us or call us on 800 4710."
          ></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/book-service"></meta>
          <meta property="og:title" content="Dollar Car Rental UAE | Book a Service "></meta>
          <meta
            property="og:description"
            content="For any questions about your vehicle’s operation, repair, rental or servicing needs, contact us at our 24x7 priority support - write to us or call us on 800 4710."
          ></meta>
          <meta
            property="og:image"
            content="https://www.dollaruae.com/static/media/service-request.1a7d4de8.png"
          ></meta>
          <meta property="twitter:card" content="summary_large_image"></meta>
          <meta property="twitter:url" content="https://www.dollaruae.com/book-service"></meta>
          <meta property="twitter:title" content="Dollar Car Rental UAE | Book a Service "></meta>
          <meta
            property="twitter:description"
            content="For any questions about your vehicle’s operation, repair, rental or servicing needs, contact us at our 24x7 priority support - write to us or call us on 800 4710."
          ></meta>
          <meta
            property="twitter:image"
            content="https://www.dollaruae.com/static/media/service-request.1a7d4de8.png"
          ></meta>
        </Helmet>
        <ListingHeader />
        <div className="minH100">
          <section className="box-shadow-white-box-section grey-bg pb-2 pt-3">
            <div className="container-fluid">
              <div className="box-shadow-white-box" id="About">
                <div className="row">
                  <div className="col-lg-7 p-lg-3">
                    <h1 class="border-botom">{t('Book a Service Online.1')}</h1>
                    <h2 class="border-botom">{t('BOOK YOUR WHEELS WITH DOLLAR.1')}</h2>
                    <p>{t('In few quick steps.1')}</p>
                    <p class="text_p mt-4">
                      {t(
                        'At Dollar Car Rental, we care about you and your vehicle. Our goal is to resolve any issues you may have with your vehicle, quickly and completely. This includes scheduled maintenance services and checks. Kindly drop in your rental details below, to book a service and well get in touch with you at the earliest.1',
                      )}
                    </p>
                    <p class="text_p mt-4">
                      {t(
                        'For any questions about your vehicle’s operation, repair, rental or servicing needs, contact us at our 24x7 priority support, 800 4710.1',
                      )}
                    </p>
                    <img
                      src={BookaServiceImg}
                      alt="Dollar Booking Service"
                      class="img-fluid rounded-xl mt-4"
                    ></img>
                  </div>
                  <div className="col-lg-5" id="book">
                    <div className="p-lg-3 bg_custom">
                      <h5 className="font-weight-bold pt-3">{t('Book a Service.1')}</h5>
                      <br />

                      <div classNa me="mt-0">
                        <div className="row ml-md-3">
                          <div className="col-12 ">
                            <div className="custom-input-type-wrap" style={{ width: '80%' }}>
                              <label htmlFor="exampleInputEmail1" className="book-text">
                                <h6 className="font-weight-bold mb-0">{t('Service Type.1')}</h6>
                              </label>
                              <select
                                name="seviceType"
                                className="filter-box w-100"
                                onChange={this.onServiceTypeChange}
                                defaultValue={'Service Type'}
                              >
                                <option value="Service Type">{t('Service Type.1')}</option>
                                {this.state.serviceType.map((type) => (
                                  <option
                                    key={type.CustomerRequestTypeID}
                                    value={JSON.stringify(type)}
                                  >
                                    {type.Name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4 ml-md-3">
                          <div className="col-12 ">
                            <div className="custom-input-type-wrap" style={{ width: '80%' }}>
                              <label htmlFor="exampleInputEmail1" className="book-text">
                                <h6 className="font-weight-bold">{t('Customer Name.1')}</h6>
                              </label>
                              <input
                                type="email"
                                name="name"
                                placeholder={t('Customer Name.1')}
                                onChange={this.onChange}
                                value={this.state.name}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4 ml-md-3">
                          <div className="col-12 ">
                            <div className="custom-input-type-wrap" style={{ width: '80%' }}>
                              <label htmlFor="exampleInputEmail1" className="book-text">
                                <h6 className="font-weight-bold">{t('Contact Number.1')}</h6>
                              </label>
                              <input
                                type="email"
                                name="contactNo"
                                placeholder={t('Contact Number.1')}
                                onChange={this.onChange}
                                value={this.state.contactNo}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4 ml-md-3">
                          <div className="col-12 ">
                            <div className="custom-input-type-wrap" style={{ width: '80%' }}>
                              <label htmlFor="exampleInputEmail1" className="book-text">
                                <h6 className="font-weight-bold">{t('Email ID.1')}</h6>
                              </label>
                              <input
                                type="email"
                                name="email"
                                placeholder={t('Email ID.1')}
                                onChange={this.onChange}
                                value={this.state.email}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4 ml-md-3">
                          <div className="col-12 ">
                            <div className="custom-input-type-wrap" style={{ width: '80%' }}>
                              <label htmlFor="exampleInputEmail1" className="book-text">
                                <h6 className="font-weight-bold">{t('Customer Remark.1')}</h6>
                              </label>
                              <br />
                              <input
                                type="email"
                                name="customerRemark"
                                placeholder={t('Customer Remark.1')}
                                onChange={this.onChange}
                                value={this.state.customerRemark}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row ml-md-3">
                          <div className="col-12 ">
                            {this.state.IsAgreementNoRequired == true ? (
                              <div className="custom-input-type-wrap mt-4" style={{ width: '80%' }}>
                                <label htmlFor="exampleInputEmail1" className="book-text">
                                  <h6 className="font-weight-bold">{t('Agreement Number.1')}</h6>
                                </label>
                                <input
                                  type="text"
                                  name="agreementNo"
                                  placeholder={t('Agreement Number.1')}
                                  onChange={this.onChange}
                                  value={this.state.agreementNo}
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div className="row ml-md-3">
                          <div className="col-12 ">
                            {this.state.IsVehicleNoRequired == true ? (
                              <div className="custom-input-type-wrap mt-4" style={{ width: '80%' }}>
                                <label htmlFor="exampleInputEmail1" className="book-text">
                                  <h6 className="font-weight-bold">{t('Vehicle Number.1')}</h6>
                                </label>
                                <input
                                  type="text"
                                  name="vehicleNo"
                                  placeholder={t('Vehicle No.1')}
                                  onChange={this.onChange}
                                  value={this.state.vehicleNo}
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div className="row ml-md-3">
                          <div className="col-12 ">
                            {this.state.IsCurrentKmRequired == true ? (
                              <div className="custom-input-type-wrap mt-4" style={{ width: '80%' }}>
                                <label htmlFor="exampleInputEmail1" className="book-text">
                                  <h6 className="font-weight-bold">{t('Current Km.1')}</h6>
                                </label>
                                <input
                                  type="text"
                                  name="currentKm"
                                  placeholder={t('Current Km.1')}
                                  onChange={this.onChange}
                                  value={this.state.currentKm}
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div className="row ml-md-3">
                          <div className="col-12 ">
                            {this.state.IsAppointmentDateRequired == true ? (
                              <div className="custom-input-type-wrap mt-4" style={{ width: '80%' }}>
                                <label htmlFor="exampleInputEmail1" className="book-text">
                                  <h6 className="font-weight-bold">{t('Appointment Date.1')}</h6>
                                </label>
                                <br />

                                <DatePicker
                                  selected={this.state.appointmentDate}
                                  onChange={(date) => this.onChangePickUpDate(date)}
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <hr />
                        {/* className="mt-0"  */}
                        <div className="px-3 bg_custom">
                          <div className="row">
                            <div className="form-group">
                              {this.state.loading == false ? (
                                <button
                                  className="primary-bttn primary-invert-bttn text-center"
                                  onClick={this.onBookNowClick}
                                >
                                  {t('BOOK NOW.1')}
                                </button>
                              ) : null}
                            </div>
                          </div>
                          <div className="row">
                            {this.state.errorMessage != null &&
                            this.state.errorMessage.length > 0 ? (
                              <ErrorAlert errorMessage={this.state.errorMessage} />
                            ) : null}
                            {this.state.loading == true ? (
                              <div className="form-group">
                                <div style={{ verticalAlign: 'center' }}>
                                  <Spinner animation="border" />
                                  &nbsp;{t('Sending quote request.1')}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="subscriber-red-section">
            <div className="container">
              <div className="other-products-news-wrap">
                <h2>{t('BOOK YOUR WHEELS WITH DOLLAR.1')}</h2>
                <div className="row other-products-news-row">
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="other-products-news-box">
                      <div className="other-products-news-image">
                        <img src={sp1} alt="Third Article" />
                        <div className="other-products-news-content">
                          <h3>{t('Fleet Management Solutions.1')}</h3>
                          <p>{t('Hands-on Expertise to save your Business Thousands.1')}</p>
                        </div>
                      </div>
                      <div className="other-products-news-button">
                        <a onClick={() => this.onMenuLinkClick('/fleetmanagementsolutions')}>
                          {t('Fleet Management Solutions.1')}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 mt-4 mt-md-0">
                    <div className="other-products-news-box">
                      <div className="other-products-news-image">
                        <img src={sp2} alt="Third Article" />
                        <div className="other-products-news-content">
                          <h3>{t('Dollar Prestige.1')}</h3>
                          <p>{t('Luxury Vehicles without the Luxury Price Tags.1')}</p>
                        </div>
                      </div>
                      <div className="other-products-news-button">
                        <a onClick={() => this.onMenuLinkClick('/dollar-prestige')}>
                          {t('Dollar Prestige.1')}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12  mt-4 mt-lg-0">
                    <div className="other-products-news-box">
                      <div className="other-products-news-image">
                        <img src={sp3} alt="Third Article" />
                        <div className="other-products-news-content">
                          <h3>{t('Budget-friendly Business Mobility.1')}</h3>
                          <p>{t('Your keys to efficiency, savings & growth.1')}</p>
                        </div>
                      </div>
                      <div className="other-products-news-button">
                        <a onClick={() => this.onMenuLinkClick('/explorecorporatefleet')}>
                          {t('Business Mobility.1')}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(withRouter(withRouter(BookService)));
