import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
// import email from '../assets/images/email.png';
// import phone from '../assets/images/phone.png';
// import phone2 from '../assets/images/phone2.svg';
import tickMarkIcon from '../assets/images/tick-mark.png';
// import TrueIcon from '../assets/images/True.svg';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
// import ContactUsBox from  '../Other/ContactUsBox';

class ThankYou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageToDisplay: 'Thank you for getting in touch.',
    };
  }

  componentDidMount = () => {
    let messageToDisplay = 'Thank you for getting in touch.';
    let message = localStorage.getItem('thanksmessage');
    if (message !== null && message.toString() !== '') {
      messageToDisplay = message.toString();
    }
    localStorage.removeItem('thanksmessage');
    this.setState({ messageToDisplay: messageToDisplay });
  };

  render() {
    // const { t } = this.props;
    return (
      <div>
        <ListingHeader></ListingHeader>

        <div className="minH100 box-shadow-white-box-a my-0 background-grey">
          <div className="container">
            <div className="row pt-5 align-items-center justify-content-center ">
              <div className="col-sm-12 col-lg-8 col-md-12 col-12">
                <div id="reserve p-3 contact-us-quick-box">
                  <div className="rental-steps-wrap ">
                    <div className="pickup-location-box text-center">
                      <img src={tickMarkIcon} alt="Thank you" className="mb-3" />
                      <h3 className="font-bold mb-2  ">{this.state.messageToDisplay}</h3>
                      <div className="pickup-location-flex">
                        <div className="pickup-location-flex-content">
                          {/* <span>
                                                    {t('We appreciate you contacting us about Change You are very important to us, Our team will reach out to you.1')}.
                                                        
                                            </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <ContactUsBox subHeading ="" heading ="Do you have further Question?"></ContactUsBox> */}
            </div>
            <br></br>
            <br></br>
            <div className="col-lg-12">
              <div className="p-3 contact-us-quick-box">
                <h5>Do you have further Question?</h5>
                <p></p>
                <div className="contact-us-quick-call">
                  <a className="phone-quick" href={'tel:+97143365065'}>
                    <i class="fa fa-phone"></i>+971 4 336 5065
                  </a>
                  <a className="email-quick" href="mailto:customer.care@dollaruae.ae">
                    Email address: customer.care@dollaruae.ae
                  </a>
                </div>
              </div>
            </div>

            {/* 
                        <div className="row py-5">
                            <div className="col-sm-12 col-lg-12 col-md-12 col-12">
                            <div className="box-shadow-white-radius">
                                <div className="pt-3 pl-4 pr-3 pb-3  border-topshow thankyou-text">
                                    <h3 className="mb-2">24x7. Right Here for You.</h3>
                                    <span>For support related to making reservations, upcoming rentals, rentals in progress, completed rentals, corporate lease, or other general enquiries/concerns.</span>
                                    <br/>
                                    <div className="row mb-2">
                                        
                                    </div>
                                    <div className="row">
                                    <div className="col-lg-3 col-12 col-md-12 mt-1 d-flex-center">
                                            <a href="mailto:makeabooking@dollaruae.ae"> <img src={email} alt="girl" className="img-fluid phone" style={{ width: '21px' }} />
                                                <span className="img_text ml-2">
                                                makeabooking@dollaruae.ae
                                 </span>
                                            </a>
                                        </div>

                                        <div className="col-lg-3 col-12 col-md-6 mt-1 d-flex-center">
                                            <a href="tel:+97143365065">  <img src={phone} alt="girl" className="img-fluid phonea" />
                                                <span className="img_text ml-2">
                                                +971 4 3365065
                                 </span></a>
                                        </div>
                                        <div className="col-lg-6 col-12 col-md-6 mt-1 d-flex-center">
                                            <a href="tel:800"> <img src={phone2} alt="girl" className="img-fluid phone" />
                                                <span className="img_text ml-2">
                                                    (Toll Free): 800 DOLLAR
                                 </span>
                                            </a></div></div>
                                </div>
                            </div>

                        </div>
                            </div> */}
          </div>
        </div>
        <ListingFooter />
      </div>
    );
  }
}

export default hoistStatics(withTranslation()(ThankYou), ListingFooter);
