import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';

import { getLanguage } from '../Common/Util';
import ListingCard from './ListingCard';
var axios = require('axios');
var qs = require('qs');

class ListingUpgradeOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      upgradeModels: [],
    };
  }
  componentDidMount() {
    let lngParam = getLanguage();
    this.getUpgradeOptions(lngParam);
  }

  getUpgradeOptions = (lngParam) => {
    let searchParam = this.props.searchParam;
    searchParam.VehicleModelID = searchParam.VehicleModelID;
    searchParam.TariffGroupID = searchParam.TariffGroupID;

    var data = qs.stringify(searchParam);

    var config = {
      method: 'post',
      url:
        process.env.REACT_APP_API +
        'SearchVehicleModel/GetUpgradeSuggestions?apiKey=' +
        process.env.REACT_APP_API_KEY +
        `&lng=${lngParam}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        const upgradeModels = response.data;

        this.setState({
          upgradeModels: upgradeModels,
        });
      })
      .catch((error) => {});
  };

  onBookNowClickHandler = () => {
    this.props.BookShow();
  };

  onBookUpgradeClickHandler = (upgradeModel) => {
    upgradeModel.PaymentStatus = this.props.searchParam.PaymentStatus;
    this.props.BookUpgrade(
      upgradeModel,
      this.props.searchParam.VehicleModelID,
      this.props.searchParam.TariffGroupID,
    );

    setTimeout(() => {
      this.props.onUpdateCalculation(upgradeModel);
    }, 0);
  };

  onClosePopupHandler = () => {
    this.props.CloseUpgradePopup(false);
  };

  render() {
    let countOfUpgrade = this.state.upgradeModels.length >= 3 ? 3 : this.state.upgradeModels.length;

    const settings = {
      infinite: true,
      slidesToShow: countOfUpgrade,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
          },
        },
      ],
    };
    const { t } = this.props;

    return (
      <div className="upgrade-option-modal-content p-0" style={{ visibility: 'auto' }}>
        <div className="d-flex justify-content-between box-title-icon-border">
          <h2 class="border-botom pb-0">
            <b>{t('Upgrade Options.1')}</b>
          </h2>
          {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onClosePopupHandler}>
                        <span aria-hidden="true">×</span>
                    </button> */}
        </div>
        <Slider {...settings}>
          {this.state.upgradeModels.map((model, index) => {
            return (
              <div key={index} className="offer-box-wrapper mb-0 mt-4 upgrade-option-boxx-wrapper">
                <div className="upgrade-slider-box-wrap" key={model.VehicleModelID}>
                  <div className="vehicle-design-upgrade-box compact-box-wrap">
                    <ListingCard
                      model={model}
                      hideUpgradeOptions={1}
                      showDiffPrice={1}
                      rateDifference={
                        Math.round(model.UpgradeCharges.UpgradeExtraRateTotal * 100) / 100
                      }
                    />
                  </div>
                  <div className="text-center mt-3">
                    <a
                      onClick={() => this.onBookUpgradeClickHandler(model)}
                      title="Book Now"
                      className="vehicle-box-design-book"
                      tabIndex="0"
                    >
                      {t('Book Now.1')}
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
        {/* <div className="w-100 text-center border-top pt-lg-3 pt-2 mt-lg-0 mt-2">
                    <a onClick={this.onBookNowClickHandler} title="Continue with [Vehicle Name]" className="book-vehicle-name-btn">
                        {t('Continue with ')}
                    </a>
                </div> */}
      </div>
    );
  }
}

export default hoistStatics(withTranslation()(ListingUpgradeOption), ListingUpgradeOption);
