import React from 'react';
import CarCard from './CarCard';
import styles from './CarSelection.module.css';

const carData = [
  {
    name: 'Suzuki Ciaz',
    image:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/1fb36229dddc9e836c4dfca23d9befbefa7a8b2aa708a780fe16ab11879da2d8?apiKey=ac85e0a3444a46c7baa2de216eef8e21&&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
    specs: ['4', '4', '1.5 L', '4', 'A', 'AC'],
    price: '1,299',
  },
  {
    name: 'Suzuki Baleno',
    image:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/a242dbc85481db1ad30496bfb7e95b2dfe555280e877b6dab9673c287825f702?apiKey=ac85e0a3444a46c7baa2de216eef8e21&&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
    specs: ['4', '4', '1.5 L', '4', 'A', 'AC'],
    price: '1,399',
  },
  {
    name: 'Toyota Yaris',
    image:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/944fa6e615970a7b55333ce4d03e60d617fed3a8720e711fb6f9731d85ffcffc?apiKey=ac85e0a3444a46c7baa2de216eef8e21&&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
    specs: ['4', '4', '1.5 L', '4', 'A', 'AC'],
    price: '1,399',
  },
  {
    name: 'Nissan Sunny',
    image:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/ca68d1c02145b6a154d226e81325c08d5bc709d05eafb310d6ff6e402773bcb7?apiKey=ac85e0a3444a46c7baa2de216eef8e21&&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
    specs: ['4', '4', '1.5 L', '4', 'A', 'AC'],
    price: '1,425',
  },
  {
    name: 'MG5',
    image:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/9d52451fa9d1b98a578f808bbf3210d6fd0b85ef04384130d11343ad66806550?apiKey=ac85e0a3444a46c7baa2de216eef8e21&&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
    specs: ['4', '4', '1.5 L', '4', 'A', 'AC'],
    price: '1,475',
  },
  {
    name: 'Mazda CX-3',
    image:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/011b814e0a0e7f311c2e6fba27f203fa12750f2f06bd98b436459a07b84974af?apiKey=ac85e0a3444a46c7baa2de216eef8e21&&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
    specs: ['4', '4', '1.5 L', '4', 'A', 'AC'],
    price: '1,649',
  },
  {
    name: 'Hyundai Creta',
    image:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/34529459ce459bd31e4ce0525b4acba7855ddbff34e95f2f018db23a92a0d1be?apiKey=ac85e0a3444a46c7baa2de216eef8e21&&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
    specs: ['4', '4', '1.5 L', '4', 'A', 'AC'],
    price: '1,899',
  },
  {
    name: 'Mazda CX-5',
    image:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/c75abfef1eb640f32bc33010ceaeea31a1833ba56226bbcb4e17a5fa5c9416c6?apiKey=ac85e0a3444a46c7baa2de216eef8e21&&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
    specs: ['4', '4', '1.5 L', '4', 'A', 'AC'],
    price: '2,099',
  },
];

const CarSelection = ({ isMobileView, handleEnquireNowClick }) => {
  return (
    <section className={styles.carSelectionSection}>
      <h2 className={styles.sectionTitle}>Choose Your Car</h2>
      <div className={styles.carGrid}>
        {carData.map((car, index) => (
          <CarCard
            key={index}
            {...car}
            handleEnquireNowClick={handleEnquireNowClick}
            isMobileView={isMobileView}
          />
        ))}
      </div>
    </section>
  );
};

export default CarSelection;
