import axios from 'axios';
import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { getLanguage } from '../Common/Util';
import NewsTile from './NewsTile';
import Slider from 'react-slick';
import '../assets/css/slick.css';
import LessFinePrevArrow from '../Hire/LessFinePrevArrow';
import LessFineNextArrow from '../Hire/LessFineNextArrow';
const { htmlToText } = require('html-to-text');

class NewsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsList: [],
    };
  }
  componentDidMount() {
    let lngParam = getLanguage();
    var config = {
      method: 'get',
      url:
        process.env.REACT_APP_API +
        'News/Get?apiKey=' +
        process.env.REACT_APP_API_KEY +
        `&noOfRecords=3&lng=${lngParam}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          newsList: response.data,
        });
      })
      .catch((error) => {});
  }

  onViewAllClick = () => {
    localStorage.setItem('newsid', 'null');
    this.props.history.push('/news');
  };

  onNewsClick = (newsId) => {
    localStorage.setItem('newsid', newsId);
    this.props.history.push(`/news/${newsId}`);
  };

  render() {
    const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      dots: true,
      centerPadding: '60px',
      centerMode: true,
      prevArrow: <LessFinePrevArrow />,
      nextArrow: <LessFineNextArrow />,
      rtl: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            centerPadding: '0px',
            centerMode: true,
          },
        },
      ],
    };

    const { t } = this.props;
    return (
      <section className="what-new-sectio ptb-80">
        <div className="container">
          <div className="new-oddEven-title-wrap">
            <div className="new-oddEven-title-left">
              <h2>{t('What’s New?.1')}</h2>
              <p>{t('All the latest from UAE’s favorite car rental agency.1')}</p>
            </div>
            <div className="new-oddEven-title-right">
              <a className="read-more-link" title="View More" onClick={this.onViewAllClick}>
                {t('View All.1')}
              </a>
            </div>
          </div>
          <div className="what-new-wrap-new">
            <div className="row">
              {' '}
              {this.state.newsList.map((news) => {
                return <NewsTile key={news.NewsID} news={news} />;
              })}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(NewsIndex)), NewsIndex);
