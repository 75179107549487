import React, { useState } from 'react';
import BenefitCard from './BenefitCard';
import styles from './TeacherBenefits.module.css';

const benefitsData = [
  {
    title: 'Option for a No Deposit Rental',
    description: (
      <>
        Teachers may avail a <span className={styles.boldText}>No-Deposit car rental package</span>{' '}
        with a one-time subscription fee (of 10%) to apply on the rental contract.
      </>
    ),
    image:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/976318d6d7c503688e6e616d439c5aaa2a46262f023e974e99598ef6b9db68c2?apiKey=ac85e0a3444a46c7baa2de216eef8e21&&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
  },
  {
    title: 'One Free Additional Driver',
    description: (
      <>
        One additional driver <span className={styles.boldText}>(worth AED 50 + VAT)</span> may be
        added to the rental contract for free.
      </>
    ),
    image:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/d17b46c89aee9f4f2909d21eaf4088bc3cf25f0bbdfec86063e895a2befb51c1?apiKey=ac85e0a3444a46c7baa2de216eef8e21&&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
  },
  {
    title: 'Free Delivery and Collection',
    description: (
      <>
        Avail <span className={styles.boldText}>free delivery</span> to locations within city limits
        across UAE.
      </>
    ),
    image:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/4d9505fe26c49823c6e5175177a24c9128651c0495bfe3de5016aff60a23727b?apiKey=ac85e0a3444a46c7baa2de216eef8e21&&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
  },
  {
    title: 'Flexible Rental Packages',
    description: (
      <>
        Teachers receive the benefit of our{' '}
        <span className={styles.boldText}>special monthly rental rates</span> across 1, 3, 6, and 9
        month rentals; with an attractive mileage allowance.
      </>
    ),
    image:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/88797c9df8dfbc27171ba40a38ae67b7d9bdbce7a0641592e961971f548d466b?apiKey=ac85e0a3444a46c7baa2de216eef8e21&&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
  },
];

const TeacherBenefits = ({ isMobileView, isTabletView }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? benefitsData.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === benefitsData.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <section className={styles.benefitsSection}>
      <h2 className={styles.sectionTitle}>Teachers Offers Benefits</h2>
      <p className={styles.sectionDescription}>
        Unlock Exclusive Benefits When You Book Your Vehicle with Us
      </p>
      <div className={styles.benefitsGrid}>
        {isMobileView || isTabletView ? (
          <>
            <div className={styles.benefitCard}>
              <img
                src={benefitsData[currentIndex].image}
                alt={benefitsData[currentIndex].title}
                className={styles.benefitImage}
              />
              <div className={styles.benefitContent}>
                <h3 className={styles.benefitTitle}>{benefitsData[currentIndex].title}</h3>
                <p className={styles.benefitDescription}>
                  {benefitsData[currentIndex].description}
                </p>
              </div>
            </div>
            <div className={styles.carouselControl}>
              <span className={styles.buttonContainer}>
                <button onClick={handlePrev} className={styles.arrowButton}>
                  {'<'}
                </button>
                <button onClick={handleNext} className={styles.arrowButton}>
                  {'>'}
                </button>
              </span>
            </div>
          </>
        ) : (
          benefitsData.map((benefit, index) => (
            <BenefitCard
              key={index}
              title={benefit.title}
              description={benefit.description}
              image={benefit.image}
              layout={index < 2 ? 'left' : 'right'} // First two cards have image on left, last two on right
            />
          ))
        )}
      </div>
    </section>
  );
};

export default TeacherBenefits;
