import React, { Component } from 'react';
import image from '../assets/images/xpress-checkout.png';
import babyseater from '../assets/images/baby-seat-icon.png';
import drivers from '../assets/images/driver-icon.png';
import gpstrack from '../assets/images/gps-icons.png';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import ContactUsBox from './ContactUsBox';
import Personalaccident from '../assets/images/personal-accident-icon.png';
import Superdamagewaiver from '../assets/images/super-damage-waiver.png';
import { animateScroll as scroll } from 'react-scroll';
import SocialMediaBox from './SocialMediaBox';
import wifiImage from '../assets/images/wi-fi-50.png';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
class RentalAddons extends Component {
  componentDidMount() {
    scroll.scrollToTop();
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t('Dollar Car Rental UAE | Self-Drive Cars at Affordable Prices.1')} </title>
          <link rel="canonical" href="www.dollaruae.com/car-rental-addons" />
          <meta
            property="og:title"
            content="Dollar Car Rental UAE | Self-Drive Cars at Affordable Prices"
          ></meta>
          <meta property="og:site_name" content="Dollar Car Rental"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/"></meta>
          <meta
            property="og:description"
            content="Rent a Car in Dubai & Across UAE with Dollar Car Rental. Explore the best of monthly, weekly, daily & long-term car rentals - and avail big discounts and unmissable offers. "
          ></meta>
          <meta property="og:type" content="business.business"></meta>
          <meta
            property="og:image"
            content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
          <meta
            name="title"
            content="Dollar Car Rental UAE | Self-Drive Cars at Affordable Prices"
          ></meta>
          <meta
            name="description"
            content="Rent a Car in Dubai & Across UAE with Dollar Car Rental. Explore the best of monthly, weekly, daily & long-term car rentals - and avail big discounts and unmissable offers. "
          ></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/rental addons"></meta>
          <meta
            property="og:title"
            content="Dollar Car Rental UAE | Self-Drive Cars at Affordable Prices"
          ></meta>
          <meta
            property="og:description"
            content="Rent a Car in Dubai & Across UAE with Dollar Car Rental. Explore the best of monthly, weekly, daily & long-term car rentals - and avail big discounts and unmissable offers. "
          ></meta>
          <meta
            property="og:image"
            content="https://www.dollaruae.com/static/media/xpress-checkout.f266bf62.png"
          ></meta>
          <meta property="twitter:card" content="summary_large_image"></meta>
          <meta property="twitter:url" content="https://www.dollaruae.com/rental addons"></meta>
          <meta
            property="twitter:title"
            content="Dollar Car Rental UAE | Self-Drive Cars at Affordable Prices"
          ></meta>
          <meta
            property="twitter:description"
            content="Rent a Car in Dubai & Across UAE with Dollar Car Rental. Explore the best of monthly, weekly, daily & long-term car rentals - and avail big discounts and unmissable offers. "
          ></meta>
          <meta
            property="twitter:image"
            content="https://www.dollaruae.com/static/media/xpress-checkout.f266bf62.png"
          ></meta>
        </Helmet>
        <ListingHeader />
        <div className="minH100">
          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a ">
                <div className="">
                  <div className="row flex-reversea">
                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                      <div className="pickup-location-box">
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content">
                            <h2 className="border-botom">{t('Rental Add-Ons.1')}</h2>
                            <p>{t('For Your Dollar Car Rental.1')}</p>
                            <p className="text_p mt-4">
                              {t(
                                'Enhance your Dollar Car Rental Experience with our practical easy-to-rent add-ons & amenities. Be it ‘Child & Baby seats’ for your kid’s safety, ‘Additional Drivers’ to take turns driving through your journey, or GPS trackers to embark on adventures during your road trips, we will have the perfect options for you as you make your rental reservations - both online & offline.1',
                              )}
                            </p>
                            {/* <p className="text_p mt-4">We will have the perfect options for you as you make your rental reservations - both online & offline. </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                      <img src={image} alt="Rental Add-Ons" className="img-fluid rounded-xl" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="box-shadow-white-box-section grey-bg py-2">
            <div className="container-fluid">
              <div className="box-shadow-white-box">
                <div className="categories-content-wrap">
                  <h5 className="font-weight-bold">{t('Categories.1')}</h5>
                  <div className="categories-box-wrap div-text-center-align">
                    <div className="categories-box-col">
                      <div className="categories-box-pink">
                        <div className="categories-box-icon rounded-xl">
                          <img src={babyseater} alt="Baby Seater" />
                        </div>
                        <div className="categories-box-content">
                          <h6>{t('Child & Baby seats.1')}</h6>
                          <p>{t('Buckle in your kid’s safety & comfort.1')}</p>
                        </div>
                      </div>
                    </div>
                    <div className="categories-box-col">
                      <div className="categories-box-pink">
                        <div className="categories-box-icon rounded-xl">
                          <img src={drivers} alt="Additional Driver" />
                        </div>
                        <div className="categories-box-content">
                          <h6>{t('Additional Driver.1')}</h6>
                          <p>{t('Take turns in the driver’s seat.1')}</p>
                        </div>
                      </div>
                    </div>
                    <div className="categories-box-col">
                      <div className="categories-box-pink">
                        <div className="categories-box-icon rounded-xl">
                          <img src={gpstrack} alt="GPS Tracker" />
                        </div>
                        <div className="categories-box-content">
                          <h6>{t('GPS Tracker.1')}</h6>
                          <p>{t('Through adventures - anytime, anywhere.1')}</p>
                        </div>
                      </div>
                    </div>

                    <div className="categories-box-col">
                      <div className="categories-box-pink">
                        <div className="categories-box-icon rounded-xl">
                          <img src={Personalaccident} alt="Personal Accident Insurance" />
                        </div>
                        <div className="categories-box-content">
                          <h6>{t('Personal Accident Insurance.1')}</h6>
                          <p>{t('More than just insurance.1')}</p>
                        </div>
                      </div>
                    </div>

                    <div className="categories-box-col">
                      <div className="categories-box-pink">
                        <div className="categories-box-icon rounded-xl">
                          <img src={Superdamagewaiver} alt="Super Damage Waiver" />
                        </div>
                        <div className="categories-box-content">
                          <h6>{t('Super Damage Waiver.1')}</h6>
                          <p>{t('Worry-less coverage for your wheels.1')}</p>
                        </div>
                      </div>
                    </div>

                    <div className="categories-box-col">
                      <div className="categories-box-pink">
                        <div className="categories-box-icon rounded-xl">
                          <img src={wifiImage} alt="Wifi" />
                        </div>
                        <div className="categories-box-content">
                          <h6>{t('Wifi.1')}</h6>
                          <p>{t('Stay connected on the move.1')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <SocialMediaBox></SocialMediaBox>
          <ContactUsBox subHeading="" heading={t('Do you have further Question.1')}></ContactUsBox>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(RentalAddons);
