import React, { Component } from 'react';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import hoistStatics from 'hoist-non-react-statics';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import carrental from '../assets/images/Business/Car Rental Program For Businesses.jpg';
import LeasingInquiry from './LeasingInquiry';
import VehicleClassifications from '../Hire/VehicleClassifications';
import globalpartner from '../assets/images/Business/Para 1.jfif';
import awardwinnig from '../assets/images/Business/Para 2.jfif';
import BusinessMobilityBox from './BusinessMobilityBox';
import VehicleByVehicleTypes from './VehicleByVehicleTypes';
import { withTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import ContactPopup from '../Common/ContactPopup';
import { Helmet } from 'react-helmet';
class ExploreCorporateFleet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderDafaultValue: 0,
      selectedVehicals: 0,
      selectedOption: 'convertible',
      selectedYear: '12',
      vehicleTypes: [],
      maxVehicles: 500,
      showContact: false,
      leasingEnquiry: {},
    };
  }

  onMenuLinkClick = (path) => {
    this.props.history.push(path);
    scroll.scrollToTop();
  };

  componentDidMount = () => {
    scroll.scrollToTop();
  };

  onClick = () => {
    this.setState({
      showContact: true,
    });
    let leasingEnquiry = {
      selectedVehicalType: this.state.selectedOption,
      selectedVehicalQunatity: this.state.selectedVehicals,
      selectedYear: this.state.selectedYear,
    };
    this.setState({
      leasingEnquiry: leasingEnquiry,
    });
  };
  toogleContactPopup = (show) => {
    this.setState({
      showContact: show,
    });
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dollar Car Rental UAE | Best In Class Corporate Fleet Management</title>
          <link rel="canonical" href="www.dollaruae.com/explore-corporate-fleet" />
          <meta
            property="og:title"
            content="Dollar Car Rental UAE | Best In Class Corporate Fleet Management"
          ></meta>
          <meta property="og:site_name" content="Dollar Car Rental"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/"></meta>
          <meta
            property="og:description"
            content="A complete rental provider for corporate needs, Dollar Car Rental UAE caters to every aspect of your requirement and provide modern, exclusive and the ultimate transportation solutions. "
          ></meta>
          <meta property="og:type" content="business.business"></meta>
          <meta
            property="og:image"
            content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
          <meta
            name="title"
            content="Dollar Car Rental UAE | Best In Class Corporate Fleet Management"
          ></meta>
          <meta
            name="description"
            content="A complete rental provider for corporate needs, Dollar Car Rental UAE caters to every aspect of your requirement and provide modern, exclusive and the ultimate transportation solutions.  "
          ></meta>
          <meta property="og:type" content="website"></meta>
          <meta
            property="og:url"
            content="https://www.dollaruae.com/explore-corporate-fleet"
          ></meta>
          <meta
            property="og:title"
            content="Dollar Car Rental UAE | Best In Class Corporate Fleet Management"
          ></meta>
          <meta
            property="og:description"
            content="A complete rental provider for corporate needs, Dollar Car Rental UAE caters to every aspect of your requirement and provide modern, exclusive and the ultimate transportation solutions. "
          ></meta>
          <meta
            property="og:image"
            content="https://www.dollaruae.com/static/media/Car%20Rental%20Program%20For%20Businesses.cbcc4c78.jpg"
          ></meta>
          <meta property="twitter:card" content="summary_large_image"></meta>
          <meta
            property="twitter:url"
            content="https://www.dollaruae.com/explore-corporate-fleet"
          ></meta>
          <meta
            property="twitter:title"
            content="Vehicle Rentals for Businesses,Corporate Fleet Services"
          ></meta>
          <meta
            property="twitter:description"
            content="A complete rental provider for corporate needs, Dollar Car Rental UAE caters to every aspect of your requirement and provide modern, exclusive and the ultimate transportation solutions. "
          ></meta>
          <meta
            property="twitter:image"
            content="https://www.dollaruae.com/static/media/Car%20Rental%20Program%20For%20Businesses.cbcc4c78.jpg"
          ></meta>
        </Helmet>
        <ListingHeader />
        <div className="minH100 bg-grey">
          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a mt-0">
                <div className="">
                  <div className="row  flex-reversea">
                    <div className="col-lg-6  col-md-12 col-sm-12 col-12 ">
                      <div className="pickup-location-box">
                        <div class="border-titlec-content mb-4">
                          <h1 class="mb-1">{t('Car Rental Program For Businesses.1')}</h1>
                          <h2 class="mb-1">{t('Budget-friendly Business Mobility.1')}</h2>
                          <p class="font-18 text-grey">
                            {t('Startup or Fortune 500, Rentals for All.1')}
                          </p>
                        </div>
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content">
                            <span>
                              {t(
                                'Enjoy discounted corporate rates when you choose Dollar Car Rental UAE. With locations across the UAE, including all major airports, we promise a hassle-free travel & uncompromising convenience. Choose from a wide range of fleet portfolio, custom add on personalizations & get  easy access to tracking solutions. Our corporate leasing works on a pay-for-usage model, rather than fleet ownership. This allows our clients to use vehicles, based on their requirements with simple straightforward lease rentals, while not having to worry about the hassles of fleet management, servicing, insurance, damage repair, registration rentals, replacement vehicles, and end-of-term sales.1',
                              )}
                            </span>
                            <span>{t('Contact us today to know more.1')}</span>
                          </div>
                          {/* <a href="" className="rounded-bttn rounded-bttn-transparent mt-3 mb-3" title="   Book Now">
                                       Submi Inquiry
                                    </a> */}
                          <br />
                        </div>
                        <img
                          src={carrental}
                          alt="Dollar Explore"
                          className="img-fluid rounded-xl"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6  col-md-12 col-sm-12 col-12 mt-4 mt-0">
                      <div className="pickup-location-box">
                        <LeasingInquiry />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <BusinessMobilityBox></BusinessMobilityBox>
          <section className="py-5 bg-grey">
            <div className="container">
              <div className="border-titlec-content mb-5">
                <h2 className="mb-1">{t('Our Fleet. Your Keys.1')}</h2>
                <p className="font-18 text-grey">
                  {t('Choose your wheels from UAE’s Largest & Widest Rental Fleet.1')}
                </p>
              </div>
              <div className="width-75 py-5 mx-auto">
                <div className="row align-items-center">
                  <div className="col-md-4 col-12">
                    <img src={globalpartner} className="rounded-xl" alt="Dollar On Fleet"></img>
                  </div>
                  <div className="col-md-8 col-12  mt-4 mt-md-0">
                    <div className="oddEven-content pl-md-5 pl-0">
                      <h3 className="font-24">
                        {t('Experience only the best at your ultimate car rental destination.1')}
                      </h3>
                      <p className="font-18 text-grey pt-3">
                        {' '}
                        {t(
                          'At Dollar Car Rental UAE, it is easy to find a rental car that suits your budget and your style.1',
                        )}{' '}
                      </p>
                      <p className="font-18 text-grey pt-3">
                        {' '}
                        {t(
                          'Dollar offers a large selection of high-quality, well-maintained, and latest models of rental vehicles designed to make getting to your destination comfortable and fun. All vehicles in our fleet are non-smoking, and each year we add more and more fuel-efficient vehicles, which means even more savings for you. In the UAE, Dollar Car Rental offers a wide range of great rental cars that includes SUVs, sedans, hatchbacks, economy & luxury rentals.1',
                        )}{' '}
                      </p>
                      <p className="font-18 text-grey pt-3">
                        {t(
                          'No matter what kind of vehicle fits your needs, Dollar has just what you’re looking for at a great rate, backed by excellent service.1',
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row align-items-center pt-5">
                  <div className="col-md-8 col-12 order-1 order-md-0 mt-4 mt-md-0">
                    <div className="oddEven-content pr-md-5 pr-0">
                      <p className="font-18 text-grey pt-3">
                        {t(
                          'Feel free to reach out to us for the season’s latest competitive rates, offers, and deals from Dollar Car Rental UAE towards a vehicle of your choice and budget.1',
                        )}{' '}
                      </p>
                      <p className="font-18 text-grey pt-3">
                        {t(
                          'Not sure which vehicle model to go for? Look for the detail indicators against each vehicle listing to help understand its seating capacity, luggage capacity, engine specs, auto/manual, and more. Nevertheless, our representatives would be happy to assist you in finding your best pocket-friendly fit.1',
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-12">
                    <img src={awardwinnig} className="rounded-xl" alt="Dollar Awards"></img>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="bg-white py-5">
            <div className="container">
              <VehicleClassifications />
            </div>
          </section>

          <div className="paddingBottom">
            <VehicleByVehicleTypes vehicleClassificationID="f2a4bbb4-bfd8-4285-bbfc-df5b00567571"></VehicleByVehicleTypes>
            <div className="container-fluid mb-4">
              <div className="text-center">
                <a
                  onClick={this.onClick}
                  className="primary-bttn primary-invert-bttn text-center mt-xl-auto mt-3"
                  title={t('Book Now.1')}
                >
                  {t('Submit Inquiry.1')}
                </a>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showContact}
          onHide={() => this.toogleContactPopup(false)}
          dialogClassName="modal-dialog modal-xl modal-dialog-centered"
        >
          <ContactPopup leasingEnquiry={this.state.leasingEnquiry} />
        </Modal>
        {/* <ContactUsBox subHeading ="" heading ="Do you have further Question?"></ContactUsBox> */}
        <ListingFooter />
      </>
    );
  }
}

export default hoistStatics(
  withTranslation()(withRouter(ExploreCorporateFleet)),
  ExploreCorporateFleet,
);
//export default CorporateFleets;
