import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import { getYear } from 'date-fns';
import range from 'lodash/range';
import moment from 'moment';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { withTranslation } from 'react-i18next';
import Switch from 'react-input-switch';
import { withRouter } from 'react-router-dom';
import validator from 'validator';
import { getCurrency, getCustomerId, getLanguage } from '../Common/Util';
import VehicleFeatureBoxVertical from '../Common/VehicleFeatureBoxVertical';
import RentalPolicies from '../ListingPage/RentalPolicies';
import ViewInsuranceDetails from '../ListingPage/ViewInsuranceDetails';
import dollarExpress from '../assets/images/DollarExpress1.png';
import protection from '../assets/images/choose-your-protection-icon.svg';
import documentattachments from '../assets/images/document-attachments-icon.svg';
import driverdetails from '../assets/images/driver-details-icon.svg';
import infothin from '../assets/images/iconmonstr-info-thin.svg';
import savetimeatcounter from '../assets/images/save-time-at-the-counter.svg';
import ViewExtrasDetails from './MonthlyViewExtrasDetails';
import ViewIncludeLongText from './MonthlyViewIncludeLongText';

var qs = require('qs');
class MonthlyListingPage3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      insuranceList: [],
      ancillaryList: [],
      countryList: [],
      allowedKm: '',
      currencyCode: '',

      isChecked: false,
      seat: 1,
      selectedInsurnace: '',
      selectedAncillary: '',
      PAI: false,
      SCDW: false,
      CDW: false,
      showInsuranceViewDetails: false,
      showRentalDetails: false,
      showExtrasDetails: false,
      extrasDesriptionToShow: '',
      insuranceDesriptionToShow: '',
      selectedAncillaryList: [],
      includeSection: [],
      LongTextShow: '',
      RateType: '',
      RateTypeAncillary: '',
      reservationForBooking: '',

      calculationDetails: [],
      loading: false,
      errorMessage: '',
      DriverDetails: [],
      dob: moment(Date())._d,
      CreatedOn: Date().toLocaleString(),
      isCheked: true,
      firstname: '',
      lastname: '',
      email: '',
      contactno: '',
      airlinename: '',
      flightnumber: '',
      showDocAttachement: 1,
      nationalitycode: '',
      nationalityName: '',
      selectedFile: null,
      AttachmentID: 0,
      passportSelectedFile: null,
      PassportAttachmentID: 0,
      loadingText: '',
      selectedAgeGreaterThan22: '',
      childSeat: '0',
      showSCDWDetails: false,
      allowToSubmit: false,
      isPayOnline: false,
      showUpgrade: false,
      hasUpgrade: false,
      isUpgrade: false,
      IPAddress: '',
      getSCDWInsuranceData: [],
      isDocumentUploadMandatory: false,
      isTerminationPolicyChecked: false,
      subscriptionTermsConditionsShortText: '',
      subscriptionTermsConditionsLongText: '',
      showSubscriptionTermsDetails: false,
    };
  }
  componentDidMount() {
    this.getIPAddress();
    let lngParam = getLanguage();
    this.state.currencyCode = getCurrency();
    this.getAllInsurance(lngParam);
    this.getAllAncillary(lngParam);
    //this.getRateCalculation2(this.state.PAI, this.state.CDW, this.state.SCDW,this.state.selectedAncillaryList);
    this.getCountryList();

    this.state.selectedAgeGreaterThan22 = 0;
    // // For new Booking select PAI as default
    // if(this.props.searchParam.ModifiedReservationID == '00000000-0000-0000-0000-000000000000')
    // {
    //     this.state.selectedInsurnace = this.state.selectedInsurnace + ',' + 0; // PAI value is 0
    //     this.state.PAI = true;
    // }
    setTimeout(() => {
      this.getRateCalculation2(
        this.state.PAI,
        this.state.CDW,
        this.state.SCDW,
        this.state.selectedAncillaryList,
      );
      this.GetSubscriptionTermsCondition();
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    this.getIPAddress();
  }

  getIPAddress = () => {
    var config = {
      method: 'get',
      url: 'https://geolocation-db.com/json/',
      headers: {},
    };

    axios(config)
      .then((res) => {
        this.setState({
          IPAddress: res.data.IPv4,
        });
      })
      .catch((error) => {});
  };

  getReservationForBookingSection = (lngParam) => {
    var data = qs.stringify(this.props.searchParam);
    var config = {
      method: 'post',
      url:
        process.env.REACT_APP_API +
        'MonthlySubscription/GetReservationForBooking?apiKey=' +
        process.env.REACT_APP_API_KEY +
        `&lng=${lngParam}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        this.setState({ reservationForBooking: response.data });
        this.setState({ includeSection: response.data.BookingIncluded });

        if (
          this.state.reservationForBooking.Renter.FirstName != null &&
          this.state.reservationForBooking.Renter.FirstName != ''
        ) {
          // In case of login or existing reservation ID, we will get Renter details.
          this.state.firstname = this.state.reservationForBooking.Renter.FirstName;
          this.state.lastname = this.state.reservationForBooking.Renter.LastName;
          this.state.email = this.state.reservationForBooking.Renter.EmailId;
          this.state.contactno = this.state.reservationForBooking.Renter.ContactNo;
          this.state.nationalitycode =
            this.state.reservationForBooking.Renter.CountryState.NationalityCode;
          this.state.nationalityName =
            this.state.reservationForBooking.Renter.CountryState.NationalityName;
          this.state.dob = moment(this.state.reservationForBooking.Renter.BirthDate)._d;
          this.setState({ selectedAgeGreaterThan22: true });
          this.state.airlinename = this.state.reservationForBooking.AirlinesName;
        }

        this.props.searchParam.CheckInOn = this.state.reservationForBooking.InDetail.Timestamp;
        this.props.additionalParam.dropOffLocationName =
          this.state.reservationForBooking.OutDetail.Name;
        this.props.additionalParam.dropOffLocationBranchDescription =
          this.state.reservationForBooking.OutDetail.BranchDescription;
        this.props.additionalParam.dropOffLocationAddress =
          this.state.reservationForBooking.OutDetail.Address;
        this.props.additionalParam.dropOffLocationMessage =
          this.state.reservationForBooking.OutDetail.BranchMessage;

        this.props.additionalParam.pickupLocationName =
          this.state.reservationForBooking.InDetail.Name;
        this.props.additionalParam.pickupLocationBranchDescription =
          this.state.reservationForBooking.InDetail.BranchDescription;
        this.props.additionalParam.pickupLocationAddress =
          this.state.reservationForBooking.InDetail.Address;
        this.props.additionalParam.pickupLocationMessage =
          this.state.reservationForBooking.InDetail.BranchMessage;

        if (
          this.props.searchParam.ModifiedReservationID != '00000000-0000-0000-0000-000000000000'
        ) {
          // Get & Bind list of ancillaries & insurance selected in previous reservation.
          if (this.state.reservationForBooking.RentalIn.IsPAISelected == 1) {
            this.state.selectedInsurnace = this.state.selectedInsurnace + ',' + 0; // PAI value is 0
            this.state.PAI = true;
          }

          if (this.state.reservationForBooking.RentalIn.IsCDWSelected == 1) {
            this.state.selectedInsurnace = this.state.selectedInsurnace + ',' + 1; // CDW value is 0
            this.state.CDW = true;
          }

          if (this.state.reservationForBooking.RentalIn.IsSCDWSelected == 1) {
            this.state.selectedInsurnace = this.state.selectedInsurnace + ',' + 2; // SCDW value is 0
            this.state.SCDW = true;
          }
          let ancillaryList = this.state.ancillaryList;

          this.state.reservationForBooking.RentalIn.AncillaryIn.map((ancillary) => {
            this.state.selectedAncillary =
              this.state.selectedAncillary + ',' + ancillary.AncillaryID;
            this.state.selectedAncillaryList.push({
              AncillaryID: ancillary.AncillaryID,
              Qty: ancillary.Qty,
            });
            this.setState({ selectedAncillary: this.state.selectedAncillary });
            this.setState({ selectedAncillaryList: this.state.selectedAncillaryList });

            // ancillaryList.forEach(anc=>{
            //     if(anc.AncillaryTariffID == ancillary.AncillaryID){
            //       //  alert(anc.AncillaryTariffID);
            //         anc.Qty = this.state.listingPageancillaryQty;
            //     }
            // });
          });

          this.setState({ ancillaryList: ancillaryList });

          // Get recalculation based on selection.
          this.getRateCalculation2(
            this.state.PAI,
            this.state.CDW,
            this.state.SCDW,
            this.state.selectedAncillaryList,
          );
        }

        //alert(this.props.searchParam.PaymentStatus);
      })
      .catch((error) => {});
  };

  toogleViewIncludeLongTextPopUp = (showHide, LongText) => {
    this.setState({
      showExtrasDetails: showHide,
    });
    this.setState({
      LongTextShow: LongText,
    });
  };
  getAllAncillary = (lngParam) => {
    let searchParam = this.props.searchParam;
    searchParam.VehicleModelID = this.getSelectedVehicleModelID();
    searchParam.TariffGroupID = this.getSelectedTarrifGroupID();
    searchParam.CustomerID = getCustomerId();
    searchParam.UpgradeModelId = this.getUpgradeVehicleModelID();

    var data = qs.stringify(searchParam);

    var config = {
      method: 'post',
      url:
        process.env.REACT_APP_API +
        'MonthlySubscriptionAddOn/GetAncillary?apiKey=' +
        process.env.REACT_APP_API_KEY +
        `&lng=${lngParam}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        this.setState({
          ancillaryList: response.data,
        });

        let selectedAncillary = this.state.selectedAncillary;
        let selectedAncillaryList = this.state.selectedAncillaryList;

        response.data.forEach((item) => {
          if (item.SelectionType == 1 && item.IsCompulsoryOnRental == true) {
            selectedAncillary = selectedAncillary + ',' + item.AncillaryTariffID;
            selectedAncillaryList.push({ AncillaryID: item.AncillaryTariffID, Qty: 1 });
          }
          if (item.SelectionType == 2 && item.IsCompulsoryOnRental == true) {
            selectedAncillaryList.push({ AncillaryID: item.AncillaryTariffID, Qty: 1 });
          }
        });
        this.setState({ selectedAncillary: selectedAncillary });
        this.setState({ selectedAncillaryList: selectedAncillaryList });
        this.getReservationForBookingSection(lngParam);
        this.getRateCalculation2(
          this.state.PAI,
          this.state.CDW,
          this.state.SCDW,
          selectedAncillaryList,
        );
      })
      .catch((error) => {});
  };

  getSelectedAgeGreaterThan22 = (e, type) => {
    if (this.state.selectedAgeGreaterThan22 == 1) {
      this.state.selectedAgeGreaterThan22 = 0;
      this.setState({ selectedAgeGreaterThan22: false });
    } else {
      this.state.selectedAgeGreaterThan22 = 1;
      this.setState({ selectedAgeGreaterThan22: true });
    }
  };

  getSelectedInsurance = (e, type) => {
    let selectedInsurnace = this.state.selectedInsurnace;

    if (!selectedInsurnace.includes(type)) {
      //Check
      selectedInsurnace = selectedInsurnace + ',' + type;
      this.setState({ selectedInsurnace: selectedInsurnace });

      //Call Get Rate Calculation
      let typeProt = '';
      if (type == 0) {
        typeProt = 'PAI';
        this.getRateCalculation2(
          true,
          this.state.CDW,
          this.state.SCDW,
          this.state.selectedAncillaryList,
        );
      } else if (type == 1) {
        typeProt = 'CDW';

        //Check if CDW is selected and uncheck it
        this.setState({ SCDW: false });

        let newSelectedInsurnaceArray = [];
        let selectedInsurnaceArray = selectedInsurnace.split(',').forEach((item) => {
          if (2 == item) {
          } else {
            newSelectedInsurnaceArray.push(item);
          }
        });
        let newSelectedInsurnace = newSelectedInsurnaceArray.join(',');
        this.setState({ selectedInsurnace: newSelectedInsurnace });
        this.getRateCalculation2(this.state.PAI, true, false, this.state.selectedAncillaryList);
      } else if (type == 2) {
        typeProt = 'SCDW';

        //Check if CDW is selected and uncheck it
        this.setState({ CDW: false });

        let newSelectedInsurnaceArray = [];
        let selectedInsurnaceArray = selectedInsurnace.split(',').forEach((item) => {
          if (1 == item) {
          } else {
            newSelectedInsurnaceArray.push(item);
          }
        });
        let newSelectedInsurnace = newSelectedInsurnaceArray.join(',');
        this.setState({ selectedInsurnace: newSelectedInsurnace });
        this.getRateCalculation2(this.state.PAI, false, true, this.state.selectedAncillaryList);
      }

      this.setState({
        [typeProt]: true,
      });
    } else {
      //Uncheck
      let typeProt = '';
      let newSelectedInsurnaceArray = [];
      //alert(selectedInsurnace);
      let selectedInsurnaceArray = selectedInsurnace.split(',').forEach((item) => {
        if (item == type) {
        } else {
          newSelectedInsurnaceArray.push(item);
        }
      });
      let newSelectedInsurnace = newSelectedInsurnaceArray.join(',');
      //alert(newSelectedInsurnace);
      this.setState({ selectedInsurnace: newSelectedInsurnace });

      if (type == 0) {
        typeProt = 'PAI';
        this.getRateCalculation2(
          false,
          this.state.CDW,
          this.state.SCDW,
          this.state.selectedAncillaryList,
        );
      } else if (type == 1) {
        typeProt = 'CDW';
        this.getRateCalculation2(
          this.state.PAI,
          false,
          this.state.SCDW,
          this.state.selectedAncillaryList,
        );
      } else if (type == 2) {
        typeProt = 'SCDW';
        this.getRateCalculation2(
          this.state.PAI,
          this.state.CDW,
          false,
          this.state.selectedAncillaryList,
        );
      }

      this.setState({
        [typeProt]: false,
      });
    }
  };

  getRateCalculation2 = (isPIA, isCDW, isSCDW, selectedAncillaryList) => {
    let searchParam = this.props.searchParam;
    searchParam.VehicleModelID = this.getSelectedVehicleModelID();
    searchParam.TariffGroupID = this.getSelectedTarrifGroupID();
    searchParam.IsPAISelected = isPIA;
    searchParam.IsCDWSelected = isCDW;
    searchParam.IsSCDWSelected = isSCDW;
    searchParam.AncillaryIn = selectedAncillaryList;
    searchParam.CustomerID = getCustomerId();
    searchParam.UpgradeModelId = this.getUpgradeVehicleModelID();

    var data = qs.stringify(searchParam);
    let url = ``;
    url =
      process.env.REACT_APP_API +
      'MonthlySubscription/GetCalculation?apiKey=' +
      process.env.REACT_APP_API_KEY;
    var config = {
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        this.setState({
          calculationDetails: response.data,
        });

        if (this.state.calculationDetails != null) {
          this.setState({
            isDocumentUploadMandatory: this.state.calculationDetails.IsDocumentUploadMandatory,
          });
        }
      })
      .catch((error) => {});
  };

  getSelectedVehicleModelID = () => {
    if (
      this.props.additionalParam.originalVehicleModelID == '00000000-0000-0000-0000-000000000000'
    ) {
      return this.props.selectVehicleForBooking.VehicleModelID;
    } else {
      return this.props.additionalParam.originalVehicleModelID;
    }
  };

  getSelectedTarrifGroupID = () => {
    if (
      this.props.additionalParam.originalTariffGroupID == '00000000-0000-0000-0000-000000000000'
    ) {
      return this.props.selectVehicleForBooking.TariffGroup.TariffGroupID;
    } else {
      return this.props.additionalParam.originalTariffGroupID;
    }
  };

  getUpgradeVehicleModelID = () => {
    if (
      this.props.additionalParam.originalVehicleModelID == '00000000-0000-0000-0000-000000000000'
    ) {
      return this.props.additionalParam.originalVehicleModelID;
    } else {
      return this.props.selectVehicleForBooking.VehicleModelID;
    }
  };

  getAllInsurance = (lngParam) => {
    let searchParam = this.props.searchParam;
    searchParam.VehicleModelID = this.getSelectedVehicleModelID();
    searchParam.TariffGroupID = this.getSelectedTarrifGroupID();
    searchParam.CustomerID = getCustomerId();
    searchParam.UpgradeModelId = this.getUpgradeVehicleModelID();

    var data = qs.stringify(searchParam);

    let url = ``;
    url =
      process.env.REACT_APP_API +
      `MonthlySubscriptionAddOn/GetInsurance?apiKey=` +
      process.env.REACT_APP_API_KEY +
      `&lng=${lngParam}`;
    var config = {
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        this.setState({
          insuranceList: response.data,
        });
      })
      .catch((error) => {});
  };

  onChangeCountryCode = (e) => {
    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;

    this.setState({
      nationalitycode: e.target.value,
      nationalityName: label,
    });
  };
  onChangeValue = (e) => {
    const isCheked = this.state.isCheked;
    this.setState({
      isCheked: !isCheked,
    });
    if (e.target.value == 'atcounter') {
      this.setState({ showDocAttachement: 0 });
    } else {
      this.setState({ showDocAttachement: 1 });
    }
  };
  onChangePickUpDate = (date) => {
    this.setState({ dob: date });
  };
  getRateCalculation = () => {
    let searchParam = this.props.searchParam;
    searchParam.VehicleModelID = this.getSelectedVehicleModelID();
    searchParam.TariffGroupID = this.getSelectedTarrifGroupID();
    searchParam.CustomerID = getCustomerId();

    searchParam.UpgradeModelId = this.getUpgradeVehicleModelID();

    var data = qs.stringify(searchParam);
    let url = ``;
    url =
      process.env.REACT_APP_API +
      'MonthlySubscription/GetCalculation?apiKey=' +
      process.env.REACT_APP_API_KEY;

    var config = {
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        this.setState({
          calculationDetails: response.data,
        });
      })
      .catch((error) => {});
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getSelectedAncillary = (e, id) => {
    let selectedAncillary = this.state.selectedAncillary;
    let selectedAncillaryList = this.state.selectedAncillaryList;

    if (!selectedAncillary.includes(id)) {
      //Check
      selectedAncillary = selectedAncillary + ',' + id;
      selectedAncillaryList.push({ AncillaryID: id, Qty: 1 });
      this.setState({ selectedAncillary: selectedAncillary });
      this.setState({ selectedAncillaryList: selectedAncillaryList });
      this.setState({
        [id]: true,
      });

      this.getRateCalculation2(
        this.state.PAI,
        this.state.CDW,
        this.state.SCDW,
        selectedAncillaryList,
      );
    } else {
      //Uncheck

      let newSelectedAncillaryArray = [];

      let selectedAncillaryArray = selectedAncillary.split(',').forEach((item) => {
        if (item == id) {
        } else {
          newSelectedAncillaryArray.push(item);
        }
      });
      let newSelectedAncillary = newSelectedAncillaryArray.join(',');
      this.setState({ selectedAncillary: newSelectedAncillary });

      let newSelectedAncillaryList = [];
      this.state.selectedAncillaryList.forEach((item) => {
        if (item.AncillaryID == id) {
        } else {
          newSelectedAncillaryList.push(item);
        }
      });
      this.setState({ selectedAncillaryList: newSelectedAncillaryList });
      this.setState({
        [id]: false,
      });
      this.getRateCalculation2(
        this.state.PAI,
        this.state.CDW,
        this.state.SCDW,
        newSelectedAncillaryList,
      );
    }
  };

  getSelectedTyp1Ancillary = (e) => {
    var selectedAncillaryList = this.state.selectedAncillaryList;
    var index = selectedAncillaryList.findIndex((an) => an.AncillaryID == e.target.id);
    if (index == -1 && e.target.value > 0) {
      selectedAncillaryList.push({ AncillaryID: e.target.id, Qty: e.target.value });
    } else {
      if (e.target.value == 0)
        // if qty is 0 then remove that from the selected list.
        selectedAncillaryList.splice(index);
      else selectedAncillaryList[index] = { AncillaryID: e.target.id, Qty: e.target.value };
    }

    this.setState({ selectedAncillaryList: selectedAncillaryList });
    this.getRateCalculation2(
      this.state.PAI,
      this.state.CDW,
      this.state.SCDW,
      this.state.selectedAncillaryList,
    );
  };
  getSelectedInsurance = (e, type) => {
    let selectedInsurnace = this.state.selectedInsurnace;

    if (!selectedInsurnace.includes(type)) {
      //Check
      selectedInsurnace = selectedInsurnace + ',' + type;

      this.setState({ selectedInsurnace: selectedInsurnace });

      //Call Get Rate Calculation
      let typeProt = '';
      if (type == 0) {
        typeProt = 'PAI';
        this.getRateCalculation2(
          true,
          this.state.CDW,
          this.state.SCDW,
          this.state.selectedAncillaryList,
        );
      } else if (type == 1) {
        typeProt = 'CDW';

        //Check if CDW is selected and uncheck it
        this.setState({ SCDW: false });

        let newSelectedInsurnaceArray = [];
        let selectedInsurnaceArray = selectedInsurnace.split(',').forEach((item) => {
          if (2 == item) {
          } else {
            newSelectedInsurnaceArray.push(item);
          }
        });
        let newSelectedInsurnace = newSelectedInsurnaceArray.join(',');
        this.setState({ selectedInsurnace: newSelectedInsurnace });
        this.getRateCalculation2(this.state.PAI, true, false, this.state.selectedAncillaryList);
      } else if (type == 2) {
        typeProt = 'SCDW';

        //Check if CDW is selected and uncheck it
        this.setState({ CDW: false });

        let newSelectedInsurnaceArray = [];
        let selectedInsurnaceArray = selectedInsurnace.split(',').forEach((item) => {
          if (1 == item) {
          } else {
            newSelectedInsurnaceArray.push(item);
          }
        });
        let newSelectedInsurnace = newSelectedInsurnaceArray.join(',');
        this.setState({ selectedInsurnace: newSelectedInsurnace });
        this.getRateCalculation2(this.state.PAI, false, true, this.state.selectedAncillaryList);
      }

      this.setState({
        [typeProt]: true,
      });
    } else {
      //Uncheck
      let typeProt = '';
      let newSelectedInsurnaceArray = [];
      //alert(selectedInsurnace);
      let selectedInsurnaceArray = selectedInsurnace.split(',').forEach((item) => {
        if (item == type) {
        } else {
          newSelectedInsurnaceArray.push(item);
        }
      });
      let newSelectedInsurnace = newSelectedInsurnaceArray.join(',');
      //alert(newSelectedInsurnace);
      this.setState({ selectedInsurnace: newSelectedInsurnace });

      if (type == 0) {
        typeProt = 'PAI';
        this.getRateCalculation2(
          false,
          this.state.CDW,
          this.state.SCDW,
          this.state.selectedAncillaryList,
        );
      } else if (type == 1) {
        typeProt = 'CDW';
        this.getRateCalculation2(
          this.state.PAI,
          false,
          this.state.SCDW,
          this.state.selectedAncillaryList,
        );
      } else if (type == 2) {
        typeProt = 'SCDW';
        this.getRateCalculation2(
          this.state.PAI,
          this.state.CDW,
          false,
          this.state.selectedAncillaryList,
        );
      }

      this.setState({
        [typeProt]: false,
      });
    }
  };
  removeSeat = () => {
    const seat = this.state.seat;
    if (seat > 1) {
      const counter = seat - 1;
      this.setState({
        seat: counter,
      });
    }
  };
  addSeat = () => {
    const seat = this.state.seat;
    if (seat < 3) {
      const counter = seat + 1;
      this.setState({
        seat: counter,
      });
    }
  };
  toogleViewExtrasPopUp = (showHide, extrasDesriptionToShow) => {
    this.setState({
      showExtrasDetails: showHide,
    });
    this.setState({
      LongTextShow: extrasDesriptionToShow,
    });
  };

  ValidateDriverDetails = () => {
    let lngParam = getLanguage();
    this.setState({
      errorMessage: '',
    });

    const valid = true;
    if (this.state.firstname == undefined || this.state.firstname === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please fill the first name' });
      } else {
        //ar
        this.setState({
          errorMessage: 'يرجى ملء الاسم الأول',
        });
      }

      return false;
    }

    if (this.state.lastname == undefined || this.state.lastname === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please fill the last name' });
      } else {
        //ar
        this.setState({
          errorMessage: 'الرجاء تعبئة اسم العائلة',
        });
      }

      return false;
    }
    if (this.state.contactno == undefined || this.state.contactno === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please fill the contact no' });
      } else {
        //ar
        this.setState({
          errorMessage: 'يرجى ملء أي اتصال',
        });
      }

      return false;
    }
    if (this.state.email == undefined || this.state.email === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please fill the email' });
      } else {
        //ar
        this.setState({
          errorMessage: 'الرجاء تعبئة البريد الإلكتروني',
        });
      }

      return false;
    }
    if (!validator.isEmail(this.state.email)) {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The email field is invalid',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل البريد الإلكتروني',
        });
      }
      return false;
    }
    if (this.state.dob == undefined || this.state.dob === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please fill the date of birth' });
      } else {
        //ar
        this.setState({
          errorMessage: 'يرجى ملء دوب',
        });
      }

      return false;
    }
    if (this.state.showDocAttachement) {
      if (this.state.selectedFile == null || this.state.selectedFile.name == '') {
        this.setState({ errorMessage: 'Please upload driver`s documents' });
        return false;
      }
      if (this.state.passportSelectedFile == null || this.state.passportSelectedFile.name == '') {
        this.setState({ errorMessage: 'Please upload Drivers Passport' });
        return false;
      }
    }
    if (
      this.state.nationalityName == null ||
      this.state.nationalityName == '' ||
      this.state.nationalityName == 'Select' ||
      this.state.nationalityName == 'SELECT'
    ) {
      this.setState({ errorMessage: 'Please select nationality.' });
      return false;
    }
    if (!this.state.isTerminationPolicyChecked) {
      this.setState({ errorMessage: 'Please read and accept the Termination Policy' });
      return false;
    }

    this.setState({ errorMessage: '' });
    return valid;
  };
  getReservationObjet = (whentoPay, paymentStatus) => {
    if (this.state.selectedAgeGreaterThan22) {
      var dob = new Date();
      this.state.dob = new Date(dob.setFullYear(dob.getFullYear() - 23));
    } else {
      this.state.dob = new Date();
    }
    let data = qs.stringify({
      Identity: {
        Id: '00000000-0000-0000-0000-000000000000',
        Code: null,
      },
      Renter: {
        TitleType: 0,
        //"Name": this.state.firstname + " " + this.state.lastname,
        FirstName: this.state.firstname,
        LastName: this.state.lastname,
        ContactNo: this.state?.Shukran?.length
          ? `${this.state.contactno}; Shukran:${this.state.Shukran}`
          : `${this.state.contactno}`,
        EmailId: this.state.email,
        BirthDate: this.state.dob, //this.state.dob,
        Address: null,
        CountryState: {
          StateCode: null,
          StateName: null,
          CountryCode: null,
          CountryName: null,
          NationalityCode: this.state.nationalitycode,
          NationalityName: this.state.nationalityName,
        },
        DrivingLicense: {
          LicenseNo: '',
          IssuedBy: null,
          IssuedOn: '0001-01-01T00:00:00',
          ExpiresOn: '2022-04-19T13:23:12.827+04:00',
          AttachmentIDs: this.state.AttachmentID,
          Attachement: [],
        },
        Passport: {
          PassportNo: '',
          IssuedBy: null,
          IssuedOn: '0001-01-01T00:00:00',
          ExpiresOn: '2022-04-19T13:23:12.827+04:00',
          IssuedCountryCode: null,
          IssuedCountryName: null,
          AttachmentIDs: this.state.PassportAttachmentID,
          Attachment: [],
        },
        DisplayName: null,
      },
      ReservationDeviceTypeID: 0,
      ContactInfo: {
        Id: this.props.searchParam.CustomerID,
        TitleType: 0,
        Name: null,
        ContactNo: null,
        Email: null,
        Address: null,
        CountryState: {
          StateCode: null,
          StateName: null,
          NationalityCode: this.state.nationalitycode,
          NationalityName: null,
        },
        CorporateCustomer: {
          Name: null,
        },
        DisplayName: null,
        AddEditStatus: 0,
      },
      VehicleModel: {
        RentalCharges: {
          RackRate: 0,
          DiscountedRate: 0,
        },
        RentalTotal: 0,
        IsSpecialOfferApplicable: false,
        SpecialOfferRate: 0,
        DiscountType: 0,
        FavouriteNote: null,
        RentalType: 0,
        SpecialOfferID: '00000000-0000-0000-0000-000000000000',
        DaysDifference: 0,
        DWMRentalCharges: {
          Daily: {
            RackRate: 0,
            DiscountedRate: 0,
          },
          Weekly: {
            RackRate: 0,
            DiscountedRate: 0,
          },
          Monthly: {
            RackRate: 0,
            DiscountedRate: 0,
          },
        },
        noOfRecords: 0,
        VehicleModelID: this.getSelectedVehicleModelID(),
        Name: null,
        ModelYearFrom: 0,
        ModelYearTo: 0,
        Passengers: null,
        Luggages: null,
        Doors: null,
        Airbag: false,
        IsFourByFour: false,
        ABS: false,
        AutoTransmission: false,
        Engine: null,
        CruseControl: false,
        ModelYearWiseCharge: [],
        VehicleMake: {
          VehicleMakeID: this.props.searchParam.VehicleMakeID,
          Name: null,
          SequenceNo: 0,
        },
        VehicleClassification: {
          VehicleClassificationID: '00000000-0000-0000-0000-000000000000',
          Name: null,
          SequenceNo: 0,
          VehicleTypeAttachment: null,
        },
        ACRISCode: {
          Name: null,
          Description: null,
        },
        VehicleImages: {
          FrontImageAttachment: {
            AttachmentID: null,
            AttachmentData: null,
            AttachmentDataString: null,
            ContentType: null,
            FileName: null,
            ImageString: null,
          },
          BackImageAttachment: {
            AttachmentID: null,
            AttachmentData: null,
            AttachmentDataString: null,
            ContentType: null,
            FileName: null,
            ImageString: null,
          },
          LeftImageAttachment: {
            AttachmentID: null,
            AttachmentData: null,
            AttachmentDataString: null,
            ContentType: null,
            FileName: null,
            ImageString: null,
          },
          RightImageAttachment: {
            AttachmentID: null,
            AttachmentData: null,
            AttachmentDataString: null,
            ContentType: null,
            FileName: null,
            ImageString: null,
          },
        },
        SequenceNo: 0,
        Description: null,
        TariffGroup: {
          TariffGroupID: this.getSelectedTarrifGroupID(),
          Name: null,
        },
        SuggestedUpgradeModelIDs: [],
        Features: [],
        IsSoldOut: false,
        IsCallToBook: false,
        IsBestSeller: false,
      },
      OutDetail: {
        BranchID: this.props.searchParam.CheckOutBranchID,
        Name: null,
        Timestamp: '2021-04-19T15:51:00',
      },
      InDetail: {
        BranchID: this.props.searchParam.CheckInBranchID,
        Name: null,
        Timestamp: '2021-04-23T15:51:00',
      },
      ReservationStatus: 0,
      Remark: null,
      PAI: {
        IsSelected: false,
        PerDayCharge: null,
        Name: null,
        InsuranceType: 0,
        Charge: null,
        RentalType: 0,
      },
      CDW: {
        IsSelected: false,
        PerDayCharge: null,
        Name: null,
        InsuranceType: 0,
        Charge: null,
        RentalType: 0,
      },
      SCDW: {
        IsSelected: false,
        PerDayCharge: null,
        Name: null,
        InsuranceType: 0,
        Charge: null,
        RentalType: 0,
      },
      ReservationAncillary: [],
      RentalRateDetail: [],
      IsPayAtLocation: whentoPay,

      BookingSource: 1,
      RentalIn: {
        SubscriptionMonth: this.props.searchParam.SubscriptionMonth,
        SubscriptionKm: this.props.searchParam.SubscriptionKm,
        ModelYear: this.props.searchParam.ModelYear,
        PaymentStatus: paymentStatus,
        VehicleTypeID: this.props.searchParam.VehicleTypeID,
        Classifications: [],
        VehicleMakeID: this.props.searchParam.VehicleMakeID,
        VehicleModelID: this.props.searchParam.VehicleModelID,
        SpecialOfferID: this.props.searchParam.SpecialOfferID,
        CustomerID: this.props.searchParam.CustomerID,
        CheckOutOn: this.props.searchParam.CheckOutOn,
        CheckInOn: this.props.searchParam.CheckInOn,
        CheckOutBranchID: this.props.searchParam.CheckOutBranchID,
        CheckInBranchID: this.props.searchParam.CheckInBranchID,
        DeliveryDetails: {
          Address: this.props.searchParam.DeliveryDetails.Address,
          AddressJson: '',
          StateName: this.props.searchParam.DeliveryDetails.StateName,
        },
        PickupDetails: {
          Address: this.props.searchParam.PickupDetails.Address,
          AddressJson: '',
          StateName: this.props.searchParam.PickupDetails.StateName,
        },
        IsCDWSelected: this.props.searchParam.IsCDWSelected,
        IsSCDWSelected: this.state.SCDW,
        IsPAISelected: this.props.searchParam.IsPAISelected,
        AncillaryIn: this.props.searchParam.AncillaryIn,
        PromoCodeID: '00000000-0000-0000-0000-000000000000',
        PromoCode: this.props.searchParam.PromoCode,
        DriverAge: this.props.searchParam.DriverAge,
        IsPayAtLocation: false,
        MinRentalRate: 0,
        MaxRentalRate: 50000,
        RentalDays: 4,
        ModelWiseChargeID: '00000000-0000-0000-0000-000000000000',
        VehicleVariantID: this.props.searchParam.VehicleVariantID,
        NoOfRecords: 0,
        TariffGroupID: this.getSelectedTarrifGroupID(),
        UpgradeModelId: this.getUpgradeVehicleModelID(),
        ModifiedReservationID: this.props.searchParam.ModifiedReservationID,
      },
      Promocode: {
        PromoCodeID: '00000000-0000-0000-0000-000000000000',
        PromoCode: this.props.searchParam.PromoCode,
      },
      AllowedKm: 0,
      ExtraKmCharges: 0,
      InsuranceExcess: 0,
      AirlinesName: this.state.airlinename,
      FlightNo: this.state.flightnumber,
      PaymentRefNo: null,

      PaymentMode: 1,
      RentalAdditionalSetting: {
        TollAdminFees: null,
        TrafficAdminFees: null,
        InsuranceExcess: 0,
        AllowedKm: 0,
        ExtraKmCharge: 0,
      },
      CreatedOn: this.state.CreatedOn,
      TotalAmount: this.state.calculationDetails.TotalAmount,
      RentalType: 0,
      IPLocation: {
        IPAddress: this.state.IPAddress,
      },
      //"IpGeoLocationDetail": "{\"geobytesforwarderfor\":\"\",\"geobytesremoteip\":\"152.57.193.211\",\"geobytesipaddress\":\"152.57.193.211\",\"geobytescertainty\":\"100\",\"geobytesinternet\":\"US\",\"geobytescountry\":\"United States\",\"geobytesregionlocationcode\":\"USNY\",\"geobytesregion\":\"New York\",\"geobytescode\":\"NY\",\"geobyteslocationcode\":\"USNYNYOR\",\"geobytesdma\":\"501\",\"geobytescity\":\"New York\",\"geobytescityid\":\"10182\",\"geobytesfqcn\":\"New York, NY, United States\",\"geobyteslatitude\":\"40.748798\",\"geobyteslongitude\":\"-73.984596\",\"geobytescapital\":\"Washington\",\"geobytestimezone\":\"-05:00\",\"geobytesnationalitysingular\":\"American\",\"geobytespopulation\":\"278058881\",\"geobytesnationalityplural\":\"Americans\",\"geobytesmapreference\":\"North America \",\"geobytescurrency\":\"US Dollar\",\"geobytescurrencycode\":\"USD\",\"geobytestitle\":\"The United States\"}"
    });

    return data;
  };

  toogleViewSCDWPopUp = () => {
    this.setState({
      showSCDWDetails: true,
    });
  };

  toogleViewSCDWHidePopUp = () => {
    this.setState({
      showSCDWDetails: false,
    });
  };

  onWithOutSCDWClick = () => {
    this.state.allowToSubmit = true;
    this.state.SCDW = false;
    if (this.state.isPayOnline) {
      this.onBookPayNowClick();
    } else {
      this.onBookPayLaterClick();
    }
  };

  onWithSCDWClick = () => {
    this.state.allowToSubmit = true;
    this.state.SCDW = true;
    this.state.selectedInsurnace = this.state.selectedInsurnace + ',' + 2;
    this.getRateCalculation2(
      this.state.PAI,
      this.state.CDW,
      this.state.SCDW,
      this.state.selectedAncillaryList,
    );
    document.getElementById('BookingDetails').scrollIntoView({ behavior: 'smooth' });
    // if(this.state.isPayOnline)
    // {
    //     this.onBookPayNowClick();
    // }
    // else
    // {
    //     this.onBookPayLaterClick();
    // }
  };

  getSCDWFullInsurchanceData = () => {
    let searchParam = this.props.searchParam;
    searchParam.VehicleModelID = this.getSelectedVehicleModelID();
    searchParam.TariffGroupID = this.getSelectedTarrifGroupID();
    searchParam.IsSCDWSelected = true;
    searchParam.CustomerID = getCustomerId();
    searchParam.UpgradeModelId = this.getUpgradeVehicleModelID();

    var data = qs.stringify(searchParam);
    let lngParam = getLanguage();
    let url = ``;
    url =
      process.env.REACT_APP_API +
      'MonthlySubscriptionAddOn/GetOptFullInsurance?apiKey=' +
      process.env.REACT_APP_API_KEY +
      `&lng=${lngParam}`;
    var config = {
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        this.setState({
          getSCDWInsuranceData: response.data,
        });
      })
      .catch((error) => {});
  };

  onBookPayLaterClick = () => {
    if (this.ValidateDriverDetails()) {
      this.state.isPayOnline = false;
      if (
        this.state.allowToSubmit == false &&
        this.state.SCDW == false &&
        this.state.CDW == false
      ) {
        this.getSCDWFullInsurchanceData();
        this.toogleViewSCDWPopUp();
        return;
      }

      this.setState({
        loading: true,
        loadingText: 'Creating the reservation',
      });

      var data = this.getReservationObjet(true, 0);
      var config = {
        method: 'post',
        url:
          process.env.REACT_APP_API +
          `MonthlySubscription/Post?apiKey=` +
          process.env.REACT_APP_API_KEY,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          this.setState({
            DriverDetails: response.data,
          });
          this.setState({ loading: false, loadingText: '' });

          if (response.data.IsValid == true) {
            this.props.history.push(`/reservationconfirm/${response.data.ID}/1`);
          } else {
            this.setState({ errorMessage: response.data.Message });
          }
        })
        .catch((error) => {
          this.setState({ loading: false, loadingText: '' });
        });
    } else {
      this.setState({ loading: false, loadingText: '' });
    }
  };

  onBookPayNowClick = () => {
    if (this.ValidateDriverDetails()) {
      this.state.isPayOnline = true;
      if (
        this.state.allowToSubmit == false &&
        this.state.SCDW == false &&
        this.state.CDW == false
      ) {
        this.getSCDWFullInsurchanceData();
        this.toogleViewSCDWPopUp();
        return;
      }

      this.setState({
        loading: true,
        loadingText: 'Creating the reservation',
      });
      var data = this.getReservationObjet(false, 1);
      var config = {
        method: 'post',
        url:
          process.env.REACT_APP_API +
          `MonthlySubscription/Post?apiKey=` +
          process.env.REACT_APP_API_KEY,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          if (response.data.IsValid == true) {
            if (response.data.PaymentUrl != undefined && response.data.PaymentUrl != null) {
              window.location = response.data.PaymentUrl;
            } else {
              this.props.history.push(`/reservationconfirm/${response.data.ID}/1`);
            }

            // this.setState({ loading: false, loadingText: '' });
          } else {
            this.setState({ loading: false, loadingText: '' });
            this.setState({ errorMessage: response.data.Message });
          }
        })
        .catch((error) => {
          this.setState({ loading: false, loadingText: '' });
        });
    } else {
      this.setState({ loading: false, loadingText: '' });
    }
  };

  openWindowWithPost = (url, parameters) => {
    var form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', url);
    form.setAttribute('id', 'payment_confirmation');

    parameters.map((param) => {
      var item = document.createElement('input');
      item.type = 'hidden';
      item.name = param.Key;
      item.id = param.Key;
      item.value = param.Value;
      form.appendChild(item);
    });

    document.body.appendChild(form);
    form.submit();
    //document.body.removeChild(form);
  };

  handleClose = () => {
    this.setState({
      errorMessage: '',
    });
  };
  //     File Upload
  onFileChange = (e) => {
    // Update the state
    this.setState({ selectedFile: e.target.files[0] });
    const file = e.target.files[0];

    // encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      var data = qs.stringify({
        attachmentID: '00000000-0000-0000-0000-000000000000',
        AttachmentDataBase64: `${reader.result}`,
        contentType: e.target.files[0].type,
        fileName: e.target.files[0].name,
      });

      var config = {
        method: 'post',
        url: process.env.REACT_APP_API + 'Attachment/Post?apiKey=' + process.env.REACT_APP_API_KEY,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          this.setState({ AttachmentID: response.data.ID });
        })
        .catch((error) => {});
    };
    reader.readAsDataURL(file);
  };
  onPassportFileChange = (e) => {
    // Update the state
    // this.setState({ selectedFile: URL.createObjectURL(e.target.files[0]) });
    this.setState({ passportSelectedFile: e.target.files[0] });
    const file = e.target.files[0];

    // encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      var data = qs.stringify({
        attachmentID: '00000000-0000-0000-0000-000000000000',
        AttachmentDataBase64: `${reader.result}`,
        contentType: e.target.files[0].type,
        fileName: e.target.files[0].name,
      });

      var config = {
        method: 'post',
        url: process.env.REACT_APP_API + 'Attachment/Post?apiKey=' + process.env.REACT_APP_API_KEY,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          this.setState({ PassportAttachmentID: response.data.ID });
        })
        .catch((error) => {});
    };
    reader.readAsDataURL(file);
  };
  onPayNowSelected = () => {
    this.props.searchParam.PaymentStatus = 1;
    this.getRateCalculation();
  };

  toogleViewInsurancePopUp = (showHide, insuranceDescriptionToShow) => {
    this.setState({
      showInsuranceViewDetails: showHide,
    });
    this.setState({
      insuranceDesriptionToShow: insuranceDescriptionToShow,
    });
  };

  getCountryList = () => {
    var config = {
      method: 'get',
      url:
        process.env.REACT_APP_API +
        `BookingSetting/GetCountries?apiKey=` +
        process.env.REACT_APP_API_KEY,
      headers: {},
    };

    axios(config)
      .then((response) => {
        this.setState({
          countryList: response.data,
        });
      })
      .catch((error) => {});
  };

  removeItem = (itemID) => {
    let searchParam = this.props.searchParam;
    searchParam.RemovedLedgerID = itemID;
    searchParam.AncillaryIn = this.state.selectedAncillaryList;

    var data = qs.stringify(searchParam);

    var config = {
      method: 'post',
      url:
        process.env.REACT_APP_API +
        'Reservations/HandleRemoveLedger?apiKey=' +
        process.env.REACT_APP_API_KEY +
        `&parameter=${searchParam}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        var data = response.data;
        this.setState({ PAI: data.IsPAISelected });
        this.setState({ CDW: data.IsCDWSelected });
        this.setState({ SCDW: data.IsSCDWSelected });
        var newselectedInsurnace = '';

        if (this.state.PAI) {
          newselectedInsurnace = newselectedInsurnace + ',' + 0;
        } else if (this.state.CDW) {
          newselectedInsurnace = newselectedInsurnace + ',' + 1;
        } else if (this.state.SCDW) {
          newselectedInsurnace = newselectedInsurnace + ',' + 2;
        }

        this.setState({ selectedInsurnace: newselectedInsurnace });

        var newSelectedAncillary = '';
        let newselectedAncillaryList = [];
        data.AncillaryIn.map((ancillary) => {
          newSelectedAncillary = newSelectedAncillary + ',' + ancillary.AncillaryID;

          newselectedAncillaryList.push({ AncillaryID: ancillary.AncillaryID, Qty: ancillary.Qty });
        });
        // this.state.selectedAncillary
        this.setState({ selectedAncillary: newSelectedAncillary });
        this.setState({ selectedAncillaryList: newselectedAncillaryList });

        //Child dropdown list TODO code needs to change for dropdown list
        if (searchParam.AncillaryIn.length > 0) {
          searchParam.AncillaryIn.map((ancillary) => {
            if (ancillary.AncillaryID == itemID) {
              if (!Number.isInteger(ancillary.Qty)) {
                this.setState({ childSeat: 0 });
              }
            }
          });
        }
        this.getRateCalculation2(this.state.PAI, this.state.CDW, this.state.SCDW, data.AncillaryIn);
      })
      .catch((error) => {});
  };

  toogleUpgradeOptionPopup = (showHide) => {
    this.setState({
      showUpgrade: showHide,
    });
  };

  BookUpgrade = (upgradeModel, originalVehicleModelID, originalTariffGroupID) => {
    this.props.onUpgradeModelSelected(upgradeModel, originalVehicleModelID, originalTariffGroupID);
    this.setState({
      showUpgrade: false,
    });
  };

  onUpdateCalculation = (upgradeModel) => {
    this.setState({
      hasUpgrade: false,
    });
    let lngParam = getLanguage();
    this.state.currencyCode = getCurrency();
    this.state.VehicleModelID = upgradeModel.VehicleModelID;
    this.state.TariffGroupID = upgradeModel.TariffGroup.TariffGroupID;
    this.getAllInsurance(lngParam);
    this.getAllAncillary(lngParam);
    this.getRateCalculation2(
      this.state.PAI,
      this.state.CDW,
      this.state.SCDW,
      this.state.selectedAncillaryList,
    );
  };

  onTerminationPolicyClick = () => {
    //window.open("/termination-policy");
    this.setState({
      showSubscriptionTermsDetails: true,
    });
  };

  onTerminationPolicyChange = (e) => {
    let isChecked = this.state.isTerminationPolicyChecked;
    if (!isChecked) this.setState({ isTerminationPolicyChecked: !isChecked });
  };

  GetSubscriptionTermsCondition = () => {
    var axios = require('axios');
    var config = {
      method: 'get',
      url:
        process.env.REACT_APP_API +
        `BookingSetting/GetSubscriptionTermsAndCondition?apiKey=` +
        process.env.REACT_APP_API_KEY,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios(config).then((response) => {
      if (response.data != null) {
        this.setState({ subscriptionTermsConditionsShortText: response.data.ShortText });
        this.setState({
          subscriptionTermsConditionsLongText: response.data.TermsAndConditions,
        });
      }
    });
  };

  subscriptionTermsAndSubscription = (showHide) => {
    this.setState({
      showExtrasDetails: showHide,
    });
    this.setState({
      showSubscriptionTermsDetails: false,
    });
  };

  render() {
    const years = range(1950, getYear(new Date()) + 1, 1);
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const { t } = this.props;
    // var startDate = moment(this.props.searchParam.CheckOutOn);
    // var endDate = moment(this.props.searchParam.CheckInOn);
    return (
      <>
        <div>
          <section className="box-shadow-white-box-section">
            <div className="container-fluid">
              <div className="box-shadow-white-box">
                <div className="pickup-location-box--wrap">
                  <div className="row">
                    <div className="col-sm-6 col-12">
                      <div className="pickup-location-box">
                        <span className="pickup-sm-title">{t('Pickup Location.1')}</span>
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content StationDetails__wrapper p-0 minH-auto">
                            <h3 className="StationDetails__headline w-100">
                              {this.props.additionalParam.dropOffLocationName}
                              <a
                                class="text-red"
                                onClick={() =>
                                  this.toogleViewInsurancePopUp(
                                    true,
                                    this.props.additionalParam.dropOffLocationBranchDescription,
                                  )
                                }
                              >
                                {t('More Info.1')} <i class="fa fa-angle-right"></i>
                              </a>
                            </h3>
                            <p className="StationDetails__subline">
                              {this.props.additionalParam.dropOffLocationAddress}
                            </p>
                            {/* <p className="StationDetails__subline">
                                                            {moment(this.props.searchParam.CheckOutOn).format("DD MMM YYYY HH:mm")}
                                                        </p> */}
                            <p className="StationDetails__subline">
                              {this.props.additionalParam.dropOffLocationMessage}
                            </p>
                            {this.props.searchParam.DeliveryDetails.Address != null &&
                            this.props.searchParam.DeliveryDetails.StateName != null &&
                            this.props.searchParam.DeliveryDetails.Address != undefined &&
                            this.props.searchParam.DeliveryDetails.StateName != undefined &&
                            this.props.searchParam.DeliveryDetails.Address != '' &&
                            this.props.searchParam.DeliveryDetails.StateName != '' ? (
                              <span className="delivery-pickup-box">
                                {t('Delivery.1')} :{' '}
                                <span>
                                  {this.props.searchParam.DeliveryDetails.Address +
                                    ', ' +
                                    this.props.searchParam.DeliveryDetails.StateName}
                                </span>
                              </span>
                            ) : null}
                            {/* <a title="View Details" className="text-16 text-red font-bold d-block" onClick={() => this.toogleViewInsurancePopUp(true, this.props.additionalParam.dropOffLocationBranchDescription)}>
                                                                {t('Branch Information')}
                                                        </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12 mt-4 mt-sm-0">
                      <div className="pickup-location-box">
                        <div className="pickup-location-flex-content StationDetails__wrapper p-0 minH-auto">
                          <span></span>
                          <span className="pickup-sm-title">{t('Pickup Date & Time.1')}</span>
                          <p className="StationDetails__subline">
                            {moment(
                              this.props.searchParam.CheckOutOn,
                              'DD MMMM YYYY, h:mmA',
                            ).format('DD MMMM YYYY, HH:mm')}
                          </p>
                          <br></br>
                          <span className="pickup-sm-title">{t('Return Date & Time.1')}</span>
                          <p className="StationDetails__subline">
                            {moment(this.props.searchParam.CheckInOn).format('DD MMM YYYY HH:mm')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="box-shadow-white-box-section">
            <div className="container-fluid">
              <div className="box-shadow-white-box">
                <div className="box-title-icon-border d-flex align-items-center mb-0 mb-sm-4 mt-0">
                  <span className="box-title-icon-rounded mt-0">
                    <img src={protection} alt="Dollar Protection" width="13" height="16" />
                  </span>
                  <h4 className="text-16 text-red">{t('Add Protection.1')}</h4>
                </div>
                <div className="row">
                  {this.state.insuranceList.map((insurance) => {
                    return (
                      <div
                        className="col-xl-3 col-md-4 col-12 border-right mt-4 mt-md-0"
                        key={insurance.InsuranceType}
                      >
                        <div class="view-details-box-wrap custom-switch custom-control w-100 px-3">
                          <label
                            className="custom-control-label1 w-100"
                            htmlFor="personal-accident-insurance"
                          >
                            <h5 className="text-18">{insurance.Name}</h5>
                            <span className="text-16 font-bold opacity-50 d-block my-1">
                              {this.state.currencyCode} {' ' + insurance.Rate}{' '}
                              {insurance.RateType == 3
                                ? '/Rental'
                                : insurance.RateType == 0
                                  ? '/Day'
                                  : insurance.RateType == 1
                                    ? '/Week'
                                    : insurance.RateType == 2
                                      ? '/Month'
                                      : ''}
                            </span>
                            <a
                              title="View Details"
                              className="text-16 text-red font-bold d-block"
                              onClick={() =>
                                this.toogleViewInsurancePopUp(true, insurance.Description)
                              }
                            >
                              {t('View Details.1')}
                            </a>
                          </label>
                          <Switch
                            className={
                              this.state.selectedInsurnace.includes(insurance.InsuranceType)
                                ? 'custom-switch-wrap custom-switch-active-wrap'
                                : 'custom-switch-wrap'
                            }
                            value={
                              this.state.selectedInsurnace.includes(insurance.InsuranceType)
                                ? insurance.InsuranceType
                                : ''
                            }
                            on={insurance.InsuranceType}
                            onChange={(e) =>
                              this.getSelectedInsurance(
                                e,
                                insurance.InsuranceType,
                                insurance.RateType,
                              )
                            }
                          />
                        </div>
                      </div>
                    );
                  })}

                  <div className="col-xl-2 col-12 ml-auto">
                    <div className="text-16 text-gray text-right d-flex align-items-center justify-content-end mt-3 mt-xl-0">
                      {this.state.currencyCode}&nbsp;
                      <span className="font-bold h4 text-black">
                        {this.state.calculationDetails.InsuranceTotal != null
                          ? this.state.calculationDetails.InsuranceTotal.toFixed(2)
                          : null}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="box-shadow-white-box-section" id="BookingDetails">
            <div className="container-fluid">
              <div className="box-shadow-white-box">
                <div className="box-title-icon-border d-flex align-items-center mb-4 mt-0">
                  <span className="box-title-icon-rounded mt-0">
                    <img
                      src={protection}
                      alt={t('Dollar Rental Add-Ons.1')}
                      width="13"
                      height="16"
                    />
                  </span>
                  <h4 className="text-16 text-red">{t('Rental Add-Ons.1')}</h4>
                </div>
                <div className="row">
                  {this.state.ancillaryList.map((ancillary) => {
                    return (
                      <>
                        <div
                          className="col-xl-5 col-md-6 col-12 mb-3"
                          key={ancillary.AncillaryTariffID}
                        >
                          <div className="extra-box-range-wrap ">
                            <div className="extra-box-range-box mr-3 mb-2 mb-sm-0">
                              <img
                                src={ancillary.Attachment.ImageString}
                                width="27"
                                alt="Dollar Free"
                              />
                            </div>
                            <div className="custom-control-label1 w-100">
                              <h5 className="text-18">
                                {ancillary.Name}
                                {ancillary.FreeDays > 0 ? (
                                  <span className="free-tag-monthly">{t('Free Days.1')}</span>
                                ) : null}
                              </h5>
                              <span className="text-16 font-bold opacity-50 d-block my-1">
                                {this.state.currencyCode} {' ' + ancillary.Rate}{' '}
                                {ancillary.RateType == 3
                                  ? '/Rental'
                                  : ancillary.RateType == 0
                                    ? '/Day'
                                    : ancillary.RateType == 1
                                      ? '/Week'
                                      : ancillary.RateType == 2
                                        ? '/Month'
                                        : ''}
                              </span>
                              <a
                                title="View Details"
                                className="text-16 text-red font-bold d-block"
                                onClick={() =>
                                  this.toogleViewExtrasPopUp(true, ancillary.Description)
                                }
                              >
                                {t('View Details.1')}
                              </a>
                            </div>
                            <div className="input-quantity ml-auto">
                              {ancillary.SelectionType == 1 ? (
                                <Switch
                                  className={
                                    this.state.selectedAncillary.includes(
                                      ancillary.AncillaryTariffID,
                                    )
                                      ? 'custom-switch-wrap custom-switch-active-wrap'
                                      : 'custom-switch-wrap'
                                  }
                                  value={
                                    this.state.selectedAncillary.includes(
                                      ancillary.AncillaryTariffID,
                                    )
                                      ? ancillary.AncillaryTariffID
                                      : ''
                                  }
                                  on={ancillary.AncillaryTariffID}
                                  onChange={(e) =>
                                    this.getSelectedAncillary(e, ancillary.AncillaryTariffID)
                                  }
                                  disabled={ancillary.IsCompulsoryOnRental}
                                  title={t("You can't change this ancillary.1")}
                                />
                              ) : (
                                <select
                                  name="ancillary-type"
                                  value={
                                    this.state.selectedAncillaryList.findIndex(
                                      (an) => an.AncillaryID == ancillary.AncillaryTariffID,
                                    ) > 0
                                      ? this.state.selectedAncillaryList.find(
                                          (x) => x.AncillaryID == ancillary.AncillaryTariffID,
                                        ).Qty
                                      : 0
                                  }
                                  className="baby-seat-select-box"
                                  id={ancillary.AncillaryTariffID}
                                  onChange={(e) => this.getSelectedTyp1Ancillary(e)}
                                >
                                  {ancillary.IsCompulsoryOnRental == false ? (
                                    <option value="0">0</option>
                                  ) : null}
                                  <option value="1">{t('1.1')}</option>
                                  <option value="2">{t('2.1')}</option>
                                  <option value="3">{t('3.1')}</option>
                                </select>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <div className="col-xl-2 col-12 ml-auto">
                    <div className="text-16 text-gray mt-3 mt-xl-0 d-flex align-items-center justify-content-end">
                      {this.state.currencyCode}&nbsp;
                      <span className="font-bold h4 text-black">
                        {this.state.calculationDetails.AncillaryTotal != null
                          ? this.state.calculationDetails.AncillaryTotal.toFixed(2)
                          : null}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="box-shadow-white-box-section">
            <div className="container-fluid">
              <div className="box-shadow-white-box">
                <div className="row">
                  <div className="col-xl-6 col-12">
                    <h5 className="font-bold mb-1">{this.props.selectVehicleForBooking.Name}</h5>
                    <p className="text-16 opacity-50 text-black">
                      {t('or Similar.1')} |{' '}
                      {this.props.selectVehicleForBooking.VehicleClassification.Name}
                      {this.props.selectVehicleForBooking.ModelYear != null ? (
                        <>
                          {' '}
                          | <b> Model Year - {this.props.selectVehicleForBooking.ModelYear} </b>
                        </>
                      ) : null}
                    </p>
                    <div className="row">
                      <div className="col-sm-8 col-12">
                        <div className="img-details-wrap">
                          <img
                            src={
                              this.props.selectVehicleForBooking.VehicleImages.FrontImageAttachment
                                .ImageString
                            }
                            alt="Dollar Features"
                            width="389"
                            height="245"
                          />
                        </div>
                      </div>
                      <div className="col-sm-4 col-12 mobile-view-list">
                        <h5 className="text-16 text-red font-bold mb-3">{t('Features.1')}</h5>
                        <VehicleFeatureBoxVertical model={this.props.selectVehicleForBooking} />
                      </div>
                    </div>
                    <div className="included-wrapper-featured mt-0 pt-0">
                      {this.props.selectVehicleForBooking.Features.length > 0 ? (
                        <p className="vehicle-featuress-content">
                          {t('Vehicle Features.1')}
                          <br></br>
                          {this.props.selectVehicleForBooking.Features.map((feature, index) => {
                            return (
                              <label key={feature.Name} style={{ color: 'grey' }}>
                                {(index ? ', ' : '') + feature.Name}
                              </label>
                            );
                          })}
                        </p>
                      ) : null}
                    </div>
                    {this.props.selectVehicleForBooking.Description.length > 0 ? (
                      <div className="included-wrapper-featured">
                        <p className="vehicle-featuress-content">
                          {t('Vehicle Description.1')}
                          <br></br>

                          <label style={{ color: 'grey' }}>
                            {this.props.selectVehicleForBooking.Description}
                          </label>
                        </p>
                      </div>
                    ) : null}
                    <div className="vehicle-box-detail-left">
                      <ul class="check-box-listing mt-4">
                        {this.state.includeSection != null
                          ? this.state.includeSection.map((include) => {
                              return (
                                <li>
                                  <span>
                                    {include.ShortText}
                                    <a
                                      className="text-16 text-red font-bold d-block"
                                      onClick={() =>
                                        this.toogleViewIncludeLongTextPopUp(true, include.LongText)
                                      }
                                    >
                                      <img alt="Sold-Out-Transparent" src={infothin} />
                                    </a>
                                  </span>
                                </li>
                              );
                            })
                          : ''}
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-6 col-12 border-xl-left pt-4 pt-xl-0 mt-4 mt-xl-0">
                    <div className="booking-detail-wrap pl-xl-4">
                      <div className="d-flex flex-wrap justify-content-between  mb-2 pb-1 align-items-center border-bottom">
                        <h3 className="booking-details-listing-left text-18 font-hel-bold">
                          {this.props.searchParam.SubscriptionMonth > 0 ? (
                            <> {t('Total Months.1')}</>
                          ) : (
                            <> {t('Total Month.1')}</>
                          )}
                        </h3>
                        <div className="booking-details-listing-right  ml-auto">
                          <span>
                            {this.props.searchParam.SubscriptionMonth > 1 ? (
                              <>
                                {this.props.searchParam.SubscriptionMonth} {t('Months.1')}
                              </>
                            ) : (
                              <>
                                {this.props.searchParam.SubscriptionMonth} {t('Month.1')}
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap justify-content-between  mb-2 pb-1 align-items-center border-bottom">
                        <h3 className="booking-details-listing-left text-18 font-hel-bold">
                          {t('Allowed KM.1')}
                        </h3>
                        <div className="booking-details-listing-right  ml-auto">
                          <span>
                            <>
                              {this.props.searchParam.SubscriptionKm} {t('KMS.1')} | {t('Month.1')}
                            </>
                          </span>
                        </div>
                      </div>

                      <h3 className="text-16 text-red mb-3">{t('Booking Details.1')}</h3>
                      <ul className="booking-details-listing">
                        {this.state.calculationDetails.RentalRateDetail != null
                          ? this.state.calculationDetails.RentalRateDetail.map((model, index) => {
                              return (
                                <li className="mb-3" key={index}>
                                  <div className="booking-details-listing-left text-18 font-regular">
                                    {model.Item}{' '}
                                    <span className="text-16 d-block opacity-50 mt-1 font-regular">
                                      {model.Description}
                                    </span>
                                  </div>
                                  <div className="booking-details-listing-right ml-auto">
                                    {this.state.currencyCode}{' '}
                                    <span>{model.SubTotal.toFixed(2)}</span>
                                  </div>
                                  <div className="booking-details-listing-trash text-18 font-regular">
                                    {model.CanBeDeleted == true ? (
                                      <button
                                        type="button"
                                        onClick={() => this.removeItem(model.ItemID)}
                                      >
                                        <span aria-hidden="true">
                                          <i class="fa fa-trash-o"></i>
                                        </span>
                                        <span class="sr-only">{t('Close.1')}</span>
                                      </button>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </li>
                              );
                            })
                          : ''}

                        <li className="mb-3 pt-3 border-top">
                          <div className="booking-details-listing-left text-18 font-hel-bold">
                            {t('Sub Total.1')}{' '}
                            <span className="text-16 d-block opacity-50 mt-1 font-regular"></span>
                          </div>
                          <div className="booking-details-listing-right ml-auto">
                            {this.state.currencyCode}{' '}
                            <span>
                              {this.state.calculationDetails.SubTotal != null
                                ? this.state.calculationDetails.SubTotal.toFixed(2)
                                : null}
                            </span>
                          </div>
                          <div className="booking-details-listing-trash text-18 font-regular"></div>
                        </li>

                        <li className="mb-3">
                          <div className="booking-details-listing-left text-18 font-hel-bold">
                            {t('Tax Total.1')}{' '}
                            <span className="text-16 d-block opacity-50 mt-1 font-regular"></span>
                          </div>
                          <div className="booking-details-listing-right ml-auto">
                            {this.state.currencyCode}{' '}
                            <span>
                              {this.state.calculationDetails.TotalTax != null
                                ? this.state.calculationDetails.TotalTax.toFixed(2)
                                : null}
                            </span>
                          </div>
                          <div className="booking-details-listing-trash text-18 font-regular"></div>
                        </li>

                        <li className="mt-3 pt-2  border-top">
                          <div className="booking-details-listing-left text-18 font-hel-bold text-red">
                            {t('Grand Total.1')}
                          </div>
                          <div className="booking-details-listing-right text-red ml-auto">
                            {this.state.currencyCode}{' '}
                            <span className="h4">
                              {this.state.calculationDetails.TotalAmount != null
                                ? this.state.calculationDetails.TotalAmount.toFixed(2)
                                : null}
                            </span>
                          </div>
                          <div className="booking-details-listing-trash text-18 font-regular"></div>
                        </li>

                        {this.state.calculationDetails.ModificationCharge != null &&
                        this.state.calculationDetails.ModificationCharge.AmountPaid > 0 ? (
                          <ul className="booking-details-listing">
                            <li className="mt-3">
                              <div class="booking-details-listing-left text-18 font-hel-bold text-red ">
                                {t('Paid Amount.1')}
                              </div>
                              <div class="booking-details-listing-right text-red  ml-auto">
                                {this.state.currencyCode}{' '}
                                <span class="h4">
                                  {this.state.calculationDetails.ModificationCharge.AmountPaid.toFixed(
                                    2,
                                  )}
                                </span>
                              </div>
                              <div className="booking-details-listing-trash text-18 font-regular"></div>
                            </li>
                            <li className="mt-3">
                              <div class="booking-details-listing-left text-18 font-hel-bold text-red">
                                {this.state.calculationDetails.ModificationCharge != null &&
                                this.state.calculationDetails.ModificationCharge.AmountToPay > 0
                                  ? 'Pay at Counter'
                                  : 'Refund Amount'}
                              </div>

                              <div class="booking-details-listing-right text-red  ml-auto">
                                {this.state.currencyCode}{' '}
                                <span class="h4">
                                  {this.state.calculationDetails.ModificationCharge != null
                                    ? this.state.calculationDetails.ModificationCharge.AmountToPay >
                                      0
                                      ? this.state.calculationDetails.ModificationCharge.AmountToPay.toFixed(
                                          2,
                                        )
                                      : this.state.calculationDetails.ModificationCharge.AmountToRefund.toFixed(
                                          2,
                                        )
                                    : null}
                                </span>
                              </div>
                              <div className="booking-details-listing-trash text-18 font-regular"></div>
                            </li>
                          </ul>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="box-shadow-white-box-section">
            <div className="container-fluid">
              <div className="box-shadow-white-box">
                <div className="box-title-icon-border d-flex align-items-center mb-4 mt-0">
                  <span className="box-title-icon-rounded mt-0">
                    <img src={driverdetails} alt="Dollar Driver Details" width="16" height="16" />
                  </span>
                  <h4 className="text-16 text-red">{t('Driver Details.1')}</h4>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="custom-input-type-wrap">
                      <label htmlFor="first-name">{t('First Name.1')}</label>
                      <input
                        type="text"
                        placeholder={t('Enter First Name.1')}
                        id="first-name"
                        name="firstname"
                        onChange={this.onChange}
                        value={this.state.firstname}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12 mt-sm-0 mt-4">
                    <div className="custom-input-type-wrap">
                      <label htmlFor="last-name">{t('Last Name.1')}</label>
                      <input
                        type="text"
                        placeholder={t('Enter Last Name.1')}
                        id="last-name"
                        name="lastname"
                        onChange={this.onChange}
                        value={this.state.lastname}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12 mt-lg-0 mt-4">
                    <div className="custom-input-type-wrap">
                      <label htmlFor="contact-number">{t('Contact Number.1')}</label>
                      <input
                        type="text"
                        placeholder={t('Enter Contact Number.1')}
                        id="contactno"
                        name="contactno"
                        onChange={this.onChange}
                        value={this.state.contactno}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12 mt-4">
                    <div className="custom-input-type-wrap">
                      <label htmlFor="first-name">{t('Email Address.1')}</label>
                      <input
                        type="text"
                        placeholder={t('Enter Email Address.1')}
                        id="email"
                        name="email"
                        onChange={this.onChange}
                        value={this.state.email}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-6 col-12 mt-4">
                    <div className="custom-input-type-wrap">
                      <label htmlFor="contact-number">{t('Nationality.1')}</label>

                      <select
                        className="filter-box"
                        onChange={this.onChangeCountryCode}
                        value={this.state.nationalitycode}
                      >
                        <option value="SELECT">{t('SELECT.1')}</option>
                        {this.state.countryList.map((CountryDetail) => (
                          <option value={CountryDetail.Code}>{CountryDetail.Name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12 mt-4">
                    <div className="custom-input-type-wrap pickup-dropoff-date-wrap1 h-100">
                      <div className="extra-box-range-wrap h-100 flex-nowrap">
                        <div className="custom-control-label1 w-100">
                          <h5 className="text-18">
                            <label
                              className="text-16 font-regular d-block my-1"
                              htmlFor="date-of-birth"
                            >
                              {'Drivers age is above 22 years?'}
                            </label>
                          </h5>
                          <span className="text-16 font-bold opacity-50 d-block my-1">
                            <label
                              className="text-16 font-regular d-block my-1"
                              htmlFor="date-of-birth"
                            >
                              {'I here by confirm that the driver age is above 22 years'}
                            </label>
                          </span>
                        </div>
                        <div className="input-quantity ml-auto">
                          <Switch
                            className={
                              this.state.selectedAgeGreaterThan22 == true
                                ? 'custom-switch-wrap custom-switch-active-wrap'
                                : 'custom-switch-wrap'
                            }
                            value={
                              this.state.selectedAgeGreaterThan22
                                ? this.state.selectedAgeGreaterThan22
                                : ''
                            }
                            on={this.state.selectedAgeGreaterThan22}
                            onChange={(e) =>
                              this.getSelectedAgeGreaterThan22(
                                e,
                                this.state.selectedAgeGreaterThan22,
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-8 col-sm-8 col-12 mt-4">
                    <div className="custom-input-type-wrap custom-input-select-wrap">
                      <label htmlFor="airline-name">{t('Airline Details.1')}</label>
                      <input
                        type="text"
                        placeholder="Enter airline details"
                        id="airlinename"
                        name="airlinename"
                        onChange={this.onChange}
                        value={this.state.airlinename}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="box-shadow-white-box-section">
            <div className="container-fluid">
              <div className="box-shadow-grey-box">
                <div className="row align-items-center">
                  <div className="col-md-6 col-12">
                    <div className="media align-items-center save-time-info">
                      <img
                        src={savetimeatcounter}
                        className="mr-md-4 mr-2"
                        alt="Dollar Save Time"
                        width="41"
                        height="41"
                      />
                      <div className="media-body">
                        <h5 className="mt-0 mb-1 text-18 font-bold">
                          <span className="xbressLogo-wrap mr-4">
                            <img src={dollarExpress} alt="Dollar Express" />
                          </span>
                          {t('Save Time At The Counter.1')}
                        </h5>
                        <p className="text-15">
                          {t(
                            'Skip lengthy paper works & queues to get on the road faster with our one-time online verification system. Easily upload the requested documents and rest assured, the process is compliant with our privacy and security systems.1',
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 mt-md-0 mt-4">
                    <div className="featured-rent-price-radio mt-0 pt-0 mb-0">
                      <div className="featured-rent-radio">
                        <input
                          type="radio"
                          id="paynow"
                          value="notatcounter"
                          name="featured-rent-price-radio"
                          checked={this.state.isCheked}
                          onChange={this.onChangeValue}
                        />
                        <label htmlFor="paynow">
                          <span className="text-16">{t('Provide Documents Now.1')}</span>
                          <p className="mt-1">{t('Upload Drivers License & ID.1')}</p>
                        </label>

                        <input
                          type="radio"
                          id="payletter"
                          disabled={this.state.isDocumentUploadMandatory}
                          value="atcounter"
                          name="featured-rent-price-radio"
                          onChange={this.onChangeValue}
                        />
                        <label htmlFor="payletter" className="mt-4 mt-sm-0">
                          <span className="text-16">{t('Provide Documents Later.1')}</span>
                          <p className="mt-1">{t('Submit Drivers License & ID at Counter.1')}</p>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.showDocAttachement == 1 ? (
                <div className="box-shadow-white-box  mt-4">
                  <div className="box-title-icon-border d-flex align-items-center mb-4 mb-0 mt-0">
                    <span className="box-title-icon-rounded mt-0">
                      <img
                        src={documentattachments}
                        alt="Dollar Verification"
                        width="14"
                        height=""
                      />
                    </span>
                    <h4 className="text-16 text-red">{t('Upload Verification Documents.1')}</h4>
                  </div>
                  <div className="row justify-content-lg-start justify-content-center align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="custom-input-type-file-wrap">
                        <label>
                          {t('Drivers License.1')}
                          <input type="file" placeholder="Upload" onChange={this.onFileChange} />

                          <span>{t('Upload.1')}</span>
                          <br />
                          <label>
                            {this.state.selectedFile != null ? this.state.selectedFile.name : ''}
                          </label>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-4 mt-lg-0">
                      <div className="custom-input-type-file-wrap">
                        <label>
                          {t('Drivers Passport.1')}

                          <input
                            type="file"
                            placeholder="Upload"
                            onChange={this.onPassportFileChange}
                          />

                          <span>{t('Upload.1')}</span>
                          <br />
                          <label>
                            {this.state.passportSelectedFile != null
                              ? this.state.passportSelectedFile.name
                              : ''}
                          </label>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </section>

          <RentalPolicies rentalType={3}></RentalPolicies>
          <section className="box-shadow-white-box-section pb-4">
            <div className="container-fluid">
              <div className="box-shadow-white-box mt-0 mb-0">
                <div class="custom-control custom-checkbox mb-3">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="terminationPolicyCheck"
                    name="example1"
                    checked={this.state.isTerminationPolicyChecked}
                    onChange={this.onTerminationPolicyChange}
                  />
                  <label class="custom-control-label" for="terminationPolicyCheck">
                    {t(this.state.subscriptionTermsConditionsShortText)} &nbsp;
                    <a className="text-red" onClick={this.onTerminationPolicyClick}>
                      {t('Read More.1')}
                    </a>{' '}
                  </label>
                </div>
              </div>
            </div>
          </section>
          <section className="mt-2 mb-5">
            <div className="container-fluid">
              <div className="row align-items-center">
                {/* <div className="col-xl-3 col-md-4 col-12 mb-4 mb-md-0">
                                    <a href="" className="d-flex align-items-center text-16 justify-content-md-start justify-content-center" title="Cancellation Policy">
                                        <img src={assignment} alt="Assignment Icon" width="18" height="20" />
                                        <span className="opacity-50 text-black mx-2">{t('Cancellation Policy.1')}</span>
                                        <img src={assignment} alt="Assignment Icon" width="20" height="20" />
                                    </a>
                                </div> */}

                <div className="col-xl-12 col-md-12 text-xl-center text-md-right text-center">
                  {this.state.loading == false ? (
                    // This means its Pay Online only.
                    this.state.reservationForBooking.VehicleModel != null &&
                    this.state.reservationForBooking.VehicleModel.PaymentMode == 1 ? (
                      <>
                        {
                          <a
                            className="rounded-bttn rounded-bttn-transparent ml-sm-3 mt-3 mt-sm-0"
                            title="Book & Pay Online"
                            onClick={this.onBookPayNowClick}
                          >
                            {t('Book & Pay Online.1')}
                          </a>
                        }
                      </>
                    ) : // This means its Pay at Counter only.&& this.state.reservationForBooking.VehicleModel.PaymentMode == 0
                    this.state.reservationForBooking.VehicleModel != null &&
                      this.state.reservationForBooking.VehicleModel.PaymentMode == 0 ? (
                      <>
                        {
                          <a
                            className="rounded-bttn rounded-bttn-transparent ml-sm-3 mt-3 mt-sm-0"
                            title="Book & Pay Later"
                            onClick={this.onBookPayLaterClick}
                          >
                            {t('Book & Pay Later.1')}
                          </a>
                        }
                      </>
                    ) : // This means user selected Pay at Counter & both pay online and pay at counter is allowed from Admin
                    this.props.searchParam.PaymentStatus == 0 &&
                      this.state.calculationDetails.PayNowDiscAmount != null &&
                      this.state.calculationDetails.PayNowDiscAmount.toFixed(2) > 0 ? (
                      <>
                        {
                          <div>
                            <a
                              title="Pay Now"
                              className="rounded-bttn rounded-bttn-transparent"
                              onClick={this.onBookPayNowClick}
                            >
                              {t('Pay Now & Save.1')} {this.state.currencyCode}{' '}
                              {this.state.calculationDetails.PayNowDiscAmount.toFixed(2)} Instantly
                            </a>
                            <a
                              className="rounded-bttn ml-sm-3 mt-3 mt-sm-0"
                              title="Book & Pay Later"
                              onClick={this.onBookPayLaterClick}
                            >
                              {t('Book & Pay Later.1')}
                            </a>
                          </div>
                        }
                      </>
                    ) : this.props.searchParam.PaymentStatus == 1 ? (
                      <a
                        className="rounded-bttn rounded-bttn-transparent ml-sm-3 mt-3 mt-sm-0"
                        title="Book & Pay Online"
                        onClick={this.onBookPayNowClick}
                      >
                        {t('Book & Pay Online.1')}
                      </a>
                    ) : (
                      <a
                        className="rounded-bttn rounded-bttn-transparent ml-sm-3 mt-3 mt-sm-0"
                        title="Book & Pay Later"
                        onClick={this.onBookPayLaterClick}
                      >
                        {t('Book & Pay Later.1')}
                      </a>
                    )
                  ) : null}

                  {this.state.loading == true ? (
                    <div style={{ verticalAlign: 'center' }}>
                      <Spinner animation="border" />
                      &nbsp;{this.state.loadingText}
                    </div>
                  ) : null}
                </div>
                <div className="col-xl-3">
                  {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
                    <Dialog
                      open={this.state.errorMessage.length > 0}
                      onClose={this.handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">{'Dollar'}</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          {this.state.errorMessage}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                          {t('OK.1')}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  ) : null}
                </div>
              </div>
            </div>
          </section>
          {this.state.showInsuranceViewDetails ? (
            <div>
              <div className="modal-dialog modal-xl modal-dialog-centered ">
                <Modal
                  show={this.state.showInsuranceViewDetails}
                  onHide={() => this.toogleViewInsurancePopUp(false)}
                  dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap"
                >
                  <ViewInsuranceDetails
                    insuranceDesriptionToShow={this.state.insuranceDesriptionToShow}
                  />
                </Modal>
              </div>
            </div>
          ) : null}
          {this.state.showExtrasDetails ? (
            <div>
              <div className="modal-dialog modal-xl modal-dialog-centered">
                <Modal
                  show={this.state.showExtrasDetails}
                  onHide={() => this.toogleViewExtrasPopUp(false)}
                  dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap"
                >
                  <ViewExtrasDetails descriptionToShow={this.state.extrasDesriptionToShow} />
                </Modal>
              </div>
            </div>
          ) : null}
          {this.state.showExtrasDetails ? (
            <div>
              <div className="modal-dialog modal-xl modal-dialog-centered">
                <Modal
                  show={this.state.showExtrasDetails}
                  onHide={() => this.toogleViewIncludeLongTextPopUp(false)}
                  dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap"
                >
                  <ViewIncludeLongText LongTextShow={this.state.LongTextShow} IsTextWithHtml={0} />
                </Modal>
              </div>
            </div>
          ) : null}

          {this.state.allowToSubmit == false ? (
            <div>
              <div className="modal-dialog modal-lg modal-dialog-centered full-coverage-modal-wrap">
                <Modal
                  show={this.state.showSCDWDetails}
                  onHide={() => this.toogleViewSCDWHidePopUp(false)}
                  dialogClassName="modal-dialog modal-lg modal-dialog-centered view-details-wrap"
                >
                  <div className="modal-header ">
                    <div className="row justify-content-between w-100 align-items-center">
                      <div className="col-md-auto col-12">
                        <h4 className="text-red text-center text-md-left">
                          <span className="box-title-icon-rounded mt-0">
                            <img src={protection} alt="" width="13" height="16" />
                          </span>
                          {t('Choose Full Coverage and relax.1')}
                        </h4>
                      </div>
                      <div className="col-md-auto col-12 mt-3 mt-md-0">
                        {this.state.getSCDWInsuranceData != null ? (
                          <p>
                            <b>
                              {t('Just.1')} {this.state.getSCDWInsuranceData.PerDayPriceFI}{' '}
                              {t('AED.1')}
                            </b>{' '}
                            {t('per month.1')}
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                      {/* <div className='col-12 text-right'>
                                    <p><span><b>Adds 250 OMR</b> for your rental</span></p>
                                </div> */}
                    </div>
                  </div>
                  <div className="modal-body">
                    {this.state.getSCDWInsuranceData != null ? (
                      <p>{this.state.getSCDWInsuranceData.FullInsuranceNotOptedText}</p>
                    ) : (
                      ''
                    )}
                    <div className="table-responsive mt-4">
                      <table className="table">
                        <thead class="bg-grey">
                          <tr>
                            <th className="no-wrap">
                              <b>{t('What is covered.1')}</b>
                            </th>
                            <th className="text-center">
                              <b>{t('No protection.1')}</b>
                            </th>
                            <th className="text-center">
                              <b>{t('Full protection.1')}</b>
                            </th>
                          </tr>
                        </thead>
                        {this.state.getSCDWInsuranceData != null &&
                        this.state.getSCDWInsuranceData.FullInsuranceIncluded != null
                          ? this.state.getSCDWInsuranceData.FullInsuranceIncluded.map(
                              (model, index) => {
                                return (
                                  <tr>
                                    <th>{model}</th>
                                    <td className="text-center">
                                      <span className="text-red">╳</span>
                                    </td>
                                    <td className="text-center">
                                      <span className="text-success">✓</span>
                                    </td>
                                  </tr>
                                );
                              },
                            )
                          : ''}
                      </table>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <a onClick={this.onWithOutSCDWClick} title="" className="rounded-bttn ">
                      {t('Book without protection.1')}
                    </a>
                    <a
                      onClick={this.onWithSCDWClick}
                      title=""
                      className="rounded-bttn ml-sm-2 rounded-bttn-transparent"
                    >
                      {t('Book with full protection.1')}
                    </a>
                  </div>
                </Modal>
              </div>
            </div>
          ) : null}

          {this.state.showSubscriptionTermsDetails ? (
            <div>
              <div className="modal-dialog modal-xl modal-dialog-centered">
                <Modal
                  show={this.state.showSubscriptionTermsDetails}
                  onHide={() => this.subscriptionTermsAndSubscription(false)}
                  dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap"
                >
                  <ViewIncludeLongText
                    LongTextShow={this.state.subscriptionTermsConditionsLongText}
                    IsTextWithHtml={1}
                  />
                </Modal>
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(MonthlyListingPage3));
