import './assets/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'font-awesome/css/font-awesome.min.css';
import './assets/css/custom.css';
import './assets/css/mid.css';
import './assets/css/new-style.css';
import './assets/css/responsive.css';
import './assets/css/slick.css';
import './assets/css/styles.css';
import 'jquery/dist/jquery.min.js';
import 'react-datepicker/dist/react-datepicker.css';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import ChangePassword from './Account/ChangePassword';
import Login from './Account/Login';
import Profile from './Account/Profile';
import Register from './Account/Register';
import ContactUs from './Contact/ContactUs';
import Hire from './Hire/Hire';
import StartaReservation from './Hire/StartaReservation';
import ListingPage from './ListingPage/ListingPage';
import ReservationConfirm from './ListingPage/ReservationConfirm';
import ReservationModify from './ListingPage/ReservationModify';
import LocationPage from './Location/LocationPage';
import AllNews from './News/AllNews';
import News from './News/News';
import BookService from './Other/BookService';
import Disclaimer from './Other/Disclaimer';
import FAQ from './Other/FAQ';
import FindReservation from './Other/FindReservation';
import JobPosting from './Other/JobPosting';
import OurPartner from './Other/OurPartner';
import PrivacyPolicy from './Other/PrivacyPolicy';
import SalesTeam from './Other/SalesTeam';
import SecurityTips from './Other/SecurityTips';
import ServicePromise from './Other/ServicePromise';
import TerminationPolicy from './Other/TerminationPolicy';
import Terms from './Other/Terms';
import VehicleTypes from './Other/VehicleTypes';
import BookingDetails from './SpecialOffer/BookingDetails';
import SpecialOffers from './SpecialOffer/SpecialOffers';
import ThankYou from './ThankYou/ThankYou';
import Helmet from 'react-helmet';
import PaymentProcessing from './ListingPage/PaymentProcessing';
import MonthlyListingPage from './MonthlySubscription/MonthlyListingPage';
import MonthlySubscription from './MonthlySubscription/MonthlySubscription';
import BusinessSolutions from './Other/BusinessSolutions';
import CorporateLeasing from './Other/CorporateLeasing';
import Covid19 from './Other/Covid19';
import DollarPrestige from './Other/DollarPrestige';
import DollarXpress from './Other/DollarXpress';
import ExploreCorporateFleet from './Other/ExploreCorporateFleet';
import ExploreFleet from './Other/ExploreFleet';
import FeedBack from './Other/FeedBack';
import FleetManagementSolutions from './Other/FleetManagementSolutions';
import KnowUs from './Other/KnowUs';
import LongTermLeasing from './Other/LongTermLeasing';
import MonthlyRentals from './Other/MonthlyRentals';
import NotFound from './Other/NotFound';
import PersonalLeasing from './Other/PersonalLeasing';
import RentalAddons from './Other/RentalAddons';
import RentalGuide from './Other/RentalGuide';
import RequestReceipt from './Other/RequestReceipt';
import SiteMap from './Other/SiteMap';
import SocialMediaBox from './Other/SocialMediaBox';
import SpecializedVehicles from './Other/SpecializedVehicles';
import TruckRental from './Other/TruckRental';
import LeaseToOwn from './Other/Lease/LeaseToOwn';
import TeacherPage from './Other/TeacherPage/TeacherPage'

function App() {
  const articleStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Dollar Car Rental',
    url: 'https://www.dollaruae.com',
    logo: 'https://www.dollaruae.com/static/media/dollar.59daae54.svg',
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+971 43365065',
      contactType: '',
    },
    sameAs: [
      'https://www.facebook.com/dollar.uae/',
      'https://www.instagram.com/dollar.uae/',
      'https://www.linkedin.com/company/dollar-rent-a-car-uae/',
    ],
  };
  return (
    <div>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(articleStructuredData)}</script>
        <meta charSet="utf-8" />
        <title>Dollar Car Rental UAE | Self-Drive Cars at Affordable Prices</title>
        <link rel="canonical" href="https://dollaruae.com/" />
      </Helmet>
      <Router>
        <Switch>
          <Route path="/login" exact>
            <Login showHeader={false} />
          </Route>
          <Route path="/offers" exact>
            <SpecialOffers />
          </Route>
          {/* <Route path="/offers/:index" exact>
            <SpecialOffers />
          </Route> */}
          <Route path="/" exact>
            <Hire />
          </Route>
          <Route path="/contactus" exact>
            <ContactUs />
          </Route>
          <Route path="/thankyou" component={ThankYou} />
          <Route path="/booking" exact>
            <ListingPage />
          </Route>
          <Route path="/vehicle-listing" exact>
            <ListingPage />
          </Route>
          <Route path="/salesteam" exact>
            <SalesTeam />
          </Route>
          <Route path="/termsandconditions" exact>
            <Terms />
          </Route>
          <Route path="/termination-policy" exact>
            <TerminationPolicy />
          </Route>
          <Route path="/paymentprocess">
            <PaymentProcessing />
          </Route>
          <Route path="/ourpartner/:index" component={OurPartner} />

          {/* <Route path="/businesssolutions/" component={BusinessSolutions}>
          </Route> */}

          <Route path="/reservationconfirm/:index/:index2" component={ReservationConfirm} />
          <Route path="/reservationmodify/:index/:index2" component={ReservationModify} />
          <Route path="/register" exact>
            <Register />
          </Route>
          <Route path="//changepassword/:id" exact>
            <ChangePassword />
          </Route>
          <Route path="/offers/:index" component={BookingDetails}></Route>
          <Route path="/locations" exact>
            <LocationPage />
          </Route>
          {/* <Route path="/locations/:index" exact>
            <LocationPage />
          </Route> */}
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/news/:index" component={News}></Route>
          <Route path="/bookservice">
            <BookService />
          </Route>
          <Route path="/book-service">
            <BookService />
          </Route>
          <Route path="/findreservation">
            <FindReservation />
          </Route>

          <Route path="/news">
            <AllNews />
          </Route>

          <Route path="/servicepromise">
            <ServicePromise />
          </Route>

          <Route path="/securitytips">
            <SecurityTips />
          </Route>

          <Route path="/disclaimer">
            <Disclaimer />
          </Route>

          <Route path="/privacypolicy">
            <PrivacyPolicy />
          </Route>

          <Route path="/faq">
            <FAQ />
          </Route>

          <Route path="/jobposting">
            <JobPosting />
          </Route>

          <Route path="/vehicletypes" component={VehicleTypes}></Route>
          <Route path="/vehicle-types" component={VehicleTypes}></Route>

          <Route path="/vehicletypes/:index" component={VehicleTypes}></Route>

          <Route path="/feedback/:index" component={FeedBack}></Route>

          <Route path="/feedback" component={FeedBack}></Route>

          <Route path="/rentalguide">
            <RentalGuide />
          </Route>
          <Route path="/rental-guide">
            <RentalGuide />
          </Route>
          <Route path="/personalleasing">
            <PersonalLeasing />
          </Route>
          <Route path="/personal-leasing">
            <PersonalLeasing />
          </Route>

          <Route path="/corporateleasing">
            <CorporateLeasing />
          </Route>
          <Route path="/corporate-leasing">
            <CorporateLeasing />
          </Route>

          <Route path="/covid19">
            <Covid19 />
          </Route>

          <Route path="/DollarXpress">
            <DollarXpress />
          </Route>
          <Route path="/dollar-xpress">
            <DollarXpress />
          </Route>
          <Route
            path="/DollarPrestige"
            exact
            render={() => {
              return <Redirect to="/dollar-prestige" />;
            }}
          ></Route>
          <Route path="/dollar-prestige">
            <DollarPrestige />
          </Route>
          <Route path="/dollar-prestige-vehicles">
            <DollarPrestige />
          </Route>

          <Route path="/ExploreFleet">
            <ExploreFleet />
          </Route>
          <Route path="/explore-fleet">
            <ExploreFleet />
          </Route>
          <Route
            path="/MonthlyRentals"
            exact
            render={() => {
              return <Redirect to="/monthly-rentals" />;
            }}
          ></Route>
          <Route path="/monthly-rentals">
            <MonthlyRentals />
          </Route>
          <Route path="/RequestReceipt">
            <RequestReceipt />
          </Route>

          <Route path="/RentalAddons">
            <RentalAddons />
          </Route>
          <Route path="/rental-addons">
            <RentalAddons />
          </Route>
          <Route path="/car-rental-addons">
            <RentalAddons />
          </Route>

          <Route path="/startaReservation">
            <StartaReservation />
          </Route>
          <Route path="/start-a-reservation">
            <StartaReservation />
          </Route>

          <Route path="/FleetManagementSolutions">
            <FleetManagementSolutions />
          </Route>
          <Route path="/fleet-management-solutions">
            <FleetManagementSolutions />
          </Route>

          <Route path="/SpecializedVehicles">
            <SpecializedVehicles />
          </Route>
          <Route path="/specialized-vehicles">
            <SpecializedVehicles />
          </Route>
          <Route path="/ExploreCorporateFleet">
            <ExploreCorporateFleet />
          </Route>
          <Route path="/explore-corporate-fleet">
            <ExploreCorporateFleet />
          </Route>
          <Route
            path="/BusinessSolutions"
            exact
            render={() => {
              return <Redirect to="/business-solutions" />;
            }}
          ></Route>
          <Route path="/business-solutions">
            <BusinessSolutions />
          </Route>
          <Route
            path="/business"
            exact
            render={() => {
              return <Redirect to="/business-solutions" />;
            }}
          ></Route>
          <Route
            path="/LongTermLeasing"
            exact
            render={() => {
              return <Redirect to="/long-term-leasing" />;
            }}
          ></Route>
          <Route path="/long-term-leasing">
            <LongTermLeasing />
          </Route>
          <Route
            path="/longterm-leasing"
            exact
            render={() => {
              return <Redirect to="/long-term-leasing" />;
            }}
          ></Route>

          <Route path="/knowus" exact>
            <KnowUs />
          </Route>

          <Route path="/KnowUs/:path" exact>
            <KnowUs />
          </Route>

          <Route path="/TruckRental">
            <TruckRental />
          </Route>

          <Route path="/SiteMap">
            <SiteMap />
          </Route>

          <Route path="/SocialMediaBox">
            <SocialMediaBox />
          </Route>
          <Route path="/FlexiMonthly">
            <MonthlySubscription />
          </Route>
          <Route path="/monthlybooking" exact>
            <MonthlyListingPage />
          </Route>
          <Route path="/rent-to-own">
            <LeaseToOwn />
          </Route>
          <Route path="/teachers-offer">
            <TeacherPage />
          </Route>
          <Route path="/dxb-raffle">
            <TeacherPage />
          </Route>
          <Route path="/auh-raffle">
            <TeacherPage />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
