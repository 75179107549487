import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
class MonthlyViewRentalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RentalDetails: [],
    };
  }
  componentDidMount() {
    var axios = require('axios');

    var config = {
      method: 'get',
      url:
        process.env.REACT_APP_API +
        `BookingSetting/GetRentalAdditionalSetting?apiKey=` +
        process.env.REACT_APP_API_KEY +
        `&parameter={"VehicleTypeID":"00000000-0000-0000-0000-000000000000","ClassificationList":null,"VehicleMakeID":"00000000-0000-0000-0000-000000000000","VehicleModelID":"00000000-0000-0000-0000-000000000000","SpecialOfferID":"00000000-0000-0000-0000-000000000000","CustomerID":"00000000-0000-0000-0000-000000000000","ModelYear":0,"Passengers":0,"IsAutoTransmission":false,"CheckOutOn":"2021-04-19T15:51:00","CheckInOn":"2021-04-23T15:51:00","CheckOutBranchID":"e5796265-81aa-4365-916f-bcb4071d1c9d","CheckInBranchID":"e5796265-81aa-4365-916f-bcb4071d1c9d","IsCDWSelected":false,"IsSCDWSelected":false,"IsPAISelected":false,"AncillaryIn":[],"PromoCodeID":"00000000-0000-0000-0000-000000000000","PromoCode":null,"DriverAge":21,"IsPayAtLocation":false,"MinRentalRate":0.0,"MaxRentalRate":50000.0,"RentalDays":0,"ModelWiseChargeID":"00000000-0000-0000-0000-000000000000","VehicleVariantID":"00000000-0000-0000-0000-000000000000","NoOfRecords":0,"TariffGroupID":"00000000-0000-0000-0000-000000000000"}`,
      headers: {},
    };

    axios(config)
      .then((response) => {
        this.setState({
          RentalDetails: response.data,
        });
      })
      .catch((error) => {});
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        {
          <Modal.Header closeButton>
            <h4>{t('View Rental Details.1')}</h4>
          </Modal.Header>
        }
        <Modal.Body></Modal.Body>
      </div>
    );
  }
}

export default withTranslation()(MonthlyViewRentalDetails);
