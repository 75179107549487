import axios from 'axios';

const createAxiosInstance = (apiKey, clientKey, baseURL) => {
  return axios.create({
    baseURL: baseURL,
    headers: {
      accept: 'application/json',
      apikey: apiKey,
      clientkey: clientKey,
    },
  });
};

export const branchInstance = createAxiosInstance(
  process.env.REACT_APP_BRANCH_API_KEY,
  process.env.REACT_APP_BRANCH_CLIENT_KEY,
  process.env.REACT_APP_BRANCH_API_URL,
);
