import React, { Component, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { getLanguage } from '../../Common/Util';
import buildingImg from '../../assets/images/building.svg';
import closeXImg from '../../assets/images/closeX.svg';
import mapPinImg from '../../assets/images/map-pin.svg';
import mappingBuilding from '../../assets/images/mappingBuildingImg.svg';
import mappingPlane from '../../assets/images/mappingImg.svg';
import plainImg from '../../assets/images/plain.svg';
import { branchInstance } from '../../axios/axiosInstance';
import './tabsStyle.css';

class PickUpPopup extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      selectedValue: {
        Name: '',
        Address: '',
        GoogleLocationURL: '',
        timing: [],
      },
      allBranches: [],
      branchTypes: [],
      baseBranches: [],
      searchQuery: this.props.searchQuery || '',
      searchQueryMobile: this.props.searchInputBoxValue || '',
      size: window.innerWidth,
      isActive: false,
      isComponentMounted: false,
      popupScroll: 0,
      isFirefox: false,
      expand: false,
    };
    this.popUpRef = createRef();
  }

  containsLocation = (locations, id) => {
    var i = -0;
    for (i = 0; i < locations.length; i++) {
      if (locations[i]?.locationId?.toString() === id?.toString()) return true;
    }
    return false;
  };

  getBranches = async () => {
    let lngParam = getLanguage();

    const code = process.env.REACT_APP_COUNTRY_CODE;
    branchInstance.get(`all?countryCode=${code}`).then((response) => {
      if (this.state.isComponentMounted) {
        const allBranches = response.data.result;

        if (allBranches.length > 0) {
          const locations = [];
          allBranches.forEach((branch) => {
            if (!this.containsLocation(locations, branch.BranchType.BranchTypeID)) {
              locations.push({
                locationId: branch.BranchType.BranchTypeID,
                name: branch.BranchType.Name,
                imageString: branch.BranchType.Attachment.ImageString,
              });
            }
          });
          this.setState({ branchTypes: locations });
          this.setState({ baseBranches: allBranches });
        }
        this.setState(
          {
            allBranches: allBranches,
          },
          // () => {
          //   this.prioritizeData();
          // }
        );

        const { Name, Address, GoogleLocationURL, Message = ' ' } = allBranches[0];
        this.handleMouseEnter(Name, Address, GoogleLocationURL, Message);
      }
    });
  };

  // prioritizeData() {
  //   const priorityLevels = {
  //     "airport locations": 1,
  //     "1. airport locations": 1,
  //     "free delivery areas": 2,
  //     "2. free delivery areas": 2,
  //     "dubai branches": 3,
  //     "3. dubai branches": 3,
  //     "abu dhabi": 4,
  //     "4. abu dhabi": 4,
  //     "sharjah branches": 5,
  //     "5. sharjah branches": 5,
  //     "al ain": 6,
  //     "6. al ain": 6,
  //   };

  //   // Convert branch type to lowercase
  //   const getPriority = (branch) => {
  //     const branchType = branch.BranchType.Name.toLowerCase();
  //     return (
  //       priorityLevels[branchType] ||
  //       priorityLevels[branchType.replace(/^\d+\.\s*/, "")]
  //     );
  //   };

  //   // Sort data based on priority levels
  //   const sortedData = this.state.allBranches.slice().sort((a, b) => {
  //     const priorityA = getPriority(a);
  //     const priorityB = getPriority(b);
  //     return priorityA - priorityB;
  //   });

  //   this.setState({ allBranches: sortedData });
  // }

  componentDidMount() {
    this.setState({ isComponentMounted: true });
    this.inputRef.current.focus();
    this.setState({ isActive: true });
    this.getBranches();
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('Firefox') > -1) {
      this.setState({ isFirefox: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.searchQuery !== prevProps.searchQuery) {
      this.setState({ searchQuery: this.props.searchQuery });
    }

    if (this.props.popUpScrollPosition !== prevProps.popUpScrollPosition) {
      this.setState({ popupScroll: this.props.popUpScrollPosition });
    }

    if (this.state.popupScroll !== prevState.popupScroll) {
      if (this.popUpRef.current) {
        this.popUpRef.current.scrollTop = this.state.popupScroll;
      }
    }
  }

  componentWillUnmount() {
    this.setState({ isComponentMounted: false });
  }

  handleScroll = (event) => {
    event.stopPropagation();
  };

  handleSelectedValue = (Name) => {
    this.props.onSelectValue(Name);
  };

  handleExpland = () => {
    this.setState((prevState) => ({
      expand: !prevState.expand,
    }));
  };

  handleMouseEnter = (Name, Address, GoogleLocationURL, Message, timing) => {
    this.setState({
      selectedValue: {
        Name,
        Address,
        GoogleLocationURL,
        Message,
        timing,
      },
    });
  };

  render() {
    const { t } = this.props;
    const popupStyle = this.state.isFirefox
      ? { scrollbarWidth: '8px', scrollbarColor: '#E31A37 #ffffff' }
      : {};
    return (
      <>
        <div
          className="pickupReturnLocationPopup"
          style={{ height: this.props.handleHeight === 10 ? 0 : '' }}
        >
          <div className="mob-header mob">
            <div className="back">
              <h6>{t('Select Pickup Location.1')}</h6>
            </div>
            <div className="section-input-section">
              <div
                className={`input ${this.state.isActive && 'active'}`}
                onClick={() => this.setState({ isActive: true })}
              >
                <div className="icon" style={{ marginBottom: 0 }}>
                  <img src={mapPinImg} alt="" />
                </div>
                <input
                  type="text"
                  value={this.state.searchQueryMobile}
                  placeholder="Airport, City or Address"
                  ref={this.inputRef}
                  id="pickupAndReturnLocation"
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({
                      searchQueryMobile: e.target.value,
                      searchQuery: e.target.value,
                    });
                  }}
                />
                <div className="icon" style={{ marginBottom: 0 }}>
                  {this.state.searchQueryMobile && (
                    <img
                      src={closeXImg}
                      alt="X"
                      onClick={() => {
                        this.setState({ searchQueryMobile: '', searchQuery: '' }, () => {
                          this.props.clearSearchProps();
                        });
                      }}
                    />
                  )}
                </div>
                <div className="close">
                  <img src={closeXImg} alt="" onClick={() => this.props.handlePopUpAirport(0)} />
                </div>
              </div>
            </div>
          </div>
          <div className="deliver-to-me-mob mob">
            {this.props.popUpAirport === 2 ? (
              <h6>{t('Want collect from you?.1')}</h6>
            ) : (
              <h6>{t('Want delivery to you?.1')}</h6>
            )}
            <button onClick={() => this.props.handleChooseDelivery(true, 0)}>
              {t('Deliver to me.1')}
            </button>
          </div>
          <div className="inner-wrapper">
            <div className="left-section">
              <ul className="selectedLocations" ref={this.popUpRef} style={popupStyle}>
                {this.state.branchTypes.map((branchtype) => (
                  <div key={branchtype.locationId}>
                    <div
                      className="branchTypeOuterWrapper"
                      style={
                        branchtype.name.toLowerCase().includes('airport') ? { border: '0px' } : null
                      }
                    >
                      <img
                        src={
                          branchtype.name.toLowerCase().includes('airport')
                            ? mappingPlane
                            : mappingBuilding
                        }
                        alt=""
                      />
                      <p>{branchtype.name}</p>
                    </div>

                    {this.state.allBranches
                      .filter((item) =>
                        item.Name.toLowerCase().includes(this.state.searchQuery.toLowerCase()),
                      )
                      .map((item) =>
                        item.BranchType.BranchTypeID === branchtype.locationId ? (
                          <li
                            key={item.Identity.Id}
                            onMouseEnter={() =>
                              this.handleMouseEnter(
                                item.Name,
                                item.Address,
                                item.GoogleLocationURL,
                                item.Message,
                                item?.BranchOfficeTiming?.BranchTimings,
                              )
                            }
                            onClick={() => this.handleSelectedValue(item)}
                          >
                            <h6 className="drop-val">{item.Name}</h6>
                          </li>
                        ) : null,
                      )}
                  </div>
                ))}
              </ul>
              <div className="bottom-section">
                {this.props.popUpAirport === 2 ? (
                  <h6>
                    {t('Want collect from you?.1')}{' '}
                    <a onClick={() => this.props.handleChooseDelivery(true, 0)}>
                      {t('Choose collect from me.1')}
                    </a>
                  </h6>
                ) : (
                  <h6>
                    {t('Want delivery to you?.1')}{' '}
                    <a onClick={() => this.props.handleChooseDelivery(true, 0)}>
                      {t('Choose deliver to me.1')}
                    </a>
                  </h6>
                )}
              </div>
            </div>
            {this.state.size > 563 && (
              <div className="right-section-c">
                <div className="location-address">
                  <div className="icon">
                    <img
                      src={
                        this.state.selectedValue.Name.includes('Airport') ? plainImg : buildingImg
                      }
                      alt=""
                    />
                  </div>
                  <h6 id="updatePickedLocation">
                    <span id="selectedPickupLocation">{this.state.selectedValue.Name}</span>{' '}
                  </h6>

                  <span>{this.state.selectedValue.Address}</span>
                  <span>
                    {this.state.selectedValue.timing?.map((time, index) => (
                      <span key={index} className="timeMapping">
                        {time.DayString} :{' '}
                        {time.Shifts?.map((item, index) => (
                          <span key={index} className="timeMapping">
                            {item}
                          </span>
                        ))}
                      </span>
                    ))}
                  </span>

                  <div className="messageArea">
                    <p className="messageText">
                      {this.state.expand && this.state.selectedValue.Message.length !== 0
                        ? this.state?.selectedValue?.Message
                        : `${
                            this.state.selectedValue.Message
                              ? this.state?.selectedValue?.Message?.slice(0, 75)
                              : ' '
                          }`}
                      {this.state.selectedValue?.Message?.length > 75 && (
                        <span className="showMoreMessage" onClick={this.handleExpland}>
                          {this.state.expand ? ' Show less' : ' Show more'}
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="iframe-wrapper">
                  <iframe
                    title="map"
                    src={this.state.selectedValue.GoogleLocationURL}
                    width="100%"
                    height="600"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(PickUpPopup);
