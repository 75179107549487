import React, { Component } from 'react';
import roadsideassistance from '../assets/images/24x7 Roadside Assistance 4.jpg';
import allinclusive from '../assets/images/All-Inclusive Experience 1.jpg';
import latestcar from '../assets/images/Latest Cars 5.jpg';
import flexibleplan from '../assets/images/Flexible Plan 2.jpg';
import nopaperwork from '../assets/images/No Paperwork 3.jpg';
import complimentarysecondary from '../assets/images/Complimentary Secondary Driver 6.jpg';
import { withTranslation } from 'react-i18next';

class MonthlyFeatures extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <section className="card-blog-left-section padding-100">
          <div className="container">
            <div className="titleBorderWrap">
              <h2>{t('Why Monthly Subscription is a Great Choice for You.1')}</h2>
              <p>{t('Explore Amazing Benefits.1')}</p>
            </div>
            <div className="card-blog-left-row">
              <div className="card-blog-left-col">
                <div className="card-blog-left-box">
                  <div className="card-blog-left-img">
                    <img src={allinclusive} alt="All-Inclusive Experience" />
                  </div>
                  <div className="card-blog-left-content">
                    <h3>{t('All-Inclusive Experience.1')}</h3>
                    <p>
                      {t(
                        'Free delivery pick-up service with routine maintenance and a one-time free car swap.1',
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-blog-left-col">
                <div className="card-blog-left-box">
                  <div className="card-blog-left-img">
                    <img src={flexibleplan} alt="Flexible Plan" />
                  </div>
                  <div className="card-blog-left-content">
                    <h3>{t('Flexible Plan.1')}</h3>
                    <p>
                      {t('Ride without any commitment and save more with value-added benefits.1')}{' '}
                      <br></br>
                      <br></br>
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-blog-left-col">
                <div className="card-blog-left-box">
                  <div className="card-blog-left-img">
                    <img src={nopaperwork} alt="No Paperwork" />
                  </div>
                  <div className="card-blog-left-content">
                    <h3>{t('No Paperwork.1')}</h3>
                    <p>
                      {t('An all digital booking process for a smooth and seamless experience.1')}
                    </p>
                    <br></br>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-blog-left-row1">
              <div className="card-blog-left-col">
                <div className="card-blog-left-box">
                  <div className="card-blog-left-img">
                    <img src={roadsideassistance} alt="24x7 Roadside Assistance" />
                  </div>
                  <div className="card-blog-left-content">
                    <h3>{t('24x7 Roadside Assistance.1')}</h3>
                    <p>{t('Let us know whenever you need us and we ll be there for you.1')}</p>
                    <br></br>
                  </div>
                </div>
              </div>
              <div className="card-blog-left-col">
                <div className="card-blog-left-box">
                  <div className="card-blog-left-img">
                    <img src={latestcar} alt="Latest Cars" />
                  </div>
                  <div className="card-blog-left-content">
                    <h3>{t('Latest Cars.1')}</h3>
                    <p>
                      {t(
                        'Choose from our range of cars and make a statement every time you drive.1',
                      )}
                    </p>
                    <br></br>
                  </div>
                </div>
              </div>
              <div className="card-blog-left-col">
                <div className="card-blog-left-box">
                  <div className="card-blog-left-img">
                    <img src={complimentarysecondary} alt="Card Image 3" />
                  </div>
                  <div className="card-blog-left-content">
                    <h3>{t('Complimentary Secondary Driver.1')}</h3>
                    <p>
                      {t('Add a family member or friend as an additional driver for your car.1')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withTranslation()(MonthlyFeatures);
