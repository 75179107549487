import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import 'react-google-flight-datepicker/dist/main.css';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import validator from 'validator';
import { getLanguage } from '../Common/Util';
var qs = require('qs');

class QuoteRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      email: '',
      Contactno: '',
      companyname: '',
      remark: '',
      loading: false,
      errorMessage: '',
      deliveryState: 'SELECT',
      allStates: [],
    };
  }

  componentDidMount() {
    this.getStates();
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validateQuoteRequest = () => {
    let lngParam = getLanguage();
    this.setState({
      errorMessage: '',
    });
    let valid = true;

    if (this.state.fullName === undefined || this.state.fullName === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please enter the customer name' });
      } else {
        //ar
        this.setState({ errorMessage: 'الرجاء إدخال اسم العميل' });
      }

      return false;
    }
    if (this.state.email === undefined || this.state.email === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please enter the email' });
      } else {
        //ar
        this.setState({ errorMessage: 'يرجى إدخال عنوان البريد الإلكتروني' });
      }

      return false;
    }
    if (!validator.isEmail(this.state.email)) {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The email field is invalid',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل البريد الإلكتروني',
        });
      }
      return false;
    }
    if (this.state.Contactno === undefined || this.state.Contactno === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please enter the contact no' });
      } else {
        //ar
        this.setState({ errorMessage: 'الرجاء إدخال أي اتصال' });
      }

      return false;
    }

    if (
      this.state.deliveryState === undefined ||
      this.state.deliveryState === '' ||
      this.state.deliveryState === 'SELECT' ||
      this.state.deliveryState === 'select'
    ) {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please select the state.' });
      } else {
        //ar
        this.setState({ errorMessage: 'الرجاء تحديد الولاية.' });
      }

      return false;
    }

    // if (this.state.companyname === undefined || this.state.companyname === '') {
    //     if (lngParam == "en") {
    //         this.setState({ errorMessage: 'Please enter the company name' });
    //     }
    //     else {
    //         //ar
    //         this.setState({ errorMessage: 'الرجاء إدخال اسم الشركة' });
    //     }

    //     return false;
    // }

    if (this.state.remark === undefined || this.state.remark === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please enter the remark' });
      } else {
        //ar
        this.setState({ errorMessage: 'الرجاء إدخال بهذا التصريح' });
      }

      return false;
    }

    return valid;
  };

  onSendQuoteRequestClick = () => {
    if (this.validateQuoteRequest()) {
      var data = qs.stringify({
        CompanyName: this.state.companyname,
        PersonalInformation: {
          Title: null,
          FullName: this.state.fullName,
          FirstName: null,
          LastName: null,
          ContactNo: this.state.Contactno,
          DOB: null,
          Email: this.state.email,
          Address: null,
        },
        Description: this.state.remark,
        SpecialOfferID: this.props.SpecialOfferID,
        StateID: this.state.deliveryState,
      });

      var config = {
        method: 'post',
        url:
          process.env.REACT_APP_API + `QuoteRequest/Post?apiKey=${process.env.REACT_APP_API_KEY}`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: data,
      };
      this.setState({ loading: true });
      axios(config)
        .then((response) => {
          this.setState({ loading: false });

          if (response.data != null && response.data.Message != null) {
            localStorage.setItem('thanksmessage', response.data.Message);
          }

          this.props.history.push(`/thankyou`);
        })
        .catch((error) => {
          this.setState({ errorMessage: 'Error while sending quote request' });
        });
    } else {
    }
  };

  handleClose = () => {
    this.setState({
      errorMessage: '',
    });
  };

  getStates = () => {
    axios(
      process.env.REACT_APP_API +
        'BookingSetting/GetStates?apiKey=' +
        process.env.REACT_APP_API_KEY,
    ).then((response) => {
      const allStates = response.data;
      if (allStates.length > 0) {
        this.setState({
          allStates: allStates,
        });
      }
    });
  };

  LocationStateChange = (event) => {
    this.setState({ deliveryState: event.target.value });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="col-lg-5 bg_custom" id="book">
        <div className="px-3 ">
          <h5 className="font-weight-bold pt-3">{t('Quote Request.1')}</h5>
          <br></br>
          <div className="custom-input-type-wrap mb-3">
            <label class="mb-2">
              <h6 className="font-weight-bold mb-0">
                {t('Customer Name.1')}&nbsp;<span className="text-red-alert">*</span>
              </h6>
            </label>

            <input
              className="form-control"
              id="fullName"
              name="fullName"
              type="text"
              onChange={this.onChange}
              value={this.state.fullName}
            />
          </div>
          <div className="custom-input-type-wrap mb-3">
            <div className="custom-input-type-wrap">
              <label class="mb-2">
                <h6 className="font-weight-bold mb-0">
                  {t('Email Id.1')}&nbsp;<span className="text-red-alert">*</span>{' '}
                </h6>
              </label>
              <input
                className="form-control"
                id="email"
                name="email"
                type="text"
                onChange={this.onChange}
                value={this.state.email}
              />
            </div>
          </div>
        </div>
        {/* className="px-3" */}
        <div className="mt-0">
          <div className="row  mb-3">
            <div className="col col-lg-6">
              <div className="custom-input-type-wrap">
                <label class="mb-2">
                  <h6 className="font-weight-bold mb-0">
                    {t('Contact No.1')}&nbsp;<span className="text-red-alert">*</span>
                  </h6>
                </label>
                <input
                  className="form-control"
                  id="Contactno"
                  name="Contactno"
                  type="text"
                  onChange={this.onChange}
                  value={this.state.Contactno}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="custom-input-type-wrap">
                <label class="mb-2">
                  <h6 className="font-weight-bold mb-0">{t('Company Name.1')}</h6>
                </label>
                <input
                  className="form-control"
                  id="companyname"
                  maxLength="256"
                  name="companyname"
                  tabIndex="9"
                  type="text"
                  onChange={this.onChange}
                  value={this.state.companyname}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-3 bg_custom mt-3">
          <div className="row">
            <div className="col col-lg-12">
              <div className="custom-input-select-wrap">
                <label class="mb-2">
                  <h6 className="font-weight-bold mb-0">
                    State&nbsp;<span className="text-red-alert">*</span>
                  </h6>
                </label>
                <select
                  id="pickup-delivery-location"
                  onChange={this.LocationStateChange}
                  value={this.state.deliveryState}
                >
                  <option value="SELECT">SELECT</option>
                  {this.state.allStates.map((state) => {
                    return (
                      <>
                        <option value={state.StateID}>{state.Name}</option>
                      </>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* className="px-3 bg_custom" */}
        <div className="mt-0">
          <div className="row">
            <div className="col col-lg-12">
              <div className="custom-input-type-wrap">
                <label class="mb-2">
                  <h6 className="font-weight-bold mb-0">
                    {t('Remark.1')}&nbsp;<span className="text-red-alert">*</span>
                  </h6>
                </label>
                <textarea
                  className="form-control"
                  id="remark"
                  name="remark"
                  tabIndex="9"
                  type="text"
                  onChange={this.onChange}
                  value={this.state.remark}
                />
              </div>
            </div>
          </div>
        </div>
        {/* className="px-3 bg_custom" */}
        <div className="mt-0">
          <div className="row">
            <div className="col col-lg-4">
              <div className="form-group">
                {this.state.loading == false ? (
                  <a
                    className="rounded-bttn rounded-bttn-transparent w-80 text-center mt-3 mb-3"
                    title={t('Submit.1')}
                    onClick={this.onSendQuoteRequestClick}
                  >
                    {t('Submit.1')}
                  </a>
                ) : null}
              </div>
            </div>
            <div className="col col-lg-8">
              <div className="form-group">
                {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
                  <Dialog
                    open={this.state.errorMessage.length > 0}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">{'Dollar'}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {this.state.errorMessage}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleClose} color="primary">
                        {t('OK.1')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                ) : null}
                {this.state.loading == true ? (
                  <div style={{ verticalAlign: 'center' }}>
                    <Spinner animation="border" />
                    &nbsp;{t('Sending quote request.1')}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(QuoteRequest));
